const countrycode = [
	{label: "+972", name: "Israel", value: "IL"},
	{label: "+93", name: "Afghanistan", value: "AF"},
	{label: "+355", name: "Albania", value: "AL"},
	{label: "+213", name: "Algeria", value: "DZ"},
	{label: "+1 684", name: "AmericanSamoa", value: "AS"},
	{label: "+376", name: "Andorra", value: "AD"},
	{label: "+244", name: "Angola", value: "AO"},
	{label: "+1 264", name: "Anguilla", value: "AI"},
	{label: "+1268", name: "Antigua and Barbuda", value: "AG"},
	{label: "+54", name: "Argentina", value: "AR"},
	{label: "+374", name: "Armenia", value: "AM"},
	{label: "+297", name: "Aruba", value: "AW"},
	{label: "+61", name: "Australia", value: "AU"},
	{label: "+43", name: "Austria", value: "AT"},
	{label: "+994", name: "Azerbaijan", value: "AZ"},
	{label: "+1 242", name: "Bahamas", value: "BS"},
	{label: "+973", name: "Bahrain", value: "BH"},
	{label: "+880", name: "Bangladesh", value: "BD"},
	{label: "+1 246", name: "Barbados", value: "BB"},
	{label: "+375", name: "Belarus", value: "BY"},
	{label: "+32", name: "Belgium", value: "BE"},
	{label: "+501", name: "Belize", value: "BZ"},
	{label: "+229", name: "Benin", value: "BJ"},
	{label: "+1 441", name: "Bermuda", value: "BM"},
	{label: "+975", name: "Bhutan", value: "BT"},
	{label: "+387", name: "Bosnia and Herzegovina", value: "BA"},
	{label: "+267", name: "Botswana", value: "BW"},
	{label: "+55", name: "Brazil", value: "BR"},
	{label: "+246", name: "British Indian Ocean Territory", value: "IO"},
	{label: "+359", name: "Bulgaria", value: "BG"},
	{label: "+226", name: "Burkina Faso", value: "BF"},
	{label: "+257", name: "Burundi", value: "BI"},
	{label: "+855", name: "Cambodia", value: "KH"},
	{label: "+237", name: "Cameroon", value: "CM"},
	{label: "+1", name: "Canada", value: "CA"},
	{label: "+238", name: "Cape Verde", value: "CV"},
	{label: "+ 345", name: "Cayman Islands", value: "KY"},
	{label: "+236", name: "Central African Republic", value: "CF"},
	{label: "+235", name: "Chad", value: "TD"},
	{label: "+56", name: "Chile", value: "CL"},
	{label: "+86", name: "China", value: "CN"},
	{label: "+61", name: "Christmas Island", value: "CX"},
	{label: "+57", name: "Colombia", value: "CO"},
	{label: "+269", name: "Comoros", value: "KM"},
	{label: "+242", name: "Congo", value: "CG"},
	{label: "+682", name: "Cook Islands", value: "CK"},
	{label: "+506", name: "Costa Rica", value: "CR"},
	{label: "+385", name: "Croatia", value: "HR"},
	{label: "+53", name: "Cuba", value: "CU"},
	{label: "+537", name: "Cyprus", value: "CY"},
	{label: "+420", name: "Czech Republic", value: "CZ"},
	{label: "+45", name: "Denmark", value: "DK"},
	{label: "+253", name: "Djibouti", value: "DJ"},
	{label: "+1 767", name: "Dominica", value: "DM"},
	{label: "+1 849", name: "Dominican Republic", value: "DO"},
	{label: "+593", name: "Ecuador", value: "EC"},
	{label: "+20", name: "Egypt", value: "EG"},
	{label: "+503", name: "El Salvador", value: "SV"},
	{label: "+240", name: "Equatorial Guinea", value: "GQ"},
	{label: "+291", name: "Eritrea", value: "ER"},
	{label: "+372", name: "Estonia", value: "EE"},
	{label: "+251", name: "Ethiopia", value: "ET"},
	{label: "+298", name: "Faroe Islands", value: "FO"},
	{label: "+679", name: "Fiji", value: "FJ"},
	{label: "+358", name: "Finland", value: "FI"},
	{label: "+33", name: "France", value: "FR"},
	{label: "+594", name: "French Guiana", value: "GF"},
	{label: "+689", name: "French Polynesia", value: "PF"},
	{label: "+241", name: "Gabon", value: "GA"},
	{label: "+220", name: "Gambia", value: "GM"},
	{label: "+995", name: "Georgia", value: "GE"},
	{label: "+49", name: "Germany", value: "DE"},
	{label: "+233", name: "Ghana", value: "GH"},
	{label: "+350", name: "Gibraltar", value: "GI"},
	{label: "+30", name: "Greece", value: "GR"},
	{label: "+299", name: "Greenland", value: "GL"},
	{label: "+1 473", name: "Grenada", value: "GD"},
	{label: "+590", name: "Guadeloupe", value: "GP"},
	{label: "+1 671", name: "Guam", value: "GU"},
	{label: "+502", name: "Guatemala", value: "GT"},
	{label: "+224", name: "Guinea", value: "GN"},
	{label: "+245", name: "Guinea-Bissau", value: "GW"},
	{label: "+595", name: "Guyana", value: "GY"},
	{label: "+509", name: "Haiti", value: "HT"},
	{label: "+504", name: "Honduras", value: "HN"},
	{label: "+36", name: "Hungary", value: "HU"},
	{label: "+354", name: "Iceland", value: "IS"},
	{label: "+91", name: "India", value: "IN"},
	{label: "+62", name: "Indonesia", value: "ID"},
	{label: "+964", name: "Iraq", value: "IQ"},
	{label: "+353", name: "Ireland", value: "IE"},
	{label: "+972", name: "Israel", value: "IL"},
	{label: "+39", name: "Italy", value: "IT"},
	{label: "+1 876", name: "Jamaica", value: "JM"},
	{label: "+81", name: "Japan", value: "JP"},
	{label: "+962", name: "Jordan", value: "JO"},
	{label: "+7 7", name: "Kazakhstan", value: "KZ"},
	{label: "+254", name: "Kenya", value: "KE"},
	{label: "+686", name: "Kiribati", value: "KI"},
	{label: "+965", name: "Kuwait", value: "KW"},
	{label: "+996", name: "Kyrgyzstan", value: "KG"},
	{label: "+371", name: "Latvia", value: "LV"},
	{label: "+961", name: "Lebanon", value: "LB"},
	{label: "+266", name: "Lesotho", value: "LS"},
	{label: "+231", name: "Liberia", value: "LR"},
	{label: "+423", name: "Liechtenstein", value: "LI"},
	{label: "+370", name: "Lithuania", value: "LT"},
	{label: "+352", name: "Luxembourg", value: "LU"},
	{label: "+261", name: "Madagascar", value: "MG"},
	{label: "+265", name: "Malawi", value: "MW"},
	{label: "+60", name: "Malaysia", value: "MY"},
	{label: "+960", name: "Maldives", value: "MV"},
	{label: "+223", name: "Mali", value: "ML"},
	{label: "+356", name: "Malta", value: "MT"},
	{label: "+692", name: "Marshall Islands", value: "MH"},
	{label: "+596", name: "Martinique", value: "MQ"},
	{label: "+222", name: "Mauritania", value: "MR"},
	{label: "+230", name: "Mauritius", value: "MU"},
	{label: "+262", name: "Mayotte", value: "YT"},
	{label: "+52", name: "Mexico", value: "MX"},
	{label: "+377", name: "Monaco", value: "MC"},
	{label: "+976", name: "Mongolia", value: "MN"},
	{label: "+382", name: "Montenegro", value: "ME"},
	{label: "+1664", name: "Montserrat", value: "MS"},
	{label: "+212", name: "Morocco", value: "MA"},
	{label: "+95", name: "Myanmar", value: "MM"},
	{label: "+264", name: "Namibia", value: "NA"},
	{label: "+674", name: "Nauru", value: "NR"},
	{label: "+977", name: "Nepal", value: "NP"},
	{label: "+31", name: "Netherlands", value: "NL"},
	{label: "+599", name: "Netherlands Antilles", value: "AN"},
	{label: "+687", name: "New Caledonia", value: "NC"},
	{label: "+64", name: "New Zealand", value: "NZ"},
	{label: "+505", name: "Nicaragua", value: "NI"},
	{label: "+227", name: "Niger", value: "NE"},
	{label: "+234", name: "Nigeria", value: "NG"},
	{label: "+683", name: "Niue", value: "NU"},
	{label: "+672", name: "Norfolk Island", value: "NF"},
	{label: "+1 670", name: "Northern Mariana Islands", value: "MP"},
	{label: "+47", name: "Norway", value: "NO"},
	{label: "+968", name: "Oman", value: "OM"},
	{label: "+92", name: "Pakistan", value: "PK"},
	{label: "+680", name: "Palau", value: "PW"},
	{label: "+507", name: "Panama", value: "PA"},
	{label: "+675", name: "Papua New Guinea", value: "PG"},
	{label: "+595", name: "Paraguay", value: "PY"},
	{label: "+51", name: "Peru", value: "PE"},
	{label: "+63", name: "Philippines", value: "PH"},
	{label: "+48", name: "Poland", value: "PL"},
	{label: "+351", name: "Portugal", value: "PT"},
	{label: "+1 939", name: "Puerto Rico", value: "PR"},
	{label: "+974", name: "Qatar", value: "QA"},
	{label: "+40", name: "Romania", value: "RO"},
	{label: "+250", name: "Rwanda", value: "RW"},
	{label: "+685", name: "Samoa", value: "WS"},
	{label: "+378", name: "San Marino", value: "SM"},
	{label: "+966", name: "Saudi Arabia", value: "SA"},
	{label: "+221", name: "Senegal", value: "SN"},
	{label: "+381", name: "Serbia", value: "RS"},
	{label: "+248", name: "Seychelles", value: "SC"},
	{label: "+232", name: "Sierra Leone", value: "SL"},
	{label: "+65", name: "Singapore", value: "SG"},
	{label: "+421", name: "Slovakia", value: "SK"},
	{label: "+386", name: "Slovenia", value: "SI"},
	{label: "+677", name: "Solomon Islands", value: "SB"},
	{label: "+27", name: "South Africa", value: "ZA"},
	{label: "+500", name: "South Georgia and the South Sandwich Islands", value: "GS"},
	{label: "+34", name: "Spain", value: "ES"},
	{label: "+94", name: "Sri Lanka", value: "LK"},
	{label: "+249", name: "Sudan", value: "SD"},
	{label: "+597", name: "Suriname", value: "SR"},
	{label: "+268", name: "Swaziland", value: "SZ"},
	{label: "+46", name: "Sweden", value: "SE"},
	{label: "+41", name: "Switzerland", value: "CH"},
	{label: "+992", name: "Tajikistan", value: "TJ"},
	{label: "+66", name: "Thailand", value: "TH"},
	{label: "+228", name: "Togo", value: "TG"},
	{label: "+690", name: "Tokelau", value: "TK"},
	{label: "+676", name: "Tonga", value: "TO"},
	{label: "+1 868", name: "Trinidad and Tobago", value: "TT"},
	{label: "+216", name: "Tunisia", value: "TN"},
	{label: "+90", name: "Turkey", value: "TR"},
	{label: "+993", name: "Turkmenistan", value: "TM"},
	{label: "+1 649", name: "Turks and Caicos Islands", value: "TC"},
	{label: "+688", name: "Tuvalu", value: "TV"},
	{label: "+256", name: "Uganda", value: "UG"},
	{label: "+380", name: "Ukraine", value: "UA"},
	{label: "+971", name: "United Arab Emirates", value: "AE"},
	{label: "+44", name: "United Kingdom", value: "GB"},
	{label: "+1", name: "United States", value: "US"},
	{label: "+598", name: "Uruguay", value: "UY"},
	{label: "+998", name: "Uzbekistan", value: "UZ"},
	{label: "+678", name: "Vanuatu", value: "VU"},
	{label: "+681", name: "Wallis and Futuna", value: "WF"},
	{label: "+967", name: "Yemen", value: "YE"},
	{label: "+260", name: "Zambia", value: "ZM"},
	{label: "+263", name: "Zimbabwe", value: "ZW"},
	{label: "+591", name: "Bolivia, Plurinational State of", value: "BO"},
	{label: "+673", name: "Brunei Darussalam", value: "BN"},
	{label: "+61", name: "Cocos (Keeling) Islands", value: "CC"},
	{label: "+243", name: "Congo, The Democratic Republic of the", value: "CD"},
	{label: "+225", name: "Cote d'Ivoire", value: "CI"},
	{label: "+500", name: "Falkland Islands (Malvinas)", value: "FK"},
	{label: "+44", name: "Guernsey", value: "GG"},
	{label: "+379", name: "Holy See (Vatican City State)", value: "VA"},
	{label: "+852", name: "Hong Kong", value: "HK"},
	{label: "+98", name: "Iran, Islamic Republic of", value: "IR"},
	{label: "+44", name: "Isle of Man", value: "IM"},
	{label: "+44", name: "Jersey", value: "JE"},
	{label: "+850", name: "Korea, Democratic People's Republic of", value: "KP"},
	{label: "+82", name: "Korea, Republic of", value: "KR"},
	{label: "+856", name: "Lao People's Democratic Republic", value: "LA"},
	{label: "+218", name: "Libyan Arab Jamahiriya", value: "LY"},
	{label: "+853", name: "Macao", value: "MO"},
	{label: "+389", name: "Macedonia, The Former Yugoslav Republic of", value: "MK"},
	{label: "+691", name: "Micronesia, Federated States of", value: "FM"},
	{label: "+373", name: "Moldova, Republic of", value: "MD"},
	{label: "+258", name: "Mozambique", value: "MZ"},
	{label: "+970", name: "Palestinian Territory, Occupied", value: "PS"},
	{label: "+872", name: "Pitcairn", value: "PN"},
	{label: "+262", name: "Réunion", value: "RE"},
	{label: "+7", name: "Russia", value: "RU"},
	{label: "+590", name: "Saint Barthélemy", value: "BL"},
	{label: "+290", name: "Saint Helena, Ascension and Tristan Da Cunha", value: "SH"},
	{label: "+1 869", name: "Saint Kitts and Nevis", value: "KN"},
	{label: "+1 758", name: "Saint Lucia", value: "LC"},
	{label: "+590", name: "Saint Martin", value: "MF"},
	{label: "+508", name: "Saint Pierre and Miquelon", value: "PM"},
	{label: "+1 784", name: "Saint Vincent and the Grenadines", value: "VC"},
	{label: "+239", name: "Sao Tome and Principe", value: "ST"},
	{label: "+252", name: "Somalia", value: "SO"},
	{label: "+47", name: "Svalbard and Jan Mayen", value: "SJ"},
	{label: "+963", name: "Syrian Arab Republic", value: "SY"},
	{label: "+886", name: "Taiwan, Province of China", value: "TW"},
	{label: "+255", name: "Tanzania, United Republic of", value: "TZ"},
	{label: "+670", name: "Timor-Leste", value: "TL"},
	{label: "+58", name: "Venezuela, Bolivarian Republic of", value: "VE"},
	{label: "+84", name: "Viet Nam", value: "VN"},
	{label: "+1 284", name: "Virgin Islands, British", value: "VG"},
	{label: "+1 340", name: "Virgin Islands, U.S.", value: "VI"}
]

export default countrycode
