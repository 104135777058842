import React, {useState, useEffect, useRef} from "react"
import {connect} from "react-redux"
import {get} from "helpers/api"
import Styled from "styled-components"
import {useHistory} from "react-router-dom"

// Components
import {Layout} from "components/Shared/Layout"
import {ContainerFluid, Row, Col} from "@uprise/grid"
import {Card} from "@uprise/card"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds} from "@uprise/colors"
// Icons
import Icons from "constants/Icons"

const Main = Styled.section`
	width: 100%;
`

const Content = Styled.section`
	width: 100%;
	padding: 35px 20px;
	min-height: 100vh;
	text-align: center;
	flex-direction: column;
	display: flex;

`

const ContentWrap = Styled.section`
	min-height: 100vh;
	background-color: ${extended.purple.six};
`

const CardWrap = Styled.section`
	display: flex;
	flex-direction: row;
	justify-content: center;
`

const SuccessImage = Styled.img`
      height: 90px;
      width: 90px;
	align-self: center;
`

const LoadingImag = Styled.img`
	width: 100px;
	align-self: center;
`

const LOADING_MESSAGE = "Loading..."

const CapApproval = ({match}) => {
	let history = useHistory()
	const [message, setMessage] = useState(LOADING_MESSAGE)
	const [status, setStatus] = useState("")

	const linkRef = useRef()

	useEffect(() => {
		// <domain>/user/delete/<token>/<email>
		const path = match.path.split("/")[3]

		if (path.includes("deny")) {
			get(`denyIncreaseCap/${match.params.token}/${match.params.email}`)
				.then(resp => {
					if (resp.error) {
						setMessage(resp.error)
						setStatus("error")
					} else if (resp.success) {
						const msg = (
							<span>
								Call cap successfully denied. <br />
								<br /> The user will be notified
							</span>
						)
						setMessage(msg)
						setStatus("success")
					}
				})
				.catch(err => {
					console.log(err)
				})
		} else {
			get(`approveIncreaseCap/${match.params.token}/${match.params.email}`)
				.then(resp => {
					if (resp.error) {
						setMessage(resp.error)
						setStatus("error")
					} else if (resp.success) {
						const msg = (
							<span>
								Call cap successfully approved. <br />
								<br /> The user will be notified
							</span>
						)
						setMessage(msg)
						setStatus("success")
					}
				})
				.catch(err => {
					console.log(err)
				})
		}
	}, [])

	return (
		<ContainerFluid>
			<Main>
				<ContentWrap>
					<ContainerFluid>
						<Row>
							<Col className='col-md-12 align-center'>
								<CardWrap>
									<Card
										className='m-t-10'
										backgroundColor={backgrounds.white}
										shadow={true}
										textAlign='center'
										width='500px'
										height='300px'>
										<Content>
											{message}
											{message === LOADING_MESSAGE && (
												<LoadingImag
													src={require("assets/images/loaders/spinner.gif")}
													alt='spinner'
												/>
											)}

											{status === "success" && (
												<SuccessImage className='m-t-7' src={Icons.success} />
											)}
										</Content>
									</Card>
								</CardWrap>
							</Col>
						</Row>
					</ContainerFluid>
				</ContentWrap>
			</Main>
		</ContainerFluid>
	)
}

function mapStateToProps(state) {
	return {
		UserState: state.UserState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(CapApproval)
