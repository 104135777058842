import * as surveys from "uprise-surveys/lib/surveys/index"

const RTW_SURVEY_LABELS = {
	WHO5RTWUS: "Page 1",
	RTW_US_ONBOARDING: "Page 2",
	ECOVALUS: "Page 3",
	RTW_US_FEEDBACK: "Page 4"
}

const RTW_3_MONTH_SURVEY_LABELS = {
	WHO5RTWUS: "Page 1",
	RTW_US_ONBOARDING: "Page 2",
	ECOVALUS: "Page 3"
}

export const postProgramTask = {
	surveyId: "progress-rtw-us-v1.0",
	taskId: "survey-postprogram-rtw",
	recurring: false
}

export const post3MonthTask = {
	surveyId: "progress-rtw-us-3-month-v1",
	taskId: "survey-postprogram-3-month-rtw",
	recurring: false
}

export function getScreeners(userState, ctx) {
	// const task = userState.modules.onboarding.tasks.assesment.tasks["survey-preprogram"]
	const task = ctx.isThreeMonthSurvey ? post3MonthTask : postProgramTask
	return surveys.retrieveSurvey(task.surveyId).questions.reduce((screeners, question) => {
		if (screeners.indexOf(question.batteryId) === -1) {
			screeners.push(question.batteryId)
		}
		return screeners
	}, [])
}

/**
 *
 * @param {Object} userState | User state from server response
 * @returns {Array} itemsList
 */
export function getRTWItemsList(userState, ctx) {
	const firstItem = {title: "Post Program Overview", header: "Welcome to Post program survey", active: true}
	const lastItem = {title: "All Done", header: "🎉 Congratulation! All done.", active: false}
	let screeners = []

	if (userState) {
		screeners = getScreeners(userState, ctx).map(i => {
			return {
				id: i,
				title: ctx.isThreeMonthSurvey ? RTW_3_MONTH_SURVEY_LABELS[i] : RTW_SURVEY_LABELS[i],
				header: ctx.isThreeMonthSurvey ? `Post 3-month Assessment` : `Post-program Assessment`,
				active: false
			}
		})
	}
	return [firstItem, ...screeners, lastItem]
}
