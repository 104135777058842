import {STORE_SUGGESTED} from "./types"
// Utils
import {getPersonalisation} from "helpers/utils"
// surveys
import {retrieveSurvey} from "uprise-surveys/lib/surveys/index"

export const storePersonalisation = userState => {
	const survey = retrieveSurvey("personalisation-v1")

	const personalisationResults = getPersonalisation(userState.surveyResults)
	const scores = {
		retraining1: 0,
		pleasant: 0,
		letgo: 0,
		breathing: 0,
		awareness: 0,
		crisis: 0,
		earlyWarningSigns: 0,
		revision: 0,
		stress: 0,
		guilt: 0,
		metacognition: 0,
		beliefs: 0,
		reflectiveListening: 0,
		sleep: 0,
		control: 0,
		observer: 0,
		autopilot: 0,
		threats: 0,
		healthAndDiseaseAnxiety: 0,
		perspectivetaking: 0
	}

	return dispatch => {
		if (personalisationResults) {
			personalisationResults.forEach(interest => {
				survey.mapPersonaliseScore && survey.mapPersonaliseScore(interest, scores)
			})
		}

		dispatch(storeSuggested(scores))
	}
}

export const storeSuggested = scores => {
	return {
		type: STORE_SUGGESTED,
		suggested: scores
	}
}
