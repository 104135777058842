import React from "react"
import {Modal} from "@uprise/modal"
import Styled from "styled-components"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Components
import {Button} from "@uprise/button"
import {H2, H3, H4, H5, H6} from "@uprise/headings"

const InfoBox = Styled.section`
	borderRadius: 10px;
	background-color: ${backgrounds.fadedPurple};
	padding: ${spacing.s5};
    text-align: left;
	span {
		color: ${primary.purple};
		cursor: pointer;
	}
 `
const CloseBtn = Styled(Button)`
    float: right;
    padding: 5px 20px;
`

const InfoModal = ({showModal, handleOpen, handleClose}) => {
	const openURL = url => {
		const newWindow = window.open(url, "_blank", "noopener,noreferrer")
		if (newWindow) newWindow.opener = null
	}
	return (
		<Modal
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='900px'
			textAlign='center'
			isOpen={showModal}
			handleClose={() => {
				handleClose()
			}}>
			<InfoBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0'>Is your wellbeing check clinically validated?</H5>
				<p>
					There are six questions in the wellbeing check we use. The first five questions are from the WHO-5
					(World Health Organization Wellbeing 5-item questionnaire), which is a clinically validated measure
					of wellbeing. A list of the evidence supporting this measure can be found by{" "}
					<span onClick={() => openURL("https://www.psykiatri-regionh.dk/who-5/Pages/default.aspx")}>
						clicking here
					</span>
					. The final question is a list of common psychosocial hazards for the workplace.
				</p>
			</InfoBox>

			<InfoBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0'>Is your stress check clinically validated?</H5>
				<p>
					The stress check is composed of several clinically validated measures. The stress component is
					measured by the PSS-4, which is a clinically validated measure of stress. A list of the evidence
					supporting this measure can be found by{" "}
					<span onClick={() => openURL("https://en.wikipedia.org/wiki/Perceived_Stress_Scale")}>
						clicking here.
					</span>
					The stress check also contains four additional items to measure absenteeism, presenteeism,
					engagement and risk of turnover.
				</p>
			</InfoBox>

			<InfoBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0'>Are the recommended exercises clinically validated?</H5>
				<p>
					The exercises recommended within Uprise are based on empirically validated psychological treatments.
					For example, the Mindset series is based on Cognitive Behavioral Therapy (CBT), which is a current
					gold standard psychosocial intervention. A discussion of the evidence supporting CBT can be found by{" "}
					<span onClick={() => openURL("https://en.wikipedia.org/wiki/Cognitive_behavioral_therapy")}>
						clicking here.
					</span>
					Uprise also uses exercises based on other evidence-based approaches such as{" "}
					<span onClick={() => openURL("https://en.wikipedia.org/wiki/Acceptance_and_commitment_therapy")}>
						Acceptance and Commitment Therapy
					</span>
					for the Mindfulness Series.
				</p>
			</InfoBox>

			<InfoBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0'>Are the approach and protocols used in Uprise clinically validated?</H5>
				<p>
					The courses in Uprise were mostly written by Dr. Jay Spence who is the founder of Uprise. Dr. Spence
					is a clinical psychologist registered in Australia with both a Master of Clinical Psychology and a
					PhD in online mental health treatments. All other courses within Uprise were written by licensed
					clinical psychologists under the supervision of Dr. Spence. Dr Spence’s publications can be found by{" "}
					<span onClick={() => openURL("https://scholar.google.com.au/citations?user=JqqcQBwAAAAJ&hl=en")}>
						clicking here.
					</span>
				</p>
			</InfoBox>

			<CloseBtn
				className='m-b-2'
				variant='primary'
				onClick={() => handleClose()}
				size='full-width'
				title='Close'
			/>
		</Modal>
	)
}

export default InfoModal
