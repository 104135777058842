import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import Styled from "styled-components"
import {connect} from "react-redux"
import {useHistory} from "react-router-dom"
// Components
import {getCoachByEmail} from "actions/coachActions"
import {AccessSupport} from "components/Shared/AccessSupport"
import {Suggested} from "components/Shared/Suggested"
import UpNext from "components/Shared/UpNext"
import FeaturedSkill from "components/Shared/FeaturedSkill"
import YourHealth from "components/Shared/YourHealth"
import BookingCardDashboard from "components/Shared/Coach/BookingCardDashboard"
import CoachingScenarioModal from "components/Shared/Coach/CoachingScenarioModal"
import CareNavigator from "components/Shared/CareNavigator"
// UI
import {Layout} from "components/Shared/Layout"
import {LeftPanel, Main, RightPanel} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"
import {Track} from "components/Shared/Library/Track"
// Actions
import {updateUserState} from "actions/userStateActions"
import {fetchBookings} from "actions/bookingsActions"
import {storePersonalisation} from "actions/suggestedActions"
// Helpers
import {isCoachingEnabled} from "helpers/utils"

const Wrap = Styled.section`
	width: 100%;
 `

const Dashboard = ({
	userState,
	coachEmail,
	suggested,
	bookings,
	getCoachByEmail,
	fetchBookings,
	storePersonalisation
}) => {
	const [showCoachingModal, setShowCoachingModal] = useState(false)
	const history = useHistory()
	const {skills, series, meta, tracks, surveyResults, courseType} = userState

	const {coreSkills, thinkingSkills} = tracks
	const firstLoad = true

	useEffect(() => {
		storePersonalisation(userState)
		fetchBookings()
	}, [])

	// Get coach object
	useEffect(() => {
		if (coachEmail) {
			getCoachByEmail(coachEmail)
		}
	}, [coachEmail])

	useEffect(() => {
		let canTriggerPopup = false
		if (userState.numberOfSessions % 3 === 0) {
			canTriggerPopup = true
		}
		if (canTriggerPopup) {
			setShowCoachingModal(true)
		}
	}, [userState])

	const _handleBookATherapy = () => {
		if (userState.coach) {
			history.push("/make-booking", {from: "/", duration: 60})
		} else {
			history.push("/select-coach", {from: "/", duration: 60})
		}
	}

	const isCareNavigatorEnabled = () => {
		if (userState?.features?.length) {
			return userState?.features?.find(feature => feature?.id === "care-navigator")?.enabled
		}
	}

	return (
		<Layout>
			<LeftPanel LogoMobile={true}>
				<LeftMenu active={0} />
			</LeftPanel>
			<Wrap>
				<Header title='Home' textAlign='left' width='100%' handleBack={null} hideMobile={true} />
				<Main>
					<Suggested suggested={suggested} meta={meta} skills={skills} />
					<UpNext className='m-b-10' meta={meta} skills={skills} firstLoad={firstLoad} />
					{isCareNavigatorEnabled() && <CareNavigator className='m-b-10' />}

					{courseType === "rtw" ? null : <FeaturedSkill className='m-b-10' />}
					{isCoachingEnabled(userState?.features) && (
						<BookingCardDashboard bookings={bookings} coachEmail={userState.coach} />
					)}
					{isCoachingEnabled(userState?.features) && courseType !== "rtw" && (
						<CoachingScenarioModal
							isOpen={showCoachingModal}
							handleClose={() => setShowCoachingModal(false)}
						/>
					)}

					<Track
						track={coreSkills || thinkingSkills}
						skills={skills}
						series={series}
						meta={meta}
						set='startingSkills'
						trial={userState.course.includes("trial") ? true : false}
						courseType={userState.courseType}
					/>

					<AccessSupport handleBookTherapy={_handleBookATherapy} />

					{/* <TheScience /> */}
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-block' width='310px' widthLg='250px'>
				<YourHealth className='m-b-7' surveyResults={surveyResults} />
			</RightPanel>
		</Layout>
	)
}

const mapDispatchToProps = dispatch => ({
	getCoachByEmail: email => dispatch(getCoachByEmail(email)),
	updateUserState: state => dispatch(updateUserState(state)),
	storePersonalisation: userState => dispatch(storePersonalisation(userState)),
	fetchBookings: () => dispatch(fetchBookings())
})

function mapStateToProps(state) {
	return {
		userState: state.userState,
		coachEmail: state?.userState?.coach,
		coach: state?.coach,
		suggested: state?.suggested,
		bookings: state?.bookings
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Dashboard.propTypes = {}

Dashboard.defaultProps = {}
