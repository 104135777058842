import React, {useState, useEffect} from "react"
import {object, number} from "prop-types"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import {connect} from "react-redux"
import moment from "moment-timezone"
import {get, post} from "helpers/api"
import "react-dates/initialize"
import {DayPickerSingleDateController} from "react-dates"
// Actions
import {updateUserState} from "actions/userStateActions"
import {getCoachByEmail} from "actions/coachActions"
import {fetchBookings} from "actions/bookingsActions"
// Styles
import "react-dates/lib/css/_datepicker.css"
import "styles/react-dates.css"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// UI
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {Alert} from "@uprise/alert"
import {Medium, P, Small, Tiny} from "@uprise/text"
import {H3, H6} from "@uprise/headings"
// Components
import {ConfirmModal} from "components/Shared/Coach/ConfirmModal"
import {MoreInfoModal} from "components/Shared/Coach/MoreInfoModal"
import {ChangeNumberModal} from "components/Shared/Coach/ChangeNumberModal"
// spacing
import {spacing} from "@uprise/spacing"
// Icons
import Icons from "constants/Icons"
// Utils
import {getBookingMessage, getBookingMessageForRTW, getCourseType, isTherapyEnabled, isCoachingEnabled} from "helpers/utils"

const BookCallStyles = Styled.section`
`

const Content = Styled.div`
	padding: ${spacing.s7}
  	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: -50px;

	@media (max-width: 1024px) {
		flex-direction: column;
	}
`

const CalendarWrap = Styled.div`

`

const AvailableTimes = Styled.div`
	width: 100%;
`

const TimeSlots = Styled.div`
	display: flex;
	flex-direction: row;
`

const CallDetails = Styled.div`
	display: flex;
	flex-direction: column;
`

const DateWrap = Styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	margin-top: 50px;


	@media (max-width: 475px) {
		width: 100%;
		flex-wrap: wrap;
		justify-content: center;
	}
`

const BookingInfo = Styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-top: 50px;
	flex-basis: 150px;

	@media (max-width: 475px) {
		width: 100%;
		flex-wrap: wrap;
		justify-content: center;
	}
`

const PhoneNumber = Styled.div`
	width: 250px;
	display: flex;
	flex-direction: row;

	@media (max-width: 475px) {
		width: 100%;
	}
`

const PhoneIcon = Styled.img`
  width: 22px;
	height: 22px;
  min-width: 22px;
`

const InfoHeader = Styled.div`
	display: flex;
	flex-direction: row;
`

const SlotHeader = Styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid ${extended.purple.five};
`

const AMSlots = Styled.div`
	display: flex;
	flex-direction: column;

	@media (max-width: 1024px) {
		flex-direction: row;
		flex-wrap: wrap;
	}
`

const PMSlots = Styled.div`
	display: flex;
	flex-direction: column;

	@media (max-width: 1024px) {
		flex-direction: row;
		flex-wrap: wrap;
	}
`

const MessageWrap = Styled.div`
	padding: ${spacing.s7};
`
const ChangeNumberButton = Styled.div`
	background-color: ${backgrounds.fadedPurple};
	padding: 0px 10px;
	cursor: pointer;
`

const BookCall = ({
	className,
	duration,
	userState,
	 
	getCoachByEmail,
	coach,
	coachEmail,
	callLimitReached,
	callsRemaining,
	callCapLimit,
	callApproved,
	reschedule,
	fetchBookings
}) => {
	let history = useHistory()

	const [date, setDate] = useState()
	const [slotsAMIndex, setSlotsAMIndex] = useState()
	const [slotsPMIndex, setSlotsPMIndex] = useState()
	const [openSlots, setOpenSlots] = useState()
	const [selectedTime, setSelectedTime] = useState()
	const [dateOpenSlots, setDateOpenSlots] = useState([])
	const [showTime, setShowTime] = useState()
	const [showConfirm, setShowConfirm] = useState(false)
	const [bookingLoading, setBookingLoading] = useState(false)
	const [booking, setBooking] = useState()
	const [showMoreInfo, setShowMoreInfo] = useState()
	const [changeNumber, setChangeNumber] = useState()
	const [dateWithSlots, setDateWithSlots] = useState({})

	useEffect(() => {
		setDate(new Date())
	}, [])

	useEffect(() => {
		if (openSlots && openSlots.length) {
			const map = getDatesWithSlot()
			setDateWithSlots(map)
			// Reset available slots
			updateAvailableSlots(moment())
		}
	}, [openSlots])

	useEffect(() => {
		get(`coach/availability/${userState.coach}/${duration}`)
			.then(resp => {
				setAvailableSlots(resp)
			})
			.catch(error => {
				console.log({error})
			})
	}, [coach, duration])

	const minBookingNotice = () => {
		return moment().add(24, "hours")
	}

	const updateAvailableSlots = date => {
		setSlotsAMIndex([])
		setSlotsPMIndex([])

		const dateOpenSlots = {}

		openSlots.forEach(openSlot => {
			if (openSlot.start.format("DD/MM/YY") === date.format("DD/MM/YY") && openSlot.start > minBookingNotice()) {
				dateOpenSlots[openSlot.start.format("X")] = openSlot
			}
		})

		const ordered = {}
		Object.keys(dateOpenSlots)
			.sort()
			.forEach(function(key) {
				ordered[key] = dateOpenSlots[key]
			})

		setDateOpenSlots(ordered)
		setDate(date)
		setSelectedTime(Object.values(ordered)[0] ? Object.values(ordered)[0] : null)
	}

	useEffect(() => {
		if (Object.values(dateOpenSlots).length > 0) {
			setIndexs()
		}
	}, [dateOpenSlots])

	const setIndexs = () => {
		let slotsIndex = []

		slotsIndex = Object.entries(dateOpenSlots)
			.filter(openslot => {
				if (openslot[1].start.format("a") === "am") {
					return true
				}
			})
			.map(openslot => {
				if (openslot[1].start.format("a") === "am") {
					return false
				}
			})

		setSlotsAMIndex(slotsIndex)

		slotsIndex = Object.entries(dateOpenSlots)
			.filter(openslot => {
				if (openslot[1].start.format("a") === "pm") {
					return true
				}
			})
			.map(openslot => {
				if (openslot[1].start.format("a") === "pm") {
					return false
				}
			})

		setSlotsPMIndex(slotsIndex)
	}

	useEffect(() => {
		handleSelectTime()

		if (slotsAMIndex && selectedTime) {
			if (slotsAMIndex.indexOf(true) === -1) {
				selectSlot(selectedTime, 0)
			}
		} else if (slotsPMIndex && selectedTime) {
			if (slotsPMIndex.indexOf(true) === -1) {
				selectSlot(selectedTime, 0)
			}
		}
	}, [slotsPMIndex, slotsAMIndex])

	const setAvailableSlots = coachSlots => {
		setOpenSlots(
			coachSlots.map(s => ({
				start: moment(s.start),
				end: moment(s.end)
			}))
		)
	}

	const selectSlot = (slot, index) => {
		let slotsAMIndexNew = slotsAMIndex
		let slotsPMIndexNew = slotsPMIndex

		for (let slot in slotsAMIndex) {
			slotsAMIndexNew[slot] = false
		}

		for (let slot in slotsPMIndex) {
			slotsPMIndexNew[slot] = false
		}

		if (slot.start.format("a") === "am") {
			if (slotsAMIndexNew[index]) {
				slotsAMIndexNew[index] = false
			} else {
				slotsAMIndexNew[index] = true
			}

			setSlotsAMIndex(slotsAMIndexNew)
		} else {
			if (slotsPMIndexNew[index]) {
				slotsPMIndexNew[index] = false
			} else {
				slotsPMIndexNew[index] = true
			}

			setSlotsPMIndex(slotsPMIndexNew)
		}

		const selectedTime = slot
		setSelectedTime(selectedTime)
	}

	const handleSelectTime = () => {
		setShowTime(true)
	}

	const getAMOpenSlots = () => {
		if (dateOpenSlots && slotsAMIndex.length > 0) {
			const openSlots = Object.entries(dateOpenSlots).map((openslot, index) => {
				if (openslot[1].start.format("a") === "am") {
					return (
						<Button
							size='tiny'
							className='m-b-3 m-r-3'
							fullWidth={false}
							borderRadius='5px'
							variant={`${slotsAMIndex[index] ? "primary" : "tertiary"}`}
							onClick={() => selectSlot(openslot[1], index)}
							key={index}
							title={`${openslot[1].start.format("h:mm A")}`}
						/>
					)
				}
			})

			return openSlots.length ? openSlots : false
		}
	}

	const getPMOpenSlots = () => {
		if (dateOpenSlots && slotsPMIndex.length > 0) {
			const openSlots = Object.entries(dateOpenSlots).map((openslot, index) => {
				if (openslot[1].start.format("a") === "pm") {
					return (
						<Button
							size='tiny'
							className='m-b-3 m-r-3'
							fullWidth={false}
							borderRadius='5px'
							variant={`${slotsPMIndex[index] ? "primary" : "tertiary"}`}
							onClick={() => selectSlot(openslot[1], index)}
							key={index}
							title={`${openslot[1].start.format("h:mm A")}`}
						/>
					)
				}
			})

			return openSlots.length ? openSlots : false
		}
	}

	const getDatesWithSlot = () => {
		const map = {}

		openSlots &&
			openSlots.forEach(slot => {
				const date = moment(slot.start).format("DD/MM")
				if (!map[date]) {
					map[date] = true
				}
			})

		return map
	}

	const _handleBook = () => {
		setBookingLoading(true)
		post("makeBooking", {
			clientEmail: userState.email,
			coachId: coach.id,
			startTime: selectedTime.start,
			purpose: "coach call",
			callDuration: parseInt(duration)
		})
			.then(resp => {
				if (resp.error === "BOOKING_CALL_CAPPING") {
					history.push("booking")
				} else if (resp.error === "BOOKING_COACH_CALENDAR_BUSY") {
					alert("The time you have selected is no longer available, please select another time")
				} else if (resp.error === "SlotUnavailable") {
					alert("This slot is no longer available, please try another one")
				} else if (resp.error === "ValidationFail") {
					alert("validation error")
				} else if (resp.error === "Failed to create event!") {
					alert("Failed to create event, please try a different coach or contact tech support")
				} else if (!resp.error) {
					const booking = {
						coach,
						selectedTime
					}
					setBooking(booking)
					fetchBookings()
					setShowConfirm(true)
				}

				setBookingLoading(false)
			})
			.catch(error => {
				console.error("Failed to make booking, reason: ", error)
				setBookingLoading(false)
			})
	}

	const _handleReschedule = () => {
		setBookingLoading(true)
		post("cancelBooking", {
			coachId: parseInt(reschedule.coachId),
			eventId: reschedule.calendarData?.data
				? reschedule.calendarData?.data?.id
				: reschedule.calendarData?.id
				? reschedule.calendarData?.id
				: reschedule.data.id,
			duration: reschedule.duration || 30,
			userTimezone: moment.tz.guess()
		}).then(resp => {
			if (resp.error) {
				alert("Unable to reschedule booking. Reason: " + resp.error)
				setBookingLoading(false)
			} else {
				_handleBook()
			}
		})
	}

	const _handleConfirmOpen = () => {
		post("complete", {
			module: "coach-booking",
			completed: true
		}).then(resp => {
			updateUserState(resp.user_state)
		})
	}

	useEffect(() => {
		if (callLimitReached && !callApproved) history.push("booking")
	}, [])

	let therapyCoachingText = "coaching and therapy"
	if (!(isTherapyEnabled(userState?.features) && isCoachingEnabled(userState?.features)))
		therapyCoachingText = `${isTherapyEnabled(userState?.features) ? "therapy" : "coaching"}`

	return (
		<BookCallStyles className={className}>
			<H3>{reschedule ? "Reschedule a call" : "Book a call"}</H3>
			<Medium className='m-b-4'>Uprise is 4x more effective with coaching</Medium>

			<Card backgroundColor={backgrounds.white} shadow={true}>
				<Content>
					<DateWrap>
						<CalendarWrap className='m-b-sm-10'>
							<DayPickerSingleDateController
								isOutsideRange={currentDate => {
									return (
										currentDate < moment().subtract(1, "d") ||
										!dateWithSlots[currentDate.format("DD/MM")]
									)
								}}
								onDateChange={date => updateAvailableSlots(date)} // PropTypes.func.isRequired
								isDayHighlighted={day => day.isSame(date, "d")}
							/>
						</CalendarWrap>

						<AvailableTimes className='m-l-10 m-r-5 m-r-sm-0 m-l-sm-0 m-t-0 m-b-sm-10'>
							<SlotHeader>
								<H6 className='m-t-0 m-b-3' fontSize='16px' weight='bold'>
									{moment(date).format("DD MMMM YYYY")}
								</H6>
							</SlotHeader>

							{showTime && selectedTime && (
								<TimeSlots className='m-t-4'>
									<AMSlots className='m-t-0'>{getAMOpenSlots()}</AMSlots>
									<PMSlots className='m-t-0'>{getPMOpenSlots()}</PMSlots>
								</TimeSlots>
							)}

							{!selectedTime && (
								<Small className='m-t-10' color={extended.charcoal.two} textAlign='center'>
									Sorry, no slots available on this day.
									<br />
									Please select another date.
								</Small>
							)}
						</AvailableTimes>
					</DateWrap>
					<BookingInfo className='m-l-5 m-l-sm-0 m-b-sm-0'>
						<InfoHeader>
							<PhoneIcon className='m-t-1' src={Icons.telephone} />
							<H6 fontSize='16px' className='m-l-3 m-t-0 m-b-5'>
								You are {reschedule ? "rescheduling" : "booking"} a{" "}
								{getCourseType() === "rtw"
									? "Coaching session"
									: duration === 30
									? "call"
									: "therapy session"}{" "}
								with {coach?.name}
							</H6>
						</InfoHeader>
						<CallDetails className='m-b-sm-0'>
							<Small className='m-b-0' color={extended.charcoal.one}>
								Your coach will call you at
							</Small>
							<PhoneNumber className='m-b-0'>
								<Small className='m-b-0' color={extended.charcoal.one}>
									{userState.phone}
								</Small>
								<ChangeNumberButton className='m-t-1 m-l-2' onClick={() => setChangeNumber(true)}>
									<Tiny color={primary.purple}>Change Number</Tiny>
								</ChangeNumberButton>

								<ChangeNumberModal
									isOpen={changeNumber}
									updateUserState={updateUserState}
									handleClose={() => setChangeNumber(false)}
									phone={userState.phone}
								/>
							</PhoneNumber>

							<Small className='m-t-4 m-b-5' color={extended.charcoal.one}>
								{getCourseType() === "rtw"
									? getBookingMessageForRTW(duration, {coach})
									: getBookingMessage(duration, {coach})}
							</Small>

							<Button
								className='m-b-3'
								fullWidth={false}
								size='medium'
								disabled={typeof selectedTime === "undefined" || selectedTime === null}
								variant='primary'
								isLoading={bookingLoading}
								onClick={() => (reschedule ? _handleReschedule() : _handleBook())}
								title={`${reschedule ? "Reschedule call" : "Confirm Booking"}`}
							/>
							<ConfirmModal
								data={booking}
								isOpen={showConfirm}
								onOpen={() => _handleConfirmOpen()}
								handleClose={() => setShowConfirm(false)}
							/>
							<Button
								className='m-b-3 m-b-sm-0'
								fullWidth={false}
								size='medium'
								variant='secondary'
								onClick={() => setShowMoreInfo(true)}
								title='Learn more about Coaching'
							/>
							<MoreInfoModal isOpen={showMoreInfo} handleClose={() => setShowMoreInfo(false)} />
						</CallDetails>
					</BookingInfo>
				</Content>
				<MessageWrap>
					{callCapLimit && !callApproved && (
						<Alert type='warning'>
							<P fontSize='16px' weight='bold'>
								You have {callsRemaining} included {therapyCoachingText} calls remaining
							</P>
						</Alert>
					)}
					{callCapLimit && callApproved && (
						<Alert type='warning'>
							<P fontSize='16px' weight='bold'>
								Your HR team has approved your request for an additional coaching call. You have 1 call
								available.
							</P>
						</Alert>
					)}
				</MessageWrap>
			</Card>
		</BookCallStyles>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		coachEmail: state?.userState?.coach,
		coach: state?.coach
	}
}

const mapDispatchToProps = dispatch => ({
 	getCoachByEmail: email => dispatch(getCoachByEmail(email)),
	updateUserState: state => dispatch(updateUserState(state)),
	fetchBookings: () => dispatch(fetchBookings())
})

export default connect(mapStateToProps, mapDispatchToProps)(BookCall)

BookCall.propTypes = {
	coach: object.isRequired,
	duration: number.isRequired
}

BookCall.defaultProps = {
	reschedule: false,
	duration: 30
}
