const constraints = {
	newPassword: {
		presence: {
			message: "^Please enter a new password",
			allowEmpty: false
		},
		length: {
			minimum: 10,
			message: "^Your password must be at least 10 characters"
		},
		format: {
			pattern: `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$`, //eslint-disable-line
			flags: "i",
			message: "^Password must contain lowercase, uppercase and 1 special character and 1 number"
		}
	},
	confirm: {
		equality: {
			attribute: "newPassword",
			message: "^Both passwords do not match"
		},
		presence: {
			message: "^Please confirm password",
			allowEmpty: false
		},
		length: {
			minimum: 10,
			message: "^Your password must be at least 10 characters"
		},
		format: {
			pattern: `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$`, //eslint-disable-line
			flags: "i",
			message: "^Password must contain lowercase, uppercase and 1 special character and 1 number"
		}
	}
}

export default constraints
