import React, {Fragment} from "react"
import {H1, H3, H5, H6} from "@uprise/headings"
import {extended, primary} from "@uprise/colors"
import {Medium} from "@uprise/text"
import {Col, Row} from "@uprise/grid"
import {Button} from "@uprise/button"

import HelpMeInterpretModal from "components/Shared/HrDashboard/HelpMeInterpretModal"
import ToggleModal from "components/Shared/ToggleModal"
import {TitleWrapper} from "components/Shared/HrDashboard/OverallWellbeingLevelsAtUprise/index.style"

import {ModalOpenButtonContainer, PurpleBox} from "../index.style"

function Feedback() {
	return (
		<>
			<TitleWrapper>
				<H3 className='m-b-5'>User feedback at Uprise</H3>
				<ToggleModal
					toggle={show => (
						<Button
							className='m-b-5'
							title={
								<ModalOpenButtonContainer>
									<img
										src={require(`assets/images/hrdashboard/information-symbol.svg`)}
										className='mr-2'
									/>
									Help me interpret this
								</ModalOpenButtonContainer>
							}
							fullWidth={false}
							size='medium'
							onClick={show}
							variant='tertiary'
							disabled={false}
							icon={true}
						/>
					)}
					content={hide => <HelpMeInterpretModal hide={hide} />}
				/>
			</TitleWrapper>
			<Row>
				<Col className='col-md-4 mb-4'>
					<H5 textAlign='center'>Uprise Ratings</H5>
					<PurpleBox>
						<Fragment>
							<H1 color={primary.purple} textAlign='center' className='mb-3'>
								90%
							</H1>
						</Fragment>
					</PurpleBox>
					<H6 className='m-b-0' textAlign='center' color={extended.charcoal.two}>
						User ratings for Uprise
					</H6>
					<Medium className='m-t-0' fontStyle='italic' textAlign='center' color={extended.charcoal.three}>
						(How would you rate Uprise out of 10)
					</Medium>
				</Col>
				<Col className='col-md-4 mb-4'>
					<H5 textAlign='center'>Coaching Session Ratings</H5>
					<PurpleBox>
						<Fragment>
							<H1 color={primary.purple} textAlign='center' className='mb-3'>
								4.8/5
							</H1>
						</Fragment>
					</PurpleBox>
					<H6 textAlign='center' color={extended.charcoal.two}>
						Average score out of five stars for all coaching call sessions since the start of the current
						contract
					</H6>
				</Col>
				<Col className='col-md-4 mb-4'>
					<H5 textAlign='center'>Employee Engagement Levels</H5>
					<PurpleBox>
						<Fragment>
							<H1 color={primary.purple} textAlign='center' className='mb-3'>
								85%
							</H1>
						</Fragment>
					</PurpleBox>
					<H6 textAlign='center' color={extended.charcoal.two}>
						How likely are you to recommend your company as a great place to work?
					</H6>
				</Col>
			</Row>
		</>
	)
}

export default Feedback
