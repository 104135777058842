import React, {useState} from "react"
import styled from "styled-components"
import {SingleDatePicker, isInclusivelyAfterDay, isInclusivelyBeforeDay} from "react-dates"
import {func, string, bool} from "prop-types"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import {backgrounds, extended, primary} from "@uprise/colors"

// import CalendarIcon from "../../../assets/images/icons/svg/icon-bookings.svg"
import moment from "moment"

const SingleDatePickerElementWrapper = styled.div`
	display: flex;
	justify-content: space-around;

	.SingleDatePicker {
		width: 100%;
	}

	.SingleDatePicker_picker .SingleDatePicker_picker_1 {
		border-radius: 10px;
		box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	}

	.DayPicker {
		border-radius: 10px;
		box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	}

	.DateInput_fang {
		bottom: 53px !important;
	}

	.SingleDatePickerInput {
		display: flex;
		justify-content: space-between;
		border-radius: 4px;
		border: solid 1px #d4cbfb;
		padding: 2px 4px 2px 8px;
	}

	.DateInput {
		/* width: 80%; */
	}

	.DateInput_input {
		font-family: "Proxima Nova";
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: #6d6c79;
		padding: 0;
	}

	.SingleDatePickerInput_calendarIcon {
		padding: 0;
	}

	.CalendarDay__selected_4 {
		background: ${primary.purple};
		border-radius: 5px;
	}
`

const DatePicker = ({date, onChange, allowPreviousDates, allowFutureDates, ...props}) => {
	const [focused, setFocused] = useState(false)

	return (
		<SingleDatePickerElementWrapper>
			<SingleDatePicker
				onDateChange={date => {
					onChange(date.format("YYYY-MM-DD"))
				}}
				onFocusChange={({focused}) => setFocused(focused)}
				focused={focused}
				numberOfMonths={1}
				date={date}
				openDirection={"up"}
				isOutsideRange={
					allowPreviousDates && allowFutureDates
						? () => false
						: !allowPreviousDates && !allowFutureDates
						? day => {
								return !isInclusivelyAfterDay(day, moment()) || !isInclusivelyBeforeDay(day, moment())
						  }
						: !allowPreviousDates
						? day => !isInclusivelyAfterDay(day, moment())
						: !allowFutureDates
						? day => !isInclusivelyBeforeDay(day, moment())
						: null
				}
				// isOutsideRange={allowPreviousDates ? () => false : day => !isInclusivelyAfterDay(day, moment())}
				displayFormat='Do MMM YYYY'
				hideKeyboardShortcutsPanel
				withFullScreenPortal={window.innerWidth < 400}
				{...props}
			/>
		</SingleDatePickerElementWrapper>
	)
}

DatePicker.propTypes = {
	date: string.isRequired,
	onChange: func.isRequired,
	allowFutureDates: bool,
	allowPreviousDates: bool
}

DatePicker.defaultProps = {
	date: "",
	onChange: () => {},
	allowFutureDates: true,
	allowPreviousDates: true
}

export default DatePicker
