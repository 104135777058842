export const retrainingThinkingSleepPredictions = [
	{
		_id: 0,
		_next: 1,
		text: "I can help you do a quick check to see if you’ve set yourself up for the best sleep possible",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "Ok. Thanks.",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		_next: 3,
		text: "Great. So I’m interested in your predictions of what will happen if you don’t get enough sleep.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		_next: 5,
		text:
			"Most people predict that they won’t cope well and this leads to staying awake and worrying about not sleeping",
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "retraining-thinking-sleep-predictions-v1",
		taskId: "exercise-retraining-thinking-sleep-predictions",
		questions: [0, 1, 2, 3, 4],
		recurring: true,
		questionIndex: 0,
		values: [
			{
				label: "That would be amazing",
				value: "start",
				questionIndex: 0
			}
		],
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		text:
			"Thanks for doing the exercise. This is an evidence-based skill for improving sleep and I’d recommend doing it a few times along with other skills in this course before so that you can get a better sleep.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 6,
			_next: 7,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 7,
		_end: true,
		text: "Thanks, I'll take you back to the dashboard 👋",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
