export const testForDrugUse = [
	{
		_id: 0,
		text:
			"This is a test for whether or not your drug use might require professional support in order to recover. It’s a measure that’s recommended by most peak bodies called the DAST.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text: "There are 10 questions so it should only a minute or so",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",

			surveyId: "self-test-drug-use-v1",
			taskId: "exercise-self-test-drug-use",
			questions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
			values: [
				{
					label: "That’s ok",
					value: "start",
					questionIndex: 0,
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		createdAt: new Date(),
		replyType: "calcScore",
		questionRange: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
		nextRange: [5, 8, 13, 18],
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	// range 0
	{
		_id: 5,
		_next: 21,
		text: "You scored in the ‘Healthy’ range. It’s not likely that you have a problem with drugs.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	// range 1
	{
		_id: 6,
		_next: 7,
		text: "You scored in the ‘Risky’ range.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 7,
			_next: 8,
			optionType: "radio",
			values: [
				{
					label: "What would you recommend I do?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		_next: 9,
		text:
			"We’d recommend educating yourself about the benefits of abstinence, starting to set some goals around reducing your use and doing an exercise called ‘Motivational Interviewing’ about your use.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 9,
			_next: 10,
			optionType: "radio",
			values: [
				{
					label: "Anything else?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		_next: 21,
		text:
			"It might also help to speak with several professionals to get advice about abstinence or harm-minimisation, especially if you or others feel as though your use is a problem.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	// range 2
	{
		_id: 11,
		text: "You scored in the ‘Harmful’ range. This is the second highest possible level for problematic use.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 12,
			_next: 13,
			optionType: "radio",
			values: [
				{
					label: "What would you recommend I do?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 13,
		text:
			"We’d recommend seeking professional help rather than trying to recover on your own. It’s possible that you might meet criteria for a diagnosis of ‘Substance Dependence’ so please talk with your doctor to check properly.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 14,
			_next: 15,
			optionType: "radio",
			values: [
				{
					label: "Anything else?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 15,
		_next: 21,
		text:
			"Check if there is a drug and alcohol hotline in your country or area. This is a good place to start to find out about services that are available and to get starting advice.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	// Range 3
	{
		_id: 16,
		text:
			"You scored in the ‘Severe’ range. This is the highest possible level for problem drug use and you would almost definitely meet criteria for a diagnosis of Substance Dependence’.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 17,
			_next: 18,
			optionType: "radio",
			values: [
				{
					label: "What would you recommend I do?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 18,
		text:
			"We’d recommend that you seek professional help as soon as possible to give yourself the best chance of recovering.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 19,
			_next: 20,
			optionType: "radio",
			values: [
				{
					label: "Anything else?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 20,
		_next: 21,
		text:
			"Check if there is a drug and alcohol hotline in your country or area. This is a good place to start to find out about services that are available and to get starting advice.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	//
	{
		_id: 21,
		text:
			"Thanks for doing the test with me. There are millions of people who struggle with drug use however there are groups both online and in-person that support a stigma and judgment free space to get support.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 22,
			_next: 23,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 23,
		text:
			"Whether or not you are facing a drug issue right now, please be a part of supporting change to end the stigma around drug use so that people can recover without the burden of shame on top.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 24,
		_end: true,
		text: "Great, I'll take you back to the dashboard 👋",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
