export const retrainingThinking = [
	{
		_id: 0,
		text: "Would you like to do an exercise where I can show you the basics of how to retrain unhelpful thoughts?",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				},
				{
					label: "Not right now. Take me back.",
					value: "back",
					replyType: "dialogue",
					response: "Ok sure.. taking you back"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text:
			"This exercise is easier if you’ve already watched the Mindset video that shows you how to use the Retraining Thinking Skill.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		text: "Did you want to watch it now or are you ok to jump into using the skill?",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 4,
			_next: 6,
			optionType: "radio",
			replyType: "survey",
			recurring: true,
			surveyId: "retraining-thinking-v1",
			taskId: "exercise-retraining-thinking",
			values: [
				{
					label: "Show me the video that explains Retraining Thinking",
					videoURI:
						"https://player.vimeo.com/external/218729113.sd.mp4?s=8e3f73f332bca4871345a883706fcbccaf691327&profile_id=165",
					vimeoId: "218729113",
					value: "video",
					taskId: "video-retraining",
					replyType: "dialogue",
					quickReplies: {
						_id: 5,
						_next: 6,
						optionType: "radio",
						replyType: "survey",
						recurring: true,
						surveyId: "retraining-thinking-v1",
						taskId: "exercise-retraining-thinking",
						values: [
							{
								label: "I've watched the video",
								value: "start",
								questionIndex: 0
							}
						]
					}
				},
				{
					label: "I’m ok to jump into the skill",
					value: "start",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		text:
			"So the first step is to look at the costs of this thought. If you can clearly understand the costs then it becomes easier to drop this thought. Does that make sense?",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 7,
			_next: 9,
			optionType: "radio",
			keepIt: false,
			recurring: true,
			surveyId: "retraining-thinking-v1",
			taskId: "exercise-retraining-thinking",
			values: [
				{
					label: "👍 I understand",
					value: "okay",
					replyType: "dialogue",
					questionIndex: 1
				},
				{
					label: "Can you explain why costs are important?",
					value: "more",
					replyType: "dialogue",
					response:
						"One theory of why unhelpful thoughts keep appearing is that they have very subtle payoffs. So retraining is about seeing that the costs are far bigger than the payoffs. Sometimes the payoffs aren’t clear so don’t worry if you don’t know what they are. Also, some people start to feel sad when they see the real costs of their unhelpful thinking patterns. Psychologists see this type of sadness as healing because it can be about grieving for all of the losses that the unhelpful thoughts have caused.",
					quickReplies: {
						_id: 8,
						_next: 9,
						optionType: "radio", // or 'checkbox',
						replyType: "dialogue",
						surveyId: "retraining-thinking-v1",
						taskId: "exercise-retraining-thinking",
						recurring: false,
						values: [
							{
								label: "👍 thanks for clarifying.",
								value: "okay",
								replyType: "dialogue",
								questionIndex: 1
							}
						]
					}
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 9,
		text:
			"Thanks. It’s normal to feel a bit down seeing this. However, research shows that recognizing the full costs does improve our ability to choose a different way of thinking. Next, we look at the accuracy of the thought.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 10,
			_next: 11,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 11,
		text:
			"In this step, we look for all of the reasons why the thought isn’t 100% true in all situations at all times. The more reasons that you can think of, the more likely you will be to change this thought. ",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 12,
			_next: 13,
			optionType: "radio",
			questions: [2, 3, 4],
			surveyId: "retraining-thinking-v1",
			taskId: "exercise-retraining-thinking",
			recurring: true,
			values: [
				{
					label: "👍",
					value: "start",
					replyType: "dialogue",
					questionIndex: 2
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 13,
		text:
			"Hopefully it’s helped you to feel a little better but don’t worry if the unhelpful thought isn’t fully resolved. Thoughts occur in clusters of related beliefs. So you might still need to retrain some of the other thoughts in the cluster.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 14,
			_next: 15,
			optionType: "radio",
			surveyId: "retraining-thinking-v1",
			questions: [5, 6],
			taskId: "exercise-retraining-thinking",
			recurring: true,
			values: [
				{
					label: "Actually, I feel better about that thought. Let’s keep going.",
					value: "okay",
					replyType: "dialogue",
					questionIndex: 6
				},
				{
					label: "I still feel like the unhelpful thought is true. Tell me about clusters.",
					value: "okay",
					replyType: "dialogue",
					questionIndex: 5
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 15,
		_next: 16,
		text: "Thanks for letting me know and for doing the exercise with me. 🏋️",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 16,
		_next: 17,
		text:
			"The goal was to help you learn the basics of retraining your thinking by questioning an unhelpful thought.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 17,
		_next: 18,
		text: "Later on we will show you how to use other questions to get better at retraining thinking.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 18,
		_next: 19,
		text: "Thanks for doing the exercise.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 19,
		_end: true,
		text: "👋 See you next time, I'll take you back..",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
