export const handleError = resp => {
	if (resp.error) {
		let errors = {}

		switch (resp.error) {
			case "PASSWORD_SIGN_ON_NOT_SUPPORTED":
				errors.signInType = {
					type: "error",
					text: "Password signup not supported, please sign up with Azure"
				}
				break
			case "AlreadyExists":
				errors.email = {
					type: "error",
					text: "This email is already in use"
				}
				break
			case "BadEmployerCode":
				errors.employerCode = {
					type: "error",
					text: "Unknown employer code, have you entered it correctly?"
				}
				break
			case "ExpiredEmployerCode":
				errors.employerCode = {
					type: "error",
					text: "This code has expired, please ask your employer for a new one"
				}
				break
			case "OutOfCodesLimit":
				errors.employerCode = {
					type: "error",
					text: "This employer code is full, please ask your employer to organise more openings"
				}
				break
			case "CodeInUse":
				errors.employerCode = {
					type: "error",
					text: "Access Code already in use"
				}
				break
			case "OutOfCodesLimitLendlease":
				errors.employerCode = {
					type: "error",
					text:
						"Sorry, there are a limited number of positions and this Uprise cohort is over-subscribed. \n\n We apologise for the inconvenience. A new cohort will be launch in due course, which you&#39;re welcome to join.\n\n In the meantime, you can contact your local EAP if you need any assistance."
				}
				break
			case "WeakPassword":
				errors.password = {type: "error", text: resp.message}
				break
			case "InvalidAccessCode":
				errors.employerCode = {
					type: "error",
					text: "Access Code is Invalid"
				}
				break
			case "ValidationFail":
				switch (resp.errors[0].property) {
					case "instance.name":
						errors.name = {type: "error", text: "Please enter valid name"}
						break
					case "instance.password":
						errors.password = {type: "error", text: "Please enter a 10 character length password"}
						break
				}
				break
			default:
				errors.networkError = {
					type: "error",
					text: "Something went wrong, reload the page or come back later"
				}
		}

		return Object.values(errors)[0]
	}
}
