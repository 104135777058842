import PropTypes from "prop-types"
import React, {useState, useEffect} from "react"
import Styled from "styled-components"
import FormValidate from "form-validate.js"
import {useHistory} from "react-router-dom"
import {connect} from "react-redux"
// Utils
import {handleError} from "../User/utils"
// Actions
import {updateUserState} from "actions/userStateActions"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds} from "@uprise/colors"
// Components
import {Card} from "@uprise/card"
import {Button} from "@uprise/button"
import {TextInput} from "@uprise/form"
import {Alert} from "@uprise/alert"
import {H2} from "@uprise/headings"
import {P} from "@uprise/text"
import {text} from "@uprise/typography"
// Validation
import constraints from "validation/preOnboarding"
// Api
import {post} from "helpers/api"

const Content = Styled.section`
	display: flex;
	justify-content: center;
`
const CardWrap = Styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	width: 486px;

	@media (max-width: 1200px) {
		width: 486px;

	}

	@media (max-width: 992px) {
		width: 100%;
	}
`

const PreOnboardingForm = ({userState, updateUserState}) => {
	let history = useHistory()

	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [accessCode, setAccessCode] = useState("")
	const [phone, setPhone] = useState("")
	const [name, setName] = useState("")
	const [error, setError] = useState({type: "", text: ""})
	const [isLoading, setIsLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)

	useEffect(() => {
		if (userState?.name?.length > 0) {
			setName(userState.name)
			delete constraints["name"]
		}

		if (userState?.employerCode?.length > 0) {
			setAccessCode(userState.employerCode)
			delete constraints["accessCode"]
		}

		if (userState.phone?.length > 0) {
			setPhone(userState.phone)
			delete constraints["phone"]
		}

		const validator = new FormValidate(constraints)
		setValidator(validator)
	}, [userState])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			if (submitted) {
				setValidation(controls)
			}
		})
	}

	const _handleSubmit = () => {
		setSubmitted(true)
		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				// a good place to update the validation being displayed.
			})
			return
		} else {
			_handleLogin()
		}
	}

	const _handleLogin = () => {
		setIsLoading(true)

		post("complete-signup", {phone, employerCode: accessCode, name})
			.then(response => {
				if (response.error) {
					const error = handleError(response)

					setError({text: error.text, type: error.type})
					setIsLoading(false)
				} else {
					updateUserState(response.user_state)
					setIsLoading(false)
				}
			})
			.catch(err => {
				console.log(err)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		if (userState?.course && userState?.phone && userState?.name) {
			history.push("onboarding")
		}
	}, [userState])

	return (
		<Content>
			<CardWrap>
				<H2 className='m-b-4' textAlign='left' width='100%'>
					You&apos;re almost there
				</H2>

				<Alert className='m-b-5' type='success'>
					Email address succesfully verified!
				</Alert>

				<P className='m-b-4' fontSize={text.t7}>
					Please enter your details below to complete sign-up
				</P>

				<Card
					border={`1px solid ${extended.purple.five}`}
					shadow={false}
					backgroundColor={backgrounds.white}
					padding={spacing.s5}
					width='100%'>
					{error?.text?.length > 0 && (
						<Alert className='m-b-5' type={error.type}>
							{error.text}
						</Alert>
					)}
					<form onChange={event => _validateForm(event)}>
						{!userState.name && (
							<TextInput
								onChange={e => setName(e.target.value)}
								validation={validation}
								value={name}
								validateControl='name'
								id='name'
								name='name'
								label='Full name'
								isFirst
								isRequired
							/>
						)}
						{!userState.employerCode && (
							<TextInput
								onChange={e => setAccessCode(e.target.value)}
								validation={validation}
								value={accessCode}
								validateControl='accessCode'
								id='accessCode'
								name='accessCode'
								label='Access code'
								isFirst
								isRequired
							/>
						)}
						{userState?.phone?.length === 0 && (
							<TextInput
								onChange={e => setPhone(e.target.value)}
								validation={validation}
								value={phone}
								id='phone'
								type='text'
								name='phone'
								label='Contact number'
								isLast={true}
								isRequired
							/>
						)}
					</form>
				</Card>

				<Button
					data-testid='get-started-button'
					className='m-t-4 m-b-7'
					variant='primary'
					title='Continue'
					size='large'
					fullWidth={true}
					width='100%'
					isLoading={isLoading}
					disabled={false}
					onClick={() => _handleSubmit()}
				/>
			</CardWrap>
		</Content>
	)
}

const mapDispatchToProps = dispatch => ({
	updateUserState: resp => dispatch(updateUserState(resp))
})

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PreOnboardingForm)
