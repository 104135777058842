import React, {Fragment, useState} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import {Modal} from "@uprise/modal"
import moment from "moment-timezone"

// Actions
import {fetchBookings} from "actions/bookingsActions"
// Colors
import {backgrounds, extended} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Components
import {Button} from "@uprise/button"
import {Medium} from "@uprise/text"
import {H3} from "@uprise/headings"
// Icons
import Icons from "constants/Icons"
// Api
import {post} from "helpers/api"

const SuccessImage = Styled.img`
      height: 90px;
      width: 90px;
`

const Date = Styled.span`

`

const DateBar = Styled.div`
	padding: 9px 0;
	width: 420px;
	height: 40px;
	border-radius: 5px;
	background-color: ${extended.lightOrange.five};
`

const CancelModal = ({isOpen, handleClose, userTimezone, booking, fetchBookings, courseType, ...props}) => {
	const [cancelSuccess, setCancelSuccess] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const showCancelCostDetails = courseType !== "rtw"

	const _handleCancel = () => {
		setIsLoading(true)

		post("cancelBooking", {
			coachId: parseInt(booking.coachId),
			eventId: booking.data.id,
			duration: booking.duration || 30,
			userTimezone: userTimezone || moment.tz.guess()
		})
			.then(resp => {
				if (resp.error) {
					alert("Unable to cancel booking. Reason: " + resp.error)
				} else {
					setCancelSuccess(true)
					setIsLoading(false)
					fetchBookings()
				}
			})
			.catch(error => {
				console.log(error)
			})
	}

	const _handleClose = () => {
		handleClose()
	}

	return (
		<Modal
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='500px'
			textAlign='center'
			isOpen={isOpen}
			handleClose={handleClose}>
			{cancelSuccess ? (
				<Fragment>
					<H3 className='m-b-10'>Booking cancelled</H3>
					<SuccessImage className='m-b-7' src={Icons.success} />

					<Medium className='m-b-5'>Booking has successfully been cancelled.</Medium>

					<Button variant='primary' size='medium' title='Close' onClick={() => _handleClose()} />
				</Fragment>
			) : (
				<Fragment>
					<H3 className='m-b-10'>Are you sure you want to cancel?</H3>
					{showCancelCostDetails && (
						<Medium className='m-b-5'>
							Bookings cancelled with less than 24 hours of the booking start time will be charged to your
							employer
						</Medium>
					)}
					{showCancelCostDetails && (
						<Medium className='m-b-10'>
							If booking is cancelled with more than 24 hours in advance they are cancelled free of
							charge.
						</Medium>
					)}

					<Button
						variant='primary'
						size='medium'
						title='Cancel booking'
						isLoading={isLoading}
						onClick={() => _handleCancel()}
					/>
				</Fragment>
			)}
		</Modal>
	)
}

const mapDispatchToProps = dispatch => ({
	fetchBookings: () => dispatch(fetchBookings())
})

function mapStateToProps(state) {
	return {
		userState: state.userState,
		courseType: state.userState.courseType
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelModal)
