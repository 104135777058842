import React, {Component, Fragment} from "react"
import {number, string, object} from "prop-types"
import {connect} from "react-redux"
import Styled, {css} from "styled-components"
import moment from "moment-timezone"
import {useHistory} from "react-router-dom"

// Components
import {EmojiDonut} from "@uprise/charts/EmojiDonut"
import {Card} from "@uprise/card"
import {Button} from "@uprise/button"
import {H2, H5} from "@uprise/headings"
import {Medium} from "@uprise/text"
// Colors
import {primary, backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"

const Content = Styled.section`
	position: relative;
	display: flex;
	flex-direction: column;
`
const Divider = Styled.div`
	width: 100%;
	height: 2px;
	background: #edeafa;
	margin: 20px 0;
`

const ScoreCard = ({
	className,
	screeners,
	cutoutPercentage,
	width,
	height,
	emojix,
	emojiy,
	emojiWidth,
	emojiHeight
}) => {
	let history = useHistory()

	let backgroundColor
	let hoverBackgroundColor
	let borderColor = [primary.purple, backgrounds.fadedPurple]
	let data = [],
		image = []

	screeners.map(screener => {
		backgroundColor = [primary.purple, backgrounds.fadedPurple]
		hoverBackgroundColor = [primary.purple, backgrounds.fadedPurple]
		if (screener?.dialogueId === "stressCheck") {
			if (screener.score < 28) {
				image.push(require("assets/images/emojis/emoji-high-score-2x.png"))
			} else if (screener.score <= 50) {
				image.push(require("assets/images/emojis/emoji-mid-score-2x.png"))
			} else {
				image.push(require("assets/images/emojis/emoji-low-score-2x.png"))
			}
		} else if (screener?.dialogueId === "wellbeingCheck") {
			backgroundColor = ["rgb(255, 170, 85)", "rgb(255, 237, 213)"]
			hoverBackgroundColor = ["rgb(255, 170, 85)", "rgb(255, 237, 213)"]
			if (screener.score < 28) {
				image.push(require("assets/images/emojis/emoji-low-score-2x-orange.png"))
			} else if (screener.score <= 50) {
				image.push(require("assets/images/emojis/emoji-mid-score-2x-orange.png"))
			} else {
				image.push(require("assets/images/emojis/emoji-high-score-2x-orange.png"))
			}
		}

		if (screener) {
			data.push({
				datasets: [
					{
						data: [screener.score, 100 - screener.score],
						backgroundColor,
						borderColor,
						hoverBackgroundColor,
						borderWidth: 0,
						weight: 1
					}
				]
			})
		}
	})

	const options = {
		cutoutPercentage: cutoutPercentage,
		maintainAspectRatio: false,
		tooltips: {enabled: false}
	}

	return (
		<Card className={className} shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
			<Content>
				{screeners.map((screener, idx) =>
					screener.score >= 0 ? (
						<Fragment key={idx}>
							{!!idx && <Divider />}
							<H5 className='m-b-3 m-t-0' textAlign='center' width='100%'>
								{idx === 0 ? "Wellbeing Score" : "Stress Score"}
							</H5>
							<EmojiDonut
								image={image[idx]}
								width={width}
								height={height}
								emojix={emojix}
								emojiy={emojiy}
								emojiWidth={emojiWidth}
								emojiHeight={emojiHeight}
								data={data[idx]}
								options={options}
							/>
							<H2 textAlign='center'>{screener.score}%</H2>
							<Medium className='m-b-3' textAlign='center'>
								{idx === 0 ? "(higher is better)" : "(lower is better)"}
							</Medium>
						</Fragment>
					) : (
						<Medium className='m-t-5 m-b-10' textAlign='center' key={idx}>
							No survey data
						</Medium>
					)
				)}
				<Medium className='m-b-3' textAlign='center'>
					Last checked - {moment.unix(screeners?.[0]?.timestamp).format("DD MMM")}
				</Medium>

				<Button
					variant='tertiary'
					title='Check Now'
					size='extra-small'
					fullWidth={true}
					disabled={false}
					isPressed={false}
					isLoading={false}
					onClick={() => history.push(`/bot/wellbeingStressWeekly`)}
				/>
			</Content>
		</Card>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(ScoreCard)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
ScoreCard.propTypes = {
	cutoutPercentage: number,
	width: number,
	height: number
}

ScoreCard.defaultProps = {
	cutoutPercentage: 75,
	width: 100,
	height: 100
}
