import React, {useState, Component, Fragment} from "react"
import {connect} from "react-redux"
import Styled, {css} from "styled-components"
import {useHistory, useLocation} from "react-router-dom"

// Components
import {Container, Row, Col} from "@uprise/grid"
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {H2, H3, H4, H5} from "@uprise/headings"
import {Medium, ExtraSmall} from "@uprise/text"
import {List} from "@uprise/list"
import {MoreInfoModal} from "components/Shared/Coach/MoreInfoModal"
import {getCourseType} from "helpers/utils"

// Colors
import {extended, primary, backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"

const GetACoachStyles = Styled.section`
	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
 	}
`

const ContentWrap = Styled.section`
  display: flex;
	flex-direction: row;
	
	@media (max-width: 767px) {
		flex-direction: column;
		text-align: center;
	}
`

const ImageWrap = Styled.section`
      height: 228px;
      display: flex;
      justify-content: center;
	align-items: center;
	padding: 30px 40px 30px 30px;
`
const ButtonWrap = Styled.section`
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: -${spacing.s6};

	& button {
		width: 100%;
		@media (min-width: 1160px) {
			width: 170px;
		}
	}
`

const GetACoachImage = Styled.img`
      height: 150px;
	width: 195px;
	
	@media (max-width: 767px) {
		margin-top: 30px;
		height: 200px;
		width: 250px;
	}
`

const ListWrap = Styled.div`
	@media (max-width: 1024px) {
		width: 70%;
		margin: 0 auto;
		text-align: left;
		margin-top: ${spacing.s6};
		margin-bottom: ${spacing.s6};
	}
`

const ContentRight = Styled.div`
      padding: ${spacing.s7} ${spacing.s9} ${spacing.s7} 0;
	width: 100%;
	
	@media (max-width: 1024px) {
		padding: ${spacing.s7} ${spacing.s7} ${spacing.s7} ${spacing.s7};
	}
`

const GetACoach = ({className, skills, meta, firstLoad}) => {
	let history = useHistory()

	const [showMoreInfo, setShowMoreInfo] = useState(false)

	const list = [
		{
			title:
				getCourseType() === "rtw"
					? "All Coaches are Certified Health and Wellness Coaches"
					: "All our coaches are registered psychologists / counsellors"
		},
		{title: "Coaches help you apply Uprise skills to your life in 30 min coaching sessions via phone"}
	]
	return (
		<GetACoachStyles className={className}>
			<H3 className='m-b-0-5'>Get a Coach</H3>

			<Medium color={extended.charcoal.one} className='m-b-6'>
				Uprise is 4x more effective with coaching
			</Medium>

			<Card shadow={true} backgroundColor={backgrounds.white}>
				<ContentWrap>
					<ImageWrap>
						<GetACoachImage src={require(`assets/images/art/coaching-for-a-specific-issue@3x.png`)} />
					</ImageWrap>
					<ContentRight>
						<H5 color={primary.charcoal} className='m-b-2 m-t-0 m-b-sm-4 m-t-sm-4'>
							How does coaching work?
						</H5>
						<ListWrap>
							<List items={list} />
						</ListWrap>
						<ButtonWrap>
							<Button
								className='m-r-sm-0 m-r-3 m-t-4'
								title='Get a Coach'
								variant='primary'
								size='medium'
								fullWidth={false}
								onClick={() => history.push("select-coach", {from: location.pathname})}
							/>

							<MoreInfoModal isOpen={showMoreInfo} handleClose={() => setShowMoreInfo(false)} />

							<Button
								className='m-t-4'
								title='Find out more'
								variant='secondary'
								size='medium'
								fullWidth={false}
								onClick={() => setShowMoreInfo(true)}
							/>
						</ButtonWrap>
					</ContentRight>
				</ContentWrap>
			</Card>
		</GetACoachStyles>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(GetACoach)
