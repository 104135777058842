import {post, get} from "helpers/api"
import {updateUserState} from "actions/userStateActions"
import moment from "moment-timezone"
import {STORE_COACH, LOADING_COACH} from "./types"

 

export function getCoachByEmail(email) {
	return dispatch => {
		dispatch(loadingCoach(true))
		get(`coach/email/${email}`, {email})
			.then(resp => {
				dispatch(storeCoach(resp))
				dispatch(loadingCoach(false))
			})
			.catch(err => {
				dispatch(loadingCoach(false))
				console.error(err)
			})
	}
}

export function loadingCoach(status) {
	return {
		type: LOADING_COACH,
		payload: {loading: status}
	}
}

function storeCoach(resp) {
	return {
		type: STORE_COACH,
		payload: resp
	}
}
