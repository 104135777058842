import React, {Fragment, useState, useEffect} from "react"
import {bool, object, string} from "prop-types"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import {connect} from "react-redux"
import moment from "moment-timezone"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// Utils
import {taskIdWhitelist} from "helpers/utils"
// Components
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {Container, ContainerFluid, Row, Col} from "@uprise/grid"
import {ProgressBar} from "@uprise/charts"
import {Medium, Note, Small} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
import CoachCard from "components/Shared/Coach/CoachCard"
// spacing
import {spacing} from "@uprise/spacing"
// Icons
import Icons from "constants/Icons"

const CoachStyles = Styled.section`
`

const Content = Styled.div`
	padding: ${spacing.s7}
      display: flex;
      flex-direction: row;
`

export const YourCoach = ({className, coach, pick, change}) => {
	if (coach) {
		return (
			<CoachStyles className={className}>
				<H3 className='m-b-6'>Your Coach</H3>
				<CoachCard coach={coach} change={true} />
			</CoachStyles>
		)
	} else {
		return <Medium>Loading</Medium>
	}
}
