import React, {useState, useEffect, Fragment} from "react"
import Styled from "styled-components"
// Colors
import {extended, primary, secondary, backgrounds} from "@uprise/colors"
// Spacing
import {spacing} from "@uprise/spacing"
// Typography
import {text} from "@uprise/typography"

const RadioButton = ({selected, disabled, reply, handlePress, numOptions}) => {
	const Button = Styled.button`
		width: auto;
		min-width: 100px;
		font-family: "Proxima Nova";
		background-color: ${props => (props.selected ? secondary.electricBlue : extended.purple.five)};
		margin-right: ${spacing.s4};
		margin-bottom: ${spacing.s4};
		font-size: ${props => (props.numOptions > 5 ? `${text.t4}` : `${text.t7}`)};  
		border: 0px;
 		padding: ${props => (props.numOptions > 5 ? `${spacing.s4}` : `${spacing.s5}`)};
		border-radius: 10px;
		color: ${props => (props.selected ? backgrounds.white : primary.charcoal)};


		@media (max-width: 768px) {
			font-size: ${text.t6};
			padding: 0 1rem;
		     	height: 30px;
	      }

		@media (max-width: 1200px) {
 			padding: 0 1rem;
			height: 40px;
		}
		


		@media (max-width: 475px) {
			font-size: ${text.t5};
			background-color: ${props => (props.selected ? secondary.electricBlue : "transparent")};
			color: ${props => (props.selected ? backgrounds.white : primary.purple)};
			border-color: ${props => (props.selected ? secondary.electricBlue : primary.purple)};
			border-width: 1px;
			border-style: solid;
			padding: ${spacing.s4};
			margin-right: 0;
			width: 100%;
		}
	`

	return (
		<Button
			selected={selected}
			disabled={disabled}
			numOptions={numOptions}
			onClick={e => handlePress(e.target.value)}
			dangerouslySetInnerHTML={{__html: reply.label}}
		/>
	)
}

export default RadioButton
