import React from "react"
import Styled from "styled-components"
// Colors
import {backgrounds} from "@uprise/colors"
// Components
import {H3} from "@uprise/headings"
import {Card} from "@uprise/card"
import {Accordian} from "@uprise/accordian"
import {getCourseType} from "helpers/utils"
const FaqsStyles = Styled.section`
`

const Content = Styled.section`
	width: 100%;
	padding: 25px;
`

export const Faqs = ({className}) => {
	const faqs = [
		{
			header: "Why should I book a coaching call?",
			content:
				"Coaching calls are the highest rated feature of our programs. You can learn the skills by yourself but your coach will show you how to personalise the skills for your specific needs. Coaching calls were rated 92 out of 100 last year."
		},
		{
			header: "What happens on a coaching call?",
			content:
				"A coach helps you solve your life’s challenges by using Uprise skills. You can ask them questions about how to apply a skill, and they’ll tell you about how others in the same situation have coped with it. "
		},
		{
			header: "Who are the coaches?",
			content:
				getCourseType() === "rtw"
					? "All Coaches are Certified Health and Wellness Coaches"
					: "All our coaches are registered psychologists or counsellors."
		},
		{
			header: "Can I keep my coach or change my coach?",
			content: "You can keep the same coach all the way through your program or change your coach at any time."
		},
		{
			header: "How long does a coaching call take?",
			content: "A coaching call takes only 30 mins."
		},
		{
			header: "What do I need to do before a coaching call?",
			content:
				"Before a coaching call, you should complete a skill on Uprise, so you can discuss it with your coach."
		},
		{
			header: "How many coaching calls can I book?",
			content:
				"That depends on your organisation’s subscription plan. If there is a limit, you’ll see a message about it when booking a coaching call."
		},
		{
			header: "Can I cancel/reschedule a coaching call?",
			content:
				"Yes, you can reschedule a coaching call upto 3 hours before it happens and you can cancel it upto 24 hours in advance."
		}
	]

	return (
		<FaqsStyles className={className}>
			<H3 className='m-b-6'>Frequently Asked Questions</H3>
			<Card backgroundColor={backgrounds.white}>
				<Content>
					<Accordian content={faqs} />
				</Content>
			</Card>
		</FaqsStyles>
	)
}
