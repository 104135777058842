import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import FormValidate from "form-validate.js"

// Actions
import {updateUserState} from "actions/userStateActions"
// Components
import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
import {Medium} from "@uprise/text"
import {TextInputHorizontal, SelectHorizontal} from "@uprise/form"
import {Alert} from "@uprise/alert"

// Validation
import constraints from "validation/profile"
// Colors
import {primary, extended, backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Api
import {post} from "helpers/api"

import DropDown from "../DropDown"

import Countrycode from "../../../utils/countrycode"

const ProfileStyles = Styled.section`
`

const ContentWrap = Styled.section`
      display: flex;
	flex-direction: column;
`

const HeaderStyles = Styled.section`
      display: flex;
	flex-direction: row;
      justify-content: space-between;
`

const Edit = Styled.div`
      text-align: right;
      align-self: flex-end;

      &:hover {
            cursor: pointer
      }
`

const PhoneInputWrapper = Styled.div`
	display : flex;
`

const DropDownWrapper = Styled(DropDown)`
margin-right: 10px;	
`

const Table = Styled.table`
      width: 100%;
`

const Tbody = Styled.tbody`
`

const Tr = Styled.tr`
      margin-bottom: ${spacing.s3};
`

const Td = Styled.td`
      border-bottom: 1px solid ${extended.purple.five};
      padding-top: ${spacing.s3};
      padding-bottom: ${spacing.s3};
`

const Profile = ({className, userState, updateUserState}) => {
	const [edit, setEdit] = useState(false)
	const [name, setName] = useState(userState.name)
	const [phone, setPhone] = useState(userState.phone)
	const [email, setEmail] = useState(userState.email)

	const [countryCode, setCountryCode] = useState(Countrycode[189])
	const [countryName, setCountryName] = useState(Countrycode[189].name)

	// Form
	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [error, setError] = useState({type: "", value: ""})
	const [success, setSuccess] = useState({type: "", value: ""})
	const [languages, setLanguages] = useState([])
	const [language, setLanguage] = useState("")

	useEffect(() => {
		const defaultValues = {name, phone, email}
		const validator = new FormValidate(constraints, null, defaultValues)
		setValidator(validator)
	}, [])

	useEffect(() => {
		if (userState.countryName) {
			const country = Countrycode.find(code => {
				return code.name === userState.countryName
			})
			setCountryCode(country)
			setCountryName(country.name)
		} else if (userState.countryCode) {
			let selectedCountryCode = Countrycode.find(code => {
				return code.label === userState.countryCode
			})
			setCountryCode(selectedCountryCode)
			setCountryName(selectedCountryCode.name)
		}
	}, [userState])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			setValidation(controls)
		})
	}

	const _handleSave = () => {
		setSuccess("")
		setError("")

		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				// a good place to update the validation being displayed.
			})
			return
		} else {
			post("settings/user", {
				countryCode: countryCode.label,
				countryName,
				phone: phone,
				name: name,
				email: email
			})
				.then(resp => {
					if (resp.error) {
						setError(resp.message)
					} else {
						updateUserState(resp.user_state)
						setSuccess("Success: profile updated")
						setEdit(false)
					}
				})
				.catch(err => {
					alert(err)
				})
		}
	}

	useEffect(() => {
		setLanguage(window.Localize.getLanguage())

		window.Localize.getAvailableLanguages(function(err, languages) {
			if (err) return console.log(err)

			const lang = languages.map(l => {
				return {label: l.name, value: l.code}
			})
			setLanguages(lang)
		})
	}, [])

	const _handleLang = e => {
		window.Localize.setLanguage(e.target.value)
		setLanguage(e.target.value)
	}

	return (
		<ProfileStyles className={className}>
			<HeaderStyles>
				<H3 className='m-b-4'>Profile Details</H3>
				{edit ? (
					<Edit className='m-b-2 m-r-2' onClick={() => _handleSave()}>
						<Medium color={extended.purple.two}>Save</Medium>
					</Edit>
				) : (
					<Edit className='m-b-2 m-r-2' onClick={() => setEdit(true)}>
						<Medium color={extended.purple.two}>Edit</Medium>
					</Edit>
				)}
			</HeaderStyles>
			<Card shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
				<ContentWrap>
					<form onChange={event => _validateForm(event)}>
						<Table>
							<Tbody>
								<Tr>
									<Td width='20%'>
										<Medium weight='bold' color={primary.charcoal}>
											Name
										</Medium>
									</Td>
									<Td>
										{edit ? (
											<TextInputHorizontal
												value={name}
												onChange={e => setName(e.target.value)}
												name='name'
												id='name'
												placeholder='Name'
												type='text'
												isRequired={true}
												validation={validation}
											/>
										) : (
											<Medium>{userState.name}</Medium>
										)}
									</Td>
								</Tr>
								<Tr>
									<Td>
										<Medium weight='bold' color={primary.charcoal}>
											Email Address
										</Medium>
									</Td>
									<Td>
										{edit ? (
											<TextInputHorizontal
												value={email}
												onChange={e => setEmail(e.target.value)}
												name='email'
												id='email'
												placeholder='example@email.com'
												type='text'
												isRequired={true}
												validation={validation}
											/>
										) : (
											<Medium>{userState.email}</Medium>
										)}
									</Td>
								</Tr>
								<Tr>
									<Td>
										<Medium weight='bold' color={primary.charcoal}>
											Phone number
										</Medium>
									</Td>
									<Td>
										{edit ? (
											<PhoneInputWrapper>
												<DropDownWrapper
													items={Countrycode}
													label='Select Code'
													onChange={value => {
														setCountryCode(value)
														setCountryName(value.name)
													}}
													initialSelectedItem={countryCode}
													menuStyle={{position: "absolute", zIndex: 2}}
													customListItemRender={item => (
														<div style={{display: "flex", justifyContent: "space-between"}}>
															<p>{item.name}</p>
															<p>{item.label}</p>
														</div>
													)}
												/>
												<TextInputHorizontal
													value={phone}
													onChange={e => setPhone(e.target.value)}
													name='phone'
													id='phone'
													placeholder='+61 123 45678'
													type='text'
													isRequired={true}
													validation={validation}
												/>
											</PhoneInputWrapper>
										) : (
											<Medium>{`${countryCode && countryCode.label}-${userState.phone}`}</Medium>
										)}
									</Td>
								</Tr>
								<Tr>
									<Td>
										<Medium weight='bold' color={primary.charcoal}>
											Access Code
										</Medium>
									</Td>
									<Td>
										<Medium>{userState.employerCode}</Medium>
									</Td>
								</Tr>
								<Tr>
									<Td>
										<Medium weight='bold' color={primary.charcoal}>
											Language
										</Medium>
									</Td>
									<Td>
										<SelectHorizontal
											onChange={e => _handleLang(e)}
											validation={validation}
											value={language}
											id='language'
											options={languages}
											name='language'
											label='Language'
											isRequired
										/>
									</Td>
								</Tr>
							</Tbody>
						</Table>
					</form>
				</ContentWrap>
				{success && success.length > 0 && (
					<ContentWrap className='m-t-5'>
						<Alert type='success'>{success}</Alert>
					</ContentWrap>
				)}

				{error && error.length > 0 && (
					<ContentWrap className='m-t-5'>
						<Alert type='error'>{error}</Alert>
					</ContentWrap>
				)}
			</Card>
		</ProfileStyles>
	)
}

const mapDispatchToProps = dispatch => ({
	updateUserState: state => dispatch(updateUserState(state))
})

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
