import React, {useEffect, useState} from "react"
import {object, array} from "prop-types"
import Styled from "styled-components"
import {useHistory, Link} from "react-router-dom"
import {connect} from "react-redux"

// Colors
import {extended, backgrounds} from "@uprise/colors"
// Components
import {H3} from "@uprise/headings"
import {H5} from "@uprise/headings"
import {ContainerFluid, Row, Col} from "@uprise/grid"
import {LeftPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import {VerticalProgress} from "components/Shared/Onboarding/VerticalProgress"

// RTW
import PostProgramRtwSteps from "components/Rtw/PostProgram"
import {getRTWItemsList} from "components/Rtw/PostProgram/utils"
// API
import {post} from "helpers/api"
// Actions
import {updateUserState} from "actions/userStateActions"

const Wrap2 = Styled.section`
	background-color: ${extended.purple.six};
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;

	@media (max-width: 1200px) {
		flex-direction: column;
	}

	@media (max-width: 992px) {
		flex-direction: column;
	}
`

const Wrap = Styled.section`
	background-color: ${extended.purple.six};
	width: 100%;
	height: 100%;
`

const PostProgram = ({userState, surveyState, updateUserState}) => {
	let history = useHistory()
	const isThreeMonthSurvey = history.location.pathname.indexOf("follow-up") !== -1
	const ctx = {isThreeMonthSurvey}

	const [activeIndex, setActiveIndex] = useState(0)
	const courseType = userState.courseType
	const [progressCheckpoints, setProgressCheckpoints] = useState(getRTWItemsList(userState, ctx))

	if (courseType !== "rtw") {
		return (
			<Row className='flex-nowrap h-100'>
				<Col>
					<H3 className='mt-5 mb-3' textAlign='center'>
						Seems like you are on wrong link! 🦄
					</H3>
					<H5 textAlign='center'>
						<Link to='/'>Take me back to home</Link>
					</H5>
				</Col>
			</Row>
		)
	}

	const _handleNext = id => {
		let newItems = [...progressCheckpoints]

		newItems.map((item, index) => {
			item.active = false
			if (index === id) {
				item.active = true
			}
		})

		setActiveIndex(id)
		setProgressCheckpoints(newItems)
	}

	const _handleCompleteRtw = () => {
		// Mark it as completed...

		post("complete", {
			module: isThreeMonthSurvey ? "survey-postprogram-3-month-rtw" : "survey-postprogram-rtw",
			completed: true
		})
			.then(res => {
				updateUserState(res.user_state)
				history.push("/dashboard")
			})
			.catch(error => console.error(error))
	}

	const containerHeight = courseType === "rtw" ? "auto" : null

	console.log(progressCheckpoints)
	return (
		<ContainerFluid height='100vh' backgroundColor={backgrounds.fadedPurple}>
			<Row className='flex-nowrap h-100'>
				<LeftPanel width='250px' className='d-none d-sm-none d-md-none d-lg-none d-xl-flex'>
					<VerticalProgress items={progressCheckpoints} />
				</LeftPanel>
				<Wrap2>
					<Wrap className='h-100'>
						<Header
							title={`${isThreeMonthSurvey ? "Follow up Assessment" : "Post Program Assessment"}`}
							textAlign='center'
							width='100%'
							back={location?.state?.from.length ? true : false}
						/>

						<Main className='10px 25px 10px 25px h-100' containerHeight={containerHeight}>
							<PostProgramRtwSteps
								progressCheckpoints={progressCheckpoints}
								handleNext={_handleNext}
								handleComplete={_handleCompleteRtw}
								activeIndex={activeIndex}
								userState={userState}
								isThreeMonthSurvey={isThreeMonthSurvey}
							/>
						</Main>
					</Wrap>
				</Wrap2>
			</Row>
		</ContainerFluid>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		surveyState: state.surveyState
	}
}

export default connect(mapStateToProps, {updateUserState})(PostProgram)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
PostProgram.propTypes = {
	userState: object.isRequired,
	activity: array
}

PostProgram.defaultProps = {}
