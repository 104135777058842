import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import {useHistory, useParams} from "react-router-dom"
import {NavLink} from "react-router-dom"
import FormValidate from "form-validate.js"
import moment from "moment-timezone"

// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {backgrounds, extended} from "@uprise/colors"
// Components
import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
import {ExtraSmall, Note} from "@uprise/text"
import {Button} from "@uprise/button"
import {Alert} from "@uprise/alert"
// Validation
import constraints from "validation/signUp"
// Inputs
import {TextInput, Select} from "@uprise/form"
// Api
import {post} from "helpers/api"
// Utils
import {handleError} from "./utils"

import DropDown from "../DropDown"

import Countrycode from "../../../utils/countrycode"
import Styled from "styled-components"

const PhoneInputWrapper = Styled.div`
	display : flex;
	
`

const TextInputWrapper = Styled(TextInput)`
 width: 100%;
`

const DropDownWrapper = Styled(DropDown)`
margin-right: 10px;	
`

const PasswordInputWraper = Styled(TextInput)`
margin-bottom : 5px;
`

const PasswordInputInstructionWrapper = Styled.div`
	margin-bottom : 15px;
`

const PasswordInputInstructionSpan = Styled.span`
color : ${extended.charcoal.two};
font-size: 12px;
`

const SignUp = ({children}) => {
	let history = useHistory()
	const {code = ""} = useParams()

	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [countryCode, setCountryCode] = useState(Countrycode[189])
	const [phone, setPhone] = useState("")
	const [password, setPassword] = useState("")
	const [accessCode, setAccessCode] = useState(code)
	const [error, setError] = useState({type: "", value: ""})
	const [isLoading, setIsLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)
	const [countryName, setCountryName] = useState(Countrycode[189].name)

	useEffect(() => {
		const validator = new FormValidate(constraints)
		setValidator(validator)
	}, [])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			if (submitted) {
				setValidation(controls)
			}
		})
	}

	const _handleSubmit = () => {
		setSubmitted(true)
		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				// a good place to update the validation being displayed.
			})
			return
		} else {
			_handleSignUp()
		}
	}

	const _handleSignUp = () => {
		setIsLoading(true)

		post("signup", {
			password: password,
			email: email.toLowerCase().trim(),
			name: name,
			countryCode: countryCode.label,
			countryName,
			phone: phone.trim(),
			employerCode: accessCode,
			timezone: moment.tz.guess() || "Australia/Sydney",
			agreeUpriseEmails: true,
			agreeTerms: true
		})
			.then(response => {
				if (response.error) {
					setError(handleError(response))
				} else {
					if (
						response.user_state.course !== "Uprise-3.1" &&
						response.user_state.course !== "Rtw-2.0" &&
						response.user_state.course !== "Students-2.0"
					) {
						alert("sorry incorrect course please contact tech support")
						return
					} else {
						history.push("onboarding")
					}
				}
			})
			.catch(error => {
				console.error(error)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	return (
		<Fragment>
			<H3 className='m-b-10'>New to Uprise? Create an Account!</H3>
			<Card className='m-b-4' backgroundColor={backgrounds.white} shadow={true} padding={spacing.s5} width='100%'>
				{error?.text?.length > 0 && (
					<Alert className='m-b-5' type={error.type}>
						{error.text}
					</Alert>
				)}

				<form onChange={event => _validateForm(event)}>
					<TextInput
						onChange={e => setName(e.target.value)}
						validation={validation}
						value={name}
						validateControl='name'
						id='name'
						name='name'
						label='Name'
						isFirst
						isRequired
					/>
					<TextInput
						onChange={e => setEmail(e.target.value)}
						validation={validation}
						value={email}
						id='email'
						name='email'
						label='Email Address'
						isRequired
					/>
					<PasswordInputWraper
						onChange={e => setPassword(e.target.value)}
						validation={validation}
						value={password}
						type='password'
						id='password'
						name='password'
						label='Password'
						isRequired
					/>
					<PasswordInputInstructionWrapper>
						<PasswordInputInstructionSpan>
							*Password must contain lowercase, uppercase and one special character and one numerical
							character
						</PasswordInputInstructionSpan>
					</PasswordInputInstructionWrapper>
					<PhoneInputWrapper>
						<DropDownWrapper
							title={"Country code"}
							items={Countrycode}
							label='Select Code'
							onChange={value => {
								setCountryCode(value)
								setCountryName(value.name)
							}}
							initialSelectedItem={countryCode}
							menuStyle={{position: "absolute", zIndex: 2}}
							customListItemRender={item => (
								<div style={{display: "flex", justifyContent: "space-between"}}>
									<p>{item.name}</p>
									<p>{item.label}</p>
								</div>
							)}
						/>
						<TextInputWrapper
							onChange={e => setPhone(e.target.value)}
							validation={validation}
							value={phone}
							id='phone'
							type='text'
							name='phone'
							label='Phone number'
							isRequired
						/>
					</PhoneInputWrapper>
					<TextInput
						onChange={e => setAccessCode(e.target.value)}
						validation={validation}
						value={accessCode}
						id='accessCode'
						type='text'
						name='accessCode'
						label='Access code'
						isRequired
						isLast={true}
					/>
				</form>
			</Card>
			<ExtraSmall className='m-b-4'>
				By continuing, you agree to the{" "}
				<a href={`https://uprise.co/terms`} target='__blank' rel='noopener noreferrer'>
					Terms
				</a>{" "}
				and{" "}
				<a href={`https://uprise.co/privacy`} target='__blank' rel='noopener noreferrer'>
					Privacy Policy
				</a>
				.
			</ExtraSmall>
			<Button
				data-testid='sign-up-button'
				variant='primary'
				className='m-b-4'
				title='Sign up'
				size='large'
				fullWidth={true}
				isLoading={isLoading}
				disabled={false}
				onClick={() => _handleSubmit()}
			/>
			<Note>
				Already have an account? <NavLink to={`/sign-in`}>Sign in</NavLink>
			</Note>
		</Fragment>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
SignUp.propTypes = {}

SignUp.defaultProps = {}

export default SignUp
