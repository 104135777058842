import React, {useState, useEffect, Fragment} from "react"
import Styled, {keyframes} from "styled-components"
import {connect} from "react-redux"
import sanitizeHtml from "sanitize-html"

// utils
import {stringTemplateParser} from "chatBot/utils"
// Colors
import {primary, backgrounds} from "@uprise/colors"
// Spacing
import {spacing} from "@uprise/spacing"
// Reply Types
import InfoModal from "components/Shared/InfoModal"
import VideoReply from "components/Shared/Chat/ContentTypes/VideoReply"

// Animation

const height = keyframes`
  from {
    transform scaleY(0);
    transform-origin bottom left;
  }

  to {
    transform scaleY(1);
    transform-origin bottom left;
  }
`

const Wrapper = Styled.section`
	display: flex;
	flex-direction: column;
`

const BubbleLeftWrap = Styled.div`
	display: flex;
	flex-direction: row;
`

const UpriseLogo = Styled.img`
	height: 40px;
	width: 40px;
	visibility: ${props => (props.hidden ? "hidden" : "")};
	display: inline-block;
`

const BubbleLeft = Styled.div`
	width: ${props => (props.maxWidth ? "400px" : "auto")};
	max-width: 400px;
      line-height: 1.5;
      font-size: 16px;
      font-family: "Proxima Nova";
	border-radius: 10px;
	background-color: ${backgrounds.white};
	color: ${primary.charcoal};
	align-self: flex-start;
	padding: ${spacing.s5};
	margin-bottom: ${spacing.s3};
	animation: 0.8s ${height};
	box-shadow: 0 2px 4px 0 rgba(219, 221, 227, 0.5);

	span {
		color: ${primary.purple};
		cursor: pointer;
	}
	
	@media (max-width: 475px) {
		width: auto;
		max-width: 100%;
		padding: ${spacing.s4};
	}
`

const BubbleRight = Styled.div`
	width: auto;
	max-width: 400px;
      line-height: 1.5;
      font-size: 16px;
      font-family: "Proxima Nova";
	border-radius: 10px;
	background-color: ${primary.purple};
	color: ${backgrounds.white};
	align-self: flex-end;
	padding: ${spacing.s5};
	margin-bottom: ${spacing.s3};
	animation: 0.8s ${height};
	box-shadow: 0 2px 4px 0 rgba(219, 221, 227, 0.5);

	@media (max-width: 475px) {
		width: auto;
		max-width: 100%;
		padding: ${spacing.s4};
	}
`

const MessageList = ({data, skillId, name, userState}) => {
	const [showModal, setShowModal] = useState(false)

	const handleClose = () => setShowModal(false)
	const handleOpen = () => setShowModal(true)

	return (
		<Wrapper>
			{data.map((reply, index) => {
				const replyText = stringTemplateParser(reply.text, {name})

				if (reply.position === "left" && reply.contentType === "video") {
					return (
						<BubbleLeftWrap key={index}>
							<UpriseLogo
								hidden={data[index - 1]?.position === "left"}
								className='m-r-4'
								src={require(`assets/images/logos/uprise-transparent-64x64.png`)}
							/>

							<BubbleLeft maxWidth={true}>
								<VideoReply
									activity={userState.activity}
									taskId={reply.taskId}
									skillId={skillId}
									videoURI={reply.videoURI}
								/>
							</BubbleLeft>
						</BubbleLeftWrap>
					)
				} else if (reply.position === "left") {
					return (
						<BubbleLeftWrap key={index}>
							<UpriseLogo
								className='m-r-4'
								hidden={data[index - 1]?.position === "left"}
								src={require(`assets/images/logos/uprise-transparent-64x64.png`)}
							/>

							<BubbleLeft>{sanitizeHtml(replyText)}</BubbleLeft>
						</BubbleLeftWrap>
					)
				} else {
					return <BubbleRight>{sanitizeHtml(replyText)}</BubbleRight>
				}
			})}
		</Wrapper>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, null)(MessageList)
