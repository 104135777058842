import React from "react"
import {P, Medium} from "@uprise/text"
import {ProgressiveImage} from "@uprise/image"

const BookingYourCoach = ({coach}) => {
	return (
		<div>
			<div className='row'>
				<div className='col-sm-3 col-md-2'>
					<ProgressiveImage
						className='m-r-6 m-b-4'
						width='90px'
						height='90px'
						borderRadius='10px'
						src={
							coach?.photos?.avatarURL.length > 0
								? coach?.photos?.imageURL
								: require("assets/images/coach/default-coach.png")
						}
						overlaySrc={
							coach?.photos?.avatarURI.length > 0
								? coach?.photos?.avatarURI
								: require("assets/images/coach/default-coach.png")
						}
					/>
				</div>
				<div className='col-md-9 col-sm-9'>
					<div className='intro'>
						<Medium>{coach.intro.english}</Medium>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BookingYourCoach
