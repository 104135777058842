import React, {Fragment} from "react"
import Styled from "styled-components"
// Components
import {Card} from "@uprise/card"
import {Alert} from "@uprise/alert"
import {P, Medium} from "@uprise/text"
import {H2, H5} from "@uprise/headings"
import {Button} from "@uprise/button"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds} from "@uprise/colors"

const Wrapper = Styled.div`
	max-width: 900px;
	margin: 0 auto;
`

export default function PostProgramBooking({handleNext}) {
	const isMobile = window.innerWidth < 480

	return (
		<Wrapper>
			<Alert type='success' className='alert alert-primary mb-5'>
				Thank you for taking the time to complete the Post-program questionnaire. Your answers will always
				remain completely confidential.
			</Alert>
			<Card
				border={`1px solid ${extended.purple.five}`}
				shadow={false}
				backgroundColor={backgrounds.white}
				padding={spacing.s10}
				width='100%'>
				<div className='row'>
					<div className='col-12'>
						<div className={`${isMobile ? "pt-4 pb-4" : "p-1"}`}>
							<div className=''>
								<H5>What do I do now?</H5>

								<ul className='mt-3'>
									<li>
										<Medium>
											You can still access the Workable modules for the next 12 months using your
											current log-in details
										</Medium>
									</li>
									<li>
										<Medium>
											Your private data will remain confidential and you will be sent the research
											paper outcomes when ready.
										</Medium>
									</li>
								</ul>
							</div>
							<Button
								className='mt-5 m-auto px-4'
								title='Continue progress'
								fullWidth={false}
								onClick={handleNext}
							/>
						</div>
					</div>
				</div>
			</Card>
		</Wrapper>
	)
}
