import React, {useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import Styled from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import {connect} from "react-redux"

// Colors
import {extended, backgrounds} from "@uprise/colors"
// Components
import {Layout} from "components/Shared/Layout"
import UpNext from "components/Shared/UpNext"
import YourHealth from "components/Shared/YourHealth"
import CoachList from "components/Shared/Coach/CoachList"
// Actions
import {getCoachByEmail} from "actions/coachActions"
// UI
import {ContainerFluid, Row} from "@uprise/grid"
import {LeftPanel, RightPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"

const Wrap = Styled.section`	background-color: ${extended.purple.six}; 
        width: 100%;
`

const SelectCoach = ({userState, getCoachByEmail, coachEmail}) => {
	let history = useHistory()
	let location = useLocation()

	useEffect(() => {
		if (coachEmail) {
			getCoachByEmail(coachEmail)
		}
	}, [coachEmail])

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={3} />
			</LeftPanel>
			<Wrap>
				<Header
					title='Select a coach'
					textAlign='left'
					width='100%'
					back={location?.state?.from.length ? true : false}
					handleBack={() => history.push(location?.state?.from)}
				/>
				<Main>
					<CoachList className='m-t-4 m-b-10' />
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-none d-xl-block' width='310px'>
				<YourHealth surveyResults={userState.surveyResults} />
			</RightPanel>
		</Layout>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		coachEmail: state?.userState?.coach,
		coach: state?.coach
	}
}

const mapDispatchToProps = dispatch => ({
	getCoachByEmail: email => dispatch(getCoachByEmail(email))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectCoach)
