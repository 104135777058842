import React from "react"
import {useHistory, useLocation} from "react-router-dom"
import Styled from "styled-components"
import {connect} from "react-redux"
import moment from "moment-timezone"
// Utils
import {getThumbnailId} from "helpers/utils"
// Colors
import {backgrounds, extended} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Thumbnails
import thumbnails from "assets/images/thumbnails"
// Components
import {Card} from "@uprise/card"
import {ProgressBar} from "@uprise/charts"
import {P, ExtraSmall} from "@uprise/text"
import {H6} from "@uprise/headings"
import {RetinaProgressiveImage} from "@uprise/image"

const CardWrap = Styled.div`
      transition: .3s ease;
      &:hover {
            transform: translateY(-10px) scale(1.02);
	}
	
	&:last-child {
		margin-right: 0;
	}
`

const ImageWrapper = Styled.div`
      height: 108px;
	width: 200px;
      min-width: 190px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
      margin-bottom: 10px;
`
const CardContent = Styled.div`
      display: flex;
      flex-direction: column;

      &:hover {
            cursor: pointer;
      }
 `

const Content = Styled.div`
	padding: ${spacing.s3} ${spacing.s5} ${spacing.s5} ${spacing.s5};
      height: 135px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
`

const TextWrap = Styled.div`

`

const SkillCard = ({
	className,
	skillId,
	meta,
	showProgressBar = true,
	skill,
	index,
	trial,
	courseType,
	set,
	setSkill,
	userState
}) => {
	let history = useHistory()
	let location = useLocation()

	// alcoholAndAddictionsGettingPastAddictedSelf
	let footer = `${moment(meta[skillId].totalMinutes, "HH:mm:ss").format("m")} mins | ${
		meta[skillId].totalTasks
	} Exercises`

	const thumbnailId = getThumbnailId(skillId)
	const hideShortDesc = meta[skillId].hideShortDesc

	const handleClick = () => {
		history.push(`/skill/${skillId}`, {from: location.pathname})
		// analytics.track("skill.click", {skillId: skillId})
	}
	return (
		<CardWrap className={className}>
			<Card
				data-testid={`skill-${skillId}-button`}
				onClick={() => handleClick()}
				backgroundColor={backgrounds.white}
				shadow={true}
				width='200px'
				height='250px'>
				<CardContent>
					<ImageWrapper>
						<RetinaProgressiveImage
							srcSets={{
								small: thumbnails[`${thumbnailId}`],
								medium: thumbnails[`${thumbnailId}@2x`],
								large: thumbnails[`${thumbnailId}@3x`]
							}}
							overlaySrc={thumbnails[`${thumbnailId}-placeholder`]}
						/>
					</ImageWrapper>
					{Boolean(showProgressBar) && (
						<ProgressBar
							percentage={Math.round((100 * meta[skillId].completedTasks) / meta[skillId].totalTasks)}
							height='4px'
							radius={0}
						/>
					)}
					<Content>
						<TextWrap>
							<H6 className='m-t-0 m-b-0'>{meta[skillId].title}</H6>
							{!hideShortDesc && (
								<P color={extended.charcoal.one} fontSize='14px'>
									{meta[skillId].shortDesc}
								</P>
							)}
						</TextWrap>
						<ExtraSmall>{footer}</ExtraSmall>
					</Content>
				</CardContent>
			</Card>
		</CardWrap>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, null)(SkillCard)
