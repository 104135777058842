import {STORE_BOOKINGS, LOADING_BOOKINGS} from "./types"
import {get} from "helpers/api"

export const fetchBookings = () => {
	return dispatch => {
		dispatch(loadingBookings(true))

		get("user/bookings")
			.then(resp => {
				dispatch(storeBookings(resp.data))
				dispatch(loadingBookings(false))
			})
			.catch(err => {
				console.log(err)
				dispatch(loadingBookings(false))
			})
	}
}

export function loadingBookings(status) {
	return {
		type: LOADING_BOOKINGS,
		payload: {loading: status}
	}
}

export function storeBookings(bookings) {
	return {
		type: STORE_BOOKINGS,
		bookings: {data: bookings}
	}
}
