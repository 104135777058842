import React, {useState, useEffect} from "react"
import {string, object} from "prop-types"
import moment from "moment"
// Components
import GetACoach from "components/Shared/GetACoach"
import UpComingSession from "components/Shared/UpComingSession"
import BookCallShort from "components/Shared/Coach/BookCallShort"

const BookingCardDashboard = ({bookings, coachEmail}) => {
	const hasCoach = coachEmail && coachEmail.length > 0
	const [nextBooking, setNextBooking] = useState()

	useEffect(() => {
		// Get next booking object
		const next = bookings?.data?.find(booking => {
			return !booking.cancelled && moment(booking.start).isAfter(moment())
		})

		setNextBooking(next)
	}, [bookings])

	if (nextBooking) {
		return <UpComingSession className='m-b-10' booking={nextBooking} />
	} else if (!hasCoach) {
		return <GetACoach className='m-b-10' />
	} else if (!nextBooking && hasCoach) {
		return <BookCallShort className='m-b-10' coachEmail={coachEmail} />
	}
}

BookingCardDashboard.propTypes = {
	bookings: object.isRequired,
	coachEmail: string
}

export default BookingCardDashboard
