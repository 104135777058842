export const retrainingThinkingStress = [
	{
		_id: 0,
		text: "Welcome to retraining thinking for stress",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 1,
		text: "This exercise is easier if you’ve already watched the video about the skill.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 2,
			_next: 4,
			optionType: "radio",
			replyType: "survey",
			questions: [0, 1, 2],
			recurring: true,
			surveyId: "retraining-thinking-stress-v1",
			taskId: "exercise-retraining-thinking-stress",
			values: [
				{
					label: "Show me the video that explains Retraining Thinking",
					videoURI:
						"https://player.vimeo.com/external/246222687.sd.mp4?s=d04686d68e4397ec60c69871d96324b18758dfa5&profile_id=165",
					vimeoId: "246222687",
					value: "video",
					taskId: "video-retraining",
					replyType: "dialogue",
					quickReplies: {
						_id: 3,
						_next: 4,
						optionType: "radio",
						replyType: "survey",
						questions: [0, 1, 2],
						recurring: true,
						surveyId: "retraining-thinking-stress-v1",
						taskId: "exercise-retraining-thinking-stress",
						values: [
							{
								label: "Thanks, i've watched the video",
								value: "start",
								questionIndex: 0
							}
						]
					}
				},
				{
					label: "I’ve already watched it. Let’s get started.",
					value: "start",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		_next: 5,
		text: "Similar to that situation",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		text: "is it possible that your current situation could work out better than you expect?",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 6,
			_next: 9,
			optionType: "radio",
			values: [
				{
					label: "Yes, this could work out ok",
					value: "next",
					replyType: "dialogue"
				},
				{
					label: "No, I still feel convinced something really bad is going to happen",
					value: "more",
					replyType: "dialogue",
					response: [
						"Sorry to hear that. Sometimes when we are in a highly stressed state, one option is to use controlled breathing. Would you like to watch the video that shows you how to use this skill?"
					],
					quickReplies: {
						_id: 7,
						_next: 9,
						optionType: "radio",
						values: [
							{
								label: "Yes",
								videoURI:
									"https://player.vimeo.com/external/246222687.sd.mp4?s=d04686d68e4397ec60c69871d96324b18758dfa5&profile_id=165",
								vimeoId: "246222687",
								value: "video",
								taskId: "video-stress",
								replyType: "dialogue",
								quickReplies: {
									_id: 8,
									_next: 9,
									optionType: "radio",
									replyType: "dialogue",
									values: [
										{
											label: "Thanks, i've watched the video",
											value: "next",
											replyType: "dialogue"
										}
									]
								}
							},
							{
								label: "No",
								value: "next",
								replyType: "dialogue"
							}
						]
					}
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 9,
		_next: 10,
		text: "Thanks for doing the exercise with me. 🏋️",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		_next: 11,
		text: "The goal of the exercise was to help identify the thought that was causing your stress.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 11,
		_next: 12,
		text: "These are predictions about the future of the worst possible outcome.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 12,
		_next: 13,
		text: "When we examine how likely the worse scenario is, it can help us to feel less stressed.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 13,
		_end: true,
		text: "👋 See you next time! I'll take you back to the dashboard",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
