export const retrainingThinkingSleepThoughts = [
	{
		_id: 0,
		text: "I can help you to use the retraining thinking skill for common thoughts that get in the way of sleep.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 2,
			_next: 3,
			optionType: "radio",
			replyType: "survey",
			questions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
			recurring: true,
			surveyId: "retraining-thinking-sleep-thoughts-v1",
			taskId: "exercise-retraining-thinking-sleep-thoughts",
			values: [
				{
					label: "Great",
					value: "start",
					replyType: "dialogue",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 1,
		text: "Thank you for working through this together. I hope you sleep better tonight!",
		replyType: "dialogue",
		createdAt: new Date(),
		quickReplies: {
			_id: 2,
			_next: 3,
			values: [
				{
					label: "Thanks",
					value: "start",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		_end: true,
		text: "👋 See you next time! I'll take you back to the dashboard",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
