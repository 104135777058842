// Checkup types
export const CHECKUP_ERROR = "CHECKUP_ERROR"
export const CHECKUP_SENT = "CHECKUP_SENT"
export const CHECKUP_RESP = "CHECKUP_RESP"
export const CHECKUP_LOADING = "CHECKUP_LOADING"
// Login types
export const VALIDATION_UPDATE = "VALIDATION_UPDATE"
export const LOGGED_IN = "LOGIN_LOGGED_IN"
export const LOGGED_OUT = "LOGIN_LOGGED_OUT"
export const FORGOT_DONE = "LOGIN_FORGOT_DONE"
export const FORGOT_CLEAR = "FORGOT_CLEAR"
export const PENDING_REQUEST = "PENDING_REQUEST"
// Task types
export const STORE_TASK = "STORE_TASK"
// Skill types
export const STORE_SKILL = "STORE_SKILL"
export const STORE_SUGGESTED = "STORE_SUGGESTED"
// User state types
export const STORE_USER_STATE = "STORE_USER_STATE"
// Codes types
export const FETCH_CODES = "FETCH_CODES"
// Coach types
export const STORE_COACH = "STORE_COACH"
export const LOADING_COACH = "LOADING_COACH"
// Survey types
export const STORE_ACTIVE_SCREENER = "STORE_ACTIVE_SCREENER"
export const SAVE_MULTI_OPTIONS = "SAVE_MULTI_OPTIONS"
// Code types
export const STORE_CODE = "STORE_CODE"
// Bookings
export const STORE_BOOKINGS = "STORE_BOOKINGS"
export const LOADING_BOOKINGS = "BOOKINGS"

// HR Dashboard
export const LOADING_WELLBEING_GRAPH_DATA = "LOADING_WELLBEING_GRAPH_DATA"
export const STORE_WELLBEING_GRAPH_DATA = "STORE_WELLBEING_GRAPH_DATA"

export const LOADING_POPULAR_SKILLS = "LOADING_POPULAR_SKILLS"
export const STORE_POPULAR_SKILLS = "STORE_POPULAR_SKILLS"

export const LOADING_POPULAR_COACHES = "LOADING_POPULAR_COACHES"
export const STORE_POPULAR_COACHES = "STORE_POPULAR_COACHES"

export const LOADING_RECENT_BLOG_POSTS = "LOADING_RECENT_BLOG_POSTS"
export const STORE_RECENT_BLOG_POSTS = "STORE_RECENT_BLOG_POSTS"

export const LOADING_WELLBEING_LEVEL = "LOADING_WELLBEING_LEVEL"
export const STORE_WELLBEING_LEVEL = "STORE_WELLBEING_LEVEL"

export const LOADING_WELLBEING_LEVEL_FOR_MONTH = "LOADING_WELLBEING_LEVEL_FOR_MONTH"
export const STORE_WELLBEING_LEVEL_FOR_MONTH = "STORE_WELLBEING_LEVEL_FOR_MONTH"

export const LOADING_WELLBEING_LEVEL_FOR_ENGAGED = "LOADING_WELLBEING_LEVEL_FOR_ENGAGED"
export const STORE_WELLBEING_LEVEL_FOR_ENGAGED = "STORE_WELLBEING_LEVEL_FOR_ENGAGED"

export const LOADING_STRESS_GRAPH_DATA = "LOADING_STRESS_GRAPH_DATA"
export const STORE_STRESS_GRAPH_DATA = "STORE_STRESS_GRAPH_DATA"

export const LOADING_STRESS_LEVEL = "LOADING_STRESS_LEVEL"
export const STORE_STRESS_LEVEL = "STORE_STRESS_LEVEL"

export const LOADING_STRESS_LEVEL_FOR_MONTH = "LOADING_STRESS_LEVEL_FOR_MONTH"
export const STORE_STRESS_LEVEL_FOR_MONTH = "STORE_STRESS_LEVEL_FOR_MONTH"

export const LOADING_STRESS_LEVEL_FOR_ENGAGED = "LOADING_STRESS_LEVEL_FOR_ENGAGED"
export const STORE_STRESS_LEVEL_FOR_ENGAGED = "STORE_STRESS_LEVEL_FOR_ENGAGED"

export const LOADING_DATA_UTILIZATION_STATS = "LOADING_DATA_UTILIZATION_STATS"
export const STORE_DATA_UTILIZATION_STATS = "STORE_DATA_UTILIZATION_STATS"

export const LOADING_DATA_UTILIZATION = "LOADING_DATA_UTILIZATION"
export const STORE_DATA_UTILIZATION = "STORE_DATA_UTILIZATION"
