export function getCourseTypeByHostName() {
	let courseTypeVal = "corporate"
	switch (window.location.hostname) {
		case "students.uprise.co":
			courseTypeVal = "students"
			break
		case "staging-students.uprise.co":
			courseTypeVal = "students"
			break
		case "testing-students.uprise.co":
			courseTypeVal = "students"
			break
		case "workable.uprise.co":
			courseTypeVal = "rtw"
			break
		case "testing-rtw.uprise.co":
			courseTypeVal = "rtw"
			break
		case "workable-us.uprise.co":
		case "testing-workable-us.uprise.co":
		case "staging-workable-us.uprise.co":
			courseTypeVal = "rtw"
			break
	}

	return courseTypeVal
}
