import {STORE_BOOKINGS, LOADING_BOOKINGS} from "actions/types"

export default function(state = {}, action) {
	switch (action.type) {
		case LOADING_BOOKINGS: {
			return {...state, ...action.payload}
		}
		case STORE_BOOKINGS: {
			return {...state, ...action.bookings}
		}
		default:
			return state
	}
}
