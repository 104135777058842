import React, {useState} from "react"
import {Button} from "@uprise/button"
import {Col, Row} from "@uprise/grid"
import {H3, H5, H6} from "@uprise/headings"
import {extended, primary, secondary} from "@uprise/colors"
import moment from "moment"
import {TitleWrapper} from "components/Shared/HrDashboard/OverallWellbeingLevelsAtUprise/index.style"
import DoughnutGraph from "components/Shared/Graphs/Doughnut"
import ToggleModal from "components/Shared/ToggleModal"
import DropDownBorderless from "components/Shared/DropDown"
import HelpMeInterpretModal from "components/Shared/HrDashboard/HelpMeInterpretModal"
import {ModalOpenButtonContainer, PurpleBox} from "../index.style"
import {Medium} from "@uprise/text"
import GroupedBarChart from "components/Shared/Graphs/GroupedBar"

function Risk() {
	const now = new moment()
	const last6Months = [...Array(6)].map((_, i) => {
		let tempMoment = now
			.clone()
			.startOf("month")
			.subtract(i + 1, "month")

		return {
			value: tempMoment.format("YYYY-MM-DDThh:mm:ss.SSS"),
			label: tempMoment.format("MMMM")
		}
	})
	const [month, setMonth] = useState(last6Months[0])

	return (
		<>
			{/* Current Risk Level Row */}
			<TitleWrapper>
				<H3 className='m-b-5'>Current Risk Levels</H3>
				<ToggleModal
					toggle={show => (
						<Button
							className='m-b-5 text-right'
							title={
								<ModalOpenButtonContainer>
									<img
										src={require(`assets/images/hrdashboard/information-symbol.svg`)}
										className='mr-2'
									/>
									Help me interpret this
								</ModalOpenButtonContainer>
							}
							fullWidth={false}
							size='medium'
							onClick={show}
							variant='tertiary'
							disabled={false}
							icon={true}
						/>
					)}
					content={hide => <HelpMeInterpretModal hide={hide} />}
				/>
			</TitleWrapper>
			{/* Current Risk Level Row - Chart */}
			<PurpleBox>
				<Row>
					<Col className='col-md-4 mb-4'>
						<H5 textAlign='center'>Employees on work at risk of Leaving</H5>
						<DoughnutGraph
							data={{
								labels: ["Hight risk 25%"],
								datasets: [
									{
										data: [25, 37.5, 37.5],
										backgroundColor: [
											secondary.electricLightOrange,
											"rgba(0,0,0,0.05)",
											"rgba(0,0,0,0.05)"
										],
										borderWidth: 0
									}
								]
							}}
						/>
					</Col>
					<Col className='col-md-4 mb-4'>
						<H5 textAlign='center'>Employees at risk of a mental health issue</H5>
						<DoughnutGraph
							data={{
								labels: ["Hight risk 25%"],
								datasets: [
									{
										data: [25, 37.5, 37.5],
										backgroundColor: [
											secondary.electricLightOrange,
											"rgba(0,0,0,0.05)",
											"rgba(0,0,0,0.05)"
										],
										borderWidth: 0
									}
								]
							}}
						/>
					</Col>
					<Col className='col-md-4 mb-4'>
						<H5 textAlign='center'>Employees at work having high stress</H5>
						<DoughnutGraph
							data={{
								labels: ["Hight risk 25%"],
								datasets: [
									{
										data: [25, 37.5, 37.5],
										backgroundColor: [
											secondary.electricLightOrange,
											"rgba(0,0,0,0.05)",
											"rgba(0,0,0,0.05)"
										],
										borderWidth: 0
									}
								]
							}}
						/>
					</Col>
				</Row>
			</PurpleBox>

			{/* Psychosocial Hazards - TODO */}

			{/* Risk level changes for engaged Uprise Users */}
			<TitleWrapper>
				<H3 className='m-b-5'>Risk level changes for engaged Uprise Users</H3>
				<ToggleModal
					toggle={show => (
						<Button
							className='m-b-5 text-right'
							title={
								<ModalOpenButtonContainer>
									<img
										src={require(`assets/images/hrdashboard/information-symbol.svg`)}
										className='mr-2'
									/>
									Help me interpret this
								</ModalOpenButtonContainer>
							}
							fullWidth={false}
							size='medium'
							onClick={show}
							variant='tertiary'
							disabled={false}
							icon={true}
						/>
					)}
					content={hide => <HelpMeInterpretModal hide={hide} />}
				/>
			</TitleWrapper>

			{/* Risk level changes for engaged Uprise Users - CHARTS */}
			<PurpleBox>
				<Row>
					<Col className='col-md-6 mb-4'>
						<H6 className='m-b-0' textAlign='center'>
							Pre-Uprise
						</H6>
						<H6 className='m-t-0' textAlign='center'>
							Risk Levels
						</H6>
						<DoughnutGraph
							data={{
								labels: ["Hight risk 25%"],
								datasets: [
									{
										data: [25, 37.5, 37.5],
										backgroundColor: [
											secondary.electricLightOrange,
											"rgba(0,0,0,0.05)",
											"rgba(0,0,0,0.05)"
										],
										borderWidth: 0
									}
								]
							}}
						/>
						<Medium textAlign='center' color={extended.charcoal.three}>
							Scores during onboarding
						</Medium>
					</Col>
					<Col className='col-md-6 mb-4'>
						<H6 className='m-b-0' textAlign='center'>
							Pre-Uprise
						</H6>
						<H6 className='m-t-0' textAlign='center'>
							Risk Levels
						</H6>
						<DoughnutGraph
							data={{
								labels: ["Hight risk 25%"],
								datasets: [
									{
										data: [25, 37.5, 37.5],
										backgroundColor: [
											secondary.electricLightOrange,
											"rgba(0,0,0,0.05)",
											"rgba(0,0,0,0.05)"
										],
										borderWidth: 0
									}
								]
							}}
						/>
						<Medium textAlign='center' color={extended.charcoal.three}>
							Scores after 2 coaching calls or completing 2 modules
						</Medium>
					</Col>
				</Row>
				<H6 className='m-b-0' textAlign='center'>
					5 eligible users
				</H6>
				<Medium textAlign='center' color={extended.charcoal.two}>
					All Uprise users since launch
				</Medium>
			</PurpleBox>

			{/* Employees at risk of leaving - Uprise vs Benchmark Avg */}
			<TitleWrapper>
				<H3 className='m-b-5'>Employees at risk of leaving - Uprise vs Benchmark Avg</H3>
				<ToggleModal
					toggle={show => (
						<Button
							className='m-b-5 text-right'
							title={
								<ModalOpenButtonContainer>
									<img
										src={require(`assets/images/hrdashboard/information-symbol.svg`)}
										className='mr-2'
									/>
									Help me interpret this
								</ModalOpenButtonContainer>
							}
							fullWidth={false}
							size='medium'
							onClick={show}
							variant='tertiary'
							disabled={false}
							icon={true}
						/>
					)}
					content={hide => <HelpMeInterpretModal hide={hide} />}
				/>
			</TitleWrapper>

			{/* Employees at risk of leaving - Uprise vs Benchmark Avg CHART */}

			<DropDownBorderless
				fullWidth
				onChange={e => setMonth(e)}
				items={last6Months}
				initialSelectedItem={month}
				menuStyle={{position: "absolute", zIndex: 2}}
				containerStyle={{background: "white", width: "400px", marginBottom: 8}}
				customListItemRender={item => (
					<div style={{display: "flex", justifyContent: "space-between"}}>
						<p>{item.label}</p>
					</div>
				)}
			/>
			<PurpleBox>
				<GroupedBarChart
					data={{
						labels: ["Jan-20", "Feb-20", "Mar-20", "Apr-20", "May-20", "Jun-20"],
						datasets: [
							{
								label: "Employees at risk of leaving - Uprise",
								data: [40, 30, 20, 50, 60, 60],
								fill: false,
								backgroundColor: primary.purple,
								borderColor: primary.purple
							},
							{
								label: "Employees at risk of leaving - Benchmark Avg",
								data: [20, 10, 30, 30, 10, 10],
								fill: false,
								backgroundColor: secondary.electricLightOrange,
								borderColor: secondary.electricLightOrange
							}
						]
					}}
				/>
			</PurpleBox>
		</>
	)
}

export default Risk
