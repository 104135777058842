import React, {Fragment, useEffect, useState} from "react"
import Styled from "styled-components"
// import {bool, func, string} from "prop-types"
import {useHistory} from "react-router-dom"
import {NavLink} from "react-router-dom"
import FormValidate from "form-validate.js"
import {connect} from "react-redux"
import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
import {P, Note} from "@uprise/text"
import {Button} from "@uprise/button"
import {Alert} from "@uprise/alert"

// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {backgrounds, extended} from "@uprise/colors"
// Components
import {SocialButton} from "./SocialButton"
// Validation
import constraints from "validation/signIn"
// Inputs
import {TextInput} from "@uprise/form"
// Actions
import {loginUser, storeuserState} from "actions/loginActions"
// utils
import {checkActivityComplete} from "helpers/utils"
// Api
import {BASE_URL} from "helpers/api"

const ButtonWrap = Styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`

const Login = ({children, errors}) => {
	let history = useHistory()

	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [error, setError] = useState({type: "", value: ""})
	const [isLoadingSignIn, setIsLoadingSignIn] = useState(false)
	const [isLoadingAzure, setIsLoadingAzure] = useState(false)
	const [submitted, setSubmitted] = useState(false)

	let courseType = "corporate"
	switch (window.location.hostname) {
		case "students.uprise.co":
			courseType = "students"
			break
		case "staging-students.uprise.co":
			courseType = "students"
			break
		case "testing-students.uprise.co":
			courseType = "students"
			break
		case "workable.uprise.co":
			courseType = "rtw"
			break
		case "testing-rtw.uprise.co":
			courseType = "rtw"
			break
		case "workable-us.uprise.co":
		case "testing-workable-us.uprise.co":
		case "staging-workable-us.uprise.co":
			courseType = "rtw"
			break
	}

	useEffect(() => {
		const validator = new FormValidate(constraints)
		setValidator(validator)
	}, [])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			if (submitted) {
				setValidation(controls)
			}
		})
	}

	const _handleSubmit = () => {
		setSubmitted(true)
		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				// a good place to update the validation being displayed.
			})
			return
		} else {
			_handleLogin()
		}
	}

	const _handleLogin = () => {
		setIsLoadingSignIn(true)

		loginUser(
			// Format email before sending to server
			email.toLowerCase().trim(),
			password
		)
			.then(response => {
				setIsLoadingSignIn(false)

				if (response.error) {
					_handleError(response)
				} else {
					if (
						!response.user_state?.employerCode ||
						response.user_state.phone.length === 0 ||
						response.user_state.name.length === 0
					) {
						history.push("pre-onboarding-sso")
						return
					}

					storeuserState(response.user_state)

					// Activity validation
					if (
						/* Redirect the user to onboarding */
						// Corporate
						response.user_state.activity["survey-onboarding"]?.completed ||
						response.user_state.activity["onboarding-page"]?.completed ||
						response.user_state.activity["onboarding-corporate"]?.completed ||
						// RTW
						response.user_state.activity["onboarding-rtw"]?.completed ||
						// Students
						response.user_state.activity["onboarding-students"]?.completed
					) {
						history.push(history?.location?.state?.from || "dashboard")
					} else {
						history.push("onboarding")
					}
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	const _handleError = resp => {
		if (resp.error) {
			let errors = {}

			switch (resp.error) {
				case "BadEmail":
					errors.email = {
						type: "error",
						text: "No account exists for that email address, please try another address"
					}

					break
				case "BadPassword":
					errors.password = {
						type: "error",
						text: "Incorrect username and/or password, please try again"
					}

					break
				case "Disabled":
					errors.disabled = {
						type: "error",
						text:
							"Your account is no longer active. Please contact Uprise Support at team@uprise.co for support"
					}
					break
				case "EXPIRED_ACCESS_CODE":
					errors.disabled = {
						type: "error",
						text: "Access to this account has been restricted"
					}
					break
				case "ValidationFail":
					switch (resp.errors[0].property) {
						case "instance.name":
							errors.name = {type: "error", text: "Please enter valid name"}
							break
						case "instance.password":
							errors.password = {type: "error", text: "Please enter a 10 character length password"}
							break
					}
					break
				case "PASSWORD_SIGN_ON_NOT_SUPPORTED":
					errors.signInType = {
						type: "error",
						text: "Password sign in not supported, please sign in with Azure"
					}

					break
				default:
					errors.name = {type: "error", text: resp.error}
			}

			setError(Object.values(errors)[0])
			return errors
		}
	}

	return (
		<Fragment>
			<H3 className='m-b-10'>Welcome, please sign in</H3>
			<Card className='m-b-4' backgroundColor={backgrounds.white} shadow={true} padding={spacing.s5} width='100%'>
				{error?.text?.length > 0 && (
					<Alert className='m-b-5' type={error.type}>
						{error.text}
					</Alert>
				)}

				<form onChange={event => _validateForm(event)}>
					<TextInput
						onChange={e => setEmail(e.target.value)}
						validation={validation}
						value={email}
						validateControl='email'
						id='email'
						name='email'
						label='Email'
						isFirst
						isRequired
					/>
					<TextInput
						onChange={e => setPassword(e.target.value)}
						validation={validation}
						value={password}
						id='password'
						type='password'
						name='password'
						label='Password'
						isLast
						isRequired
					/>
				</form>
			</Card>
			<ButtonWrap>
				<Button
					variant='primary'
					className='m-b-2'
					data-testid='sign-in-button'
					title='Sign in'
					size='large'
					fullWidth={true}
					isLoading={isLoadingSignIn}
					disabled={false}
					onClick={() => _handleSubmit()}
				/>
			</ButtonWrap>

			<Note>
				Don’t have an account? <NavLink to={`/sign-up`}>Sign up</NavLink>. Forgot your password?{" "}
				<NavLink to={`/reset`}>Reset</NavLink>.
			</Note>
		</Fragment>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Login.propTypes = {}

Login.defaultProps = {}

const mapDispatchToProps = dispatch => ({
	storeuserState: state => dispatch(storeuserState(state))
})

export default connect(null, mapDispatchToProps)(Login)
