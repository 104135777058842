import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import Styled from "styled-components"
import {useLocation} from "react-router-dom"
import {connect} from "react-redux"
import moment from "moment-timezone"

// Colors
import {extended, backgrounds} from "@uprise/colors"
// Components
import {Layout} from "components/Shared/Layout"
import YourHealth from "components/Shared/YourHealth"
import {YourCoach} from "components/Shared/Coach/YourCoach"
import BookCall from "components/Shared/Coach/BookCall"
import {AccessSupport} from "components/Shared/AccessSupport"
import {Faqs} from "components/Shared/Coach/Faqs"
// Actions
import {getCoachByEmail} from "actions/coachActions"
import {updateUserState} from "actions/userStateActions"
import {fetchCode} from "actions/codesActions"
import {fetchBookings} from "actions/bookingsActions"
// UI
import {ContainerFluid, Row, Col} from "@uprise/grid"
import {Medium} from "@uprise/text"

import {LeftPanel, RightPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"
import {calculateCaps} from "helpers/utils"

const Wrap = Styled.section`	background-color: ${extended.purple.six};
        width: 100%;
`

const getDuration = ({location, courseType}) => {
	let duration = 30

	if (location?.state?.duration) {
		duration = location.state.duration
	}
	// Override based on course type
	if (courseType === "rtw") {
		duration = 60
	}
	return duration
}

const MakeBooking = ({
	userState,
	coach,
	assignCoach,
	getCoachByEmail,
	coachEmail,
	code,
	courseType,
	match,
	fetchCode,
	fetchBookings,
	bookings
}) => {
	let location = useLocation()

	const [reachedCapLimit, setReachedCapLimit] = useState(false)
	const [callsRemaining, setCallsRemaining] = useState(0)
	const [codeLoading, setCodeLoading] = useState(true)
	const [duration, setDuration] = useState(getDuration({location, courseType}))
	const {skills, meta} = userState
	const [capLimit, setCapLimit] = useState(0)

	const reschedule = location?.state?.reschedule

	useEffect(() => {
		getCoachByEmail(coachEmail)
	}, [coachEmail])

	useEffect(() => {
		fetchBookings()
		// check if user is reschudling a call

		// get employer code data
		fetchCode(userState.employerCode)
	}, [])

	useEffect(() => {
		if (Object.values(code).length) {
			setCodeLoading(false)
		}
	}, [code])

	useEffect(() => {
		const {companyCallCap, finalCaps} = calculateCaps(code)
		let totalValidBookings =
			bookings?.data
				?.filter(booking => {
					return booking?.id
				})
				.filter(booking => moment(booking.createdAt).diff(moment(finalCaps?.startingDate), "days") >= 0)
				.filter(booking => {
					if (["within24", "within_24", "userNoShow", "user_no_show"].includes(booking.cancelReason))
						return true
					else return !booking.cancelled
				}) || []

		setCapLimit(companyCallCap)

		// Check if calls are capps + check if users bookings exceeds codes call cap limit

		if (!code?.caps?.capCalls) setReachedCapLimit(false)
		else if (totalValidBookings.length >= parseInt(companyCallCap)) {
			setReachedCapLimit(true)
		} else setReachedCapLimit(false)

		if (code?.caps?.capCalls) {
			setCallsRemaining(parseInt(companyCallCap) - totalValidBookings.length)
		}
	}, [code, bookings])

	const handleBookTherapy = () => {
		setDuration(60)
		window.scrollTo({left: 0, top: 100, behavior: "smooth"})
	}

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={3} />
			</LeftPanel>
			<Wrap>
				<Header
					title='Coaching'
					textAlign='left'
					width='100%'
					back={location?.state?.from?.length ? true : false}
				/>

				<Main>
					{codeLoading ? (
						<Medium>Loading</Medium>
					) : (
						<Fragment>
							<BookCall
								className='m-t-4 m-b-10'
								duration={duration}
								reschedule={reschedule}
								callsRemaining={callsRemaining}
								callLimitReached={reachedCapLimit}
								callCapLimit={code?.caps?.capCalls && capLimit}
								callApproved={reachedCapLimit && userState.disableCallCap}
							/>
							<YourCoach className='m-b-10' coach={coach} change={true} />
							{courseType !== "rtw" && (
								<AccessSupport className='m-b-10' handleBookTherapy={handleBookTherapy} />
							)}
							<Faqs />
						</Fragment>
					)}
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-none d-xl-block' width='310px'>
				<YourHealth surveyResults={userState.surveyResults} />
			</RightPanel>
		</Layout>
	)
}

function mapStateToProps(state) {
	return {
		courseType: state?.userState?.courseType,
		userState: state.userState,
		coachEmail: state?.userState?.coach,
		coach: state?.coach,
		code: state?.code,
		bookings: state?.bookings
	}
}

const mapDispatchToProps = dispatch => ({
	getCoachByEmail: email => dispatch(getCoachByEmail(email)),
	updateUserState: state => dispatch(updateUserState(state)),
	fetchCode: code => dispatch(fetchCode(code)),
	fetchBookings: () => dispatch(fetchBookings())
})

export default connect(mapStateToProps, mapDispatchToProps)(MakeBooking)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
MakeBooking.propTypes = {}

MakeBooking.defaultProps = {}
