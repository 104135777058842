import React, {Fragment, useState, useEffect} from "react"
import styled from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import Styled from "styled-components"
import moment from "moment-timezone"
import {Modal} from "@uprise/modal"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Components
import {Button} from "@uprise/button"
import {Medium, Note, P, Small, Tiny} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
import {ProgressiveImage} from "@uprise/image"
// Icons
import Icons from "constants/Icons"

const CoachPhoto = Styled.img`
      height: 142px;
      min-width: 142px;
      border-radius: 10px;
`

const Date = Styled.span`

`

const NameBar = Styled.div`
	padding: 9px 0;
	width: 100%;
	height: 40px;
	border-radius: 5px;
	background-color: ${extended.lightOrange.five};
`

export const CoachModal = ({isOpen, handleClose, data, ...props}) => {
	let history = useHistory()
	const location = useLocation()

	return (
		<Modal
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='500px'
			textAlign='center'
			isOpen={isOpen}
			handleClose={handleClose}>
			<H3 className='m-b-6'>You’ve got support now!</H3>
			<CoachPhoto
				className='m-b-6'
				src={
					data?.photos?.avatarURL.length > 0
						? data?.photos?.imageURL
						: require("assets/images/coach/default-coach.png")
				}
			/>

			<NameBar className='m-b-6'>
				<H5 lineHeight='1.2' className='m-t-0 m-b-0'>
					{data?.name}
				</H5>
			</NameBar>

			<Medium className='m-b-6' textAlign='left'>
				Great work, you’ve selected {data?.name} as your coach. {data?.name.split(" ")[0]} will help you apply
				Uprise skills to your life.
			</Medium>

			<Button
				className='m-b-2'
				variant='primary'
				size='medium'
				title='Book a coaching call now'
				onClick={() =>
					history.push("make-booking", {from: "/select-coach", duration: location?.state?.duration || 30})
				}
			/>

			<Button variant='secondary' size='medium' title='Close' onClick={() => handleClose()} />
		</Modal>
	)
}
