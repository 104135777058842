import React from "react"
import propTypes from "prop-types"
import Styled from "styled-components"
import {TextInput} from "@uprise/form"
import {Note} from "@uprise/text"

const InputWrap = Styled.div`
 	flex-direction: row !important;
	display: flex !important;
	padding: 15px !important;
`

const TextInputExtended = Styled(TextInput)`
	margin-bottom: 0;
	min-width : 60px;
`

const NumberOptional = ({value, valid, handleChange}) => {
	const isMobile = window.innerWidth < 480
	return (
		<div className='form-row mt-2'>
			<div className='form-group row'>
				<div className='col-sm-6'>
					<InputWrap className='form-control'>
						<TextInputExtended
							type='number'
							name=''
							className={`p-2 text-input ${
								valid ? (valid.valid ? "is-valid" : "is-invalid") : ""
							} input-sm`}
							placeholder=''
							min='0'
							value={value}
							onChange={e => handleChange(e.target.value)}
						/>
						<div className={`m-r-10 col-sm-2 ${isMobile ? "" : "p-0"}`}>
							<Note className='block mt-1'>times</Note>
						</div>
					</InputWrap>
				</div>
			</div>
			{valid && valid.message && <span className='invalid-feedback'>{valid.message}</span>}
		</div>
	)
}

NumberOptional.propTypes = {
	value: propTypes.number,
	handleChange: propTypes.func.isRequired
}

NumberOptional.defaultProps = {
	value: 0
}

export default NumberOptional
