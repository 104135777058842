import moment from "moment-timezone"
import {put, post} from "helpers/api"
import {SAVE_MULTI_OPTIONS, STORE_ACTIVE_SCREENER} from "actions/types"

export const setOptions = options => {
	return dispatch => {
		dispatch(saveOptions(options))
	}
}

export const saveOptions = options => ({
	type: SAVE_MULTI_OPTIONS,
	options
})

export const setActiveScreener = screener => {
	return dispatch => {
		dispatch(storeActiveScreener(screener))
	}
}

const storeActiveScreener = screener => ({
	type: STORE_ACTIVE_SCREENER,
	screener: screener
})

export const saveSurvey = (recurring, answers, surveyId, surveyIndex, taskId) => {
	// increment unique identifier to surveyId if a recurring survey
	let id = 0

	if (surveyIndex === null) {
		id = 0
	} else if (parseInt(surveyIndex) === 0) {
		id = 1
	} else {
		id = parseInt(surveyIndex) + 1
	}

	return post("complete", {
		module: taskId,
		completed: true
	})
		.then(async () => {
			return post("surveyresults", {
				id: id,
				taskId: taskId,
				surveyId: surveyId,
				timestamp: moment().unix(),
				completed: true,
				recurring: recurring || false,
				answers: answers
			})
		})
		.catch(err => {
			console.log(err)
			return err
		})
}
