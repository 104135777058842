import React, {Fragment} from "react"
import {string} from "prop-types"
import Styled from "styled-components"
import {useLocation, useHistory} from "react-router-dom"
// Colors
import {backgrounds} from "@uprise/colors"
// UI
import {Layout} from "components/Shared/Layout"
import {Row, Col} from "@uprise/grid"
// Components
import Login from "components/Shared/User/Login"
import SignUp from "components/Shared/User/SignUp"
import Forgot from "components/Shared/User/Forgot"
import Reset from "components/Shared/User/Reset"
import Footer from "components/Shared/Home/Footer"
// Corporate
import RightContentCorporate from "components/Shared/Home/RightContentCorporate"
// RTW
import RightContentRTW from "components/Shared/Home/RightContentRtw"
// Students
import RightContentStudents from "components/Shared/Home/RightContentStudents"

const ContentLeft = Styled.section`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const Main = Styled.div`
`

const ContentRight = Styled.section`
	margin-top: 166px;
`

const Image = Styled.img`
	width: 106px;
	height: 30px;
	margin-bottom: 116px;
	cursor: pointer;
`

const Home = ({courseType}) => {
	let location = useLocation()
	let history = useHistory()

	const active = location.pathname

	return (
		<Fragment>
			<Layout backgroundColor={backgrounds.white} rowClassName='justify-content-center h-100'>
				<Col className='col-lg-6 col-md-12'>
					<Row className='justify-content-center h-100' backgroundColor={backgrounds.fadedPurple}>
						<Col className='col-lg-8 col-md-12'>
							<ContentLeft>
								<Main>
									<Image
										className='m-t-5'
										src={require("assets/images/logos/logo-black.svg")}
										onClick={() => history.push("/")}
									/>
									{(active === "/sign-in" || active === "/login") && <Login />}
									{(active.includes("/login/register/") || active.includes("/sign-up")) && <SignUp />}
									{active === "/reset" && <Forgot />}
									{active.includes("/user/forgot/") && <Reset />}
								</Main>
								<Footer className='m-b-2' />
							</ContentLeft>
						</Col>
					</Row>
				</Col>
				<Col className='col-12 col-lg-6 d-none d-lg-block'>
					<Row className='justify-content-center h-100' backgroundColor={backgrounds.white}>
						<Col className='col-lg-8'>
							<ContentRight>
								{courseType === "rtw" && <RightContentRTW />}
								{courseType === "corporate" && <RightContentCorporate />}
								{courseType === "students" && <RightContentStudents />}
							</ContentRight>
						</Col>
					</Row>
				</Col>
			</Layout>
		</Fragment>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Home.propTypes = {
	courseType: string.isRequired
}

Home.defaultProps = {courseType: "corporate"}

export default Home
