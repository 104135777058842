export const values = [
	{
		_id: 0,
		text: "Would you like to do an exercise to clarify your value system and find a way to act on your values?",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			surveyId: "values-v1",
			taskId: "exercise-values",
			optionType: "radio",
			questions: [0, 12],
			values: [
				{
					label: "👍",
					value: "start",
					replyType: "survey",
					questionIndex: 0
				},
				{
					label: "Not right now. Take me back.",
					value: "back",
					replyType: "dialogue",
					response: "Ok sure.. taking you back"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		_next: 3,
		text: "Thanks for doing the exercise. 🏋️",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		_end: true,
		text: "👋 See you next time, I'll take you back to the skill dashboard",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
