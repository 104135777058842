import React, {Fragment, useState, useEffect} from "react"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import moment from "moment-timezone"
import {Modal} from "@uprise/modal"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Components
import {Button} from "@uprise/button"
import {Medium, Note, P, Small, Tiny} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
// Icons
import Icons from "constants/Icons"
import {getCourseTypeByHostName} from "../../../utils/getCourseType"

const SuccessImage = Styled.img`
      height: 90px;
      width: 90px;
`

const Date = Styled.span`

`

const DateBar = Styled.div`
	padding: 9px 0;
	width: 420px;
	height: 40px;
	border-radius: 5px;
	background-color: ${extended.lightOrange.five};
`

export const MoreInfoModal = ({isOpen, handleClose, data, ...props}) => {
	let history = useHistory()

	return (
		<Modal
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='500px'
			textAlign='center'
			isOpen={isOpen}
			handleClose={handleClose}>
			<H3 className='m-b-5'>What happens on a coaching call?</H3>
			{getCourseTypeByHostName() === "corporate" ? (
				<Fragment>
					<Medium className='m-b-5'>
						You can book in a 30 minute call at a time that suits you. Your coach will show you how to use
						the skills for your specific situation. He/she will ask about a situation that&apos;s currently
						stressful for you then go through a worked example of how to best use that week&apos;s skill to
						deal with it effectively. If you choose &apos;Book a Call&apos; you can still do chat/email as
						well.
					</Medium>
					<H4 className='m-b-5'>What happens on chat/email coaching?</H4>
					<Medium className='m-b-10'>
						Your coach will offer tips about how to get the most out of the skills. You can also send your
						skills exercises to your coach and get feedback if you want it. There is still an option to book
						a call with your coach if you choose chat/email but it’s not compulsory.
					</Medium>
				</Fragment>
			) : (
				<Medium className='m-b-5'>
					You can book in a 60 minute call at a time that suits you. Your coach will show you how to use the
					skills for your specific situation. He/she will ask about a situation that’s currently stressful for
					you then go through a worked example of how to best use that week’s skill to deal with it
					effectively.
				</Medium>
			)}

			<Button variant='primary' size='medium' title='Close' onClick={() => handleClose()} />
		</Modal>
	)
}
