// Icons
export default {
	home: require("assets/images/icons/png/home/home.png"),
	homeActive: require("assets/images/icons/png/home-active/home-active.png"),
	library: require("assets/images/icons/png/library/library.png"),
	libraryActive: require("assets/images/icons/png/library-active/library-active.png"),
	you: require("assets/images/icons/png/you/you.png"),
	youActive: require("assets/images/icons/png/you-active/you-active.png"),
	notebook: require("assets/images/icons/png/notebook/notebook.png"),
	notebookActive: require("assets/images/icons/png/notebook-active/notebook-active.png"),
	gears: require("assets/images/icons/png/gears/gears.png"),
	headGears: require("assets/images/icons/png/head-gears/head-gears.png"),
	play: require("assets/images/icons/png/play/play.png"),
	completed: require("assets/images/icons/png/completed/completed.png"),
	lock: require("assets/images/icons/png/lock/lock.png"),
	medal: require("assets/images/icons/png/medal/medal.png"),
	time: require("assets/images/icons/png/time/time.png"),
	cog: require("assets/images/icons/png/cog/cog.png"),
	audio: require("assets/images/icons/png/audio/audio.png"),
	chevronRightPurple: require("assets/images/icons/png/chevron-right-purple/chevron-right-purple.png"),
	chevronPurple: require("assets/images/icons/png/chevron-purple/chevron-purple.png"),
	chevronDownPurple: require("assets/images/icons/png/chevron-down-purple/chevron-down-purple.png"),
	arrowDownYellow: require("assets/images/icons/png/arrow-down-yellow/arrow-down-yellow.png"),
	arrowDownGreen: require("assets/images/icons/png/arrow-down-green/arrow-down-green.png"),
	arrowDownRed: require("assets/images/icons/png/arrow-down-red/arrow-down-red.png"),
	send: require("assets/images/icons/png/send/send.png"),
	logoHead: require("assets/images/icons/png/logo-head/logo-head.png"),
	chevronUpBlue: require("assets/images/icons/png/chevron-up-blue/chevron-up-blue.png"),
	language: require("assets/images/icons/png/language/language@3x.png"),
	location: require("assets/images/icons/png/location/location@3x.png"),
	education: require("assets/images/icons/png/education/education@3x.png"),
	telephone: require("assets/images/icons/png/telephone/telephone@3x.png"),
	success: require("assets/images/icons/png/success/success@3x.png"),
	close: require("assets/images/icons/png/close/close@3x.png"),
	password: {
		src: "",
		height: "1rem",
		width: "1rem"
	},
	information: {
		src: "",
		height: "1rem",
		width: "0.4375rem"
	}
}
