export const sleepChecklist = [
	{
		_id: 0,
		text: "I can help you do a quick check to see if you’ve set yourself up for the best sleep possible",
		replyType: "dialogue",
		createdAt: new Date(),
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			replyType: "survey",
			surveyId: "sleep-checklist-v1",
			taskId: "exercise-sleep-checklist",
			questions: [0],
			recurring: true,
			values: [
				{
					label: "Let’s do it",
					value: "start",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		_next: 3,
		text: "It’s 15C - 22C (59F - 72F)",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		_next: 4,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questionIndex: 1,
		questions: [1],
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		_next: 5,
		text: "The answer is ‘Pitch black’ is ideal",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		_next: 6,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questionIndex: 2,
		questions: [2],
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		_next: 7,
		text: "The answer is ‘Any time as long as it’s the same time each day’",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 7,
		_next: 8,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questions: [3],
		questionIndex: 3,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		_next: 9,
		text: "The answer is ‘Warm shower and 15 mins of reading’",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 9,
		_next: 10,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questions: [4],
		questionIndex: 4,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		_next: 11,
		text: "The answer is ‘All of the above’",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 11,
		_next: 12,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questions: [5],
		questionIndex: 5,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 12,
		_next: 13,
		text: "The answer is ‘Moderate to strong’",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 13,
		_next: 14,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questions: [6],
		questionIndex: 6,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 14,
		_next: 15,
		text: "The answer is ‘20-30 mins, not too late in the day",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 15,
		_next: 16,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questions: [7],
		questionIndex: 7,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 16,
		_next: 17,
		text: "The answer is ‘6 hours’",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 17,
		_next: 18,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questions: [8],
		questionIndex: 8,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 18,
		_next: 19,
		text: "The answer is ‘4 hours’",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 19,
		_next: 20,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questions: [9],
		questionIndex: 9,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 20,
		_next: 21,
		text: "The answer is ‘3 hours’",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 21,
		_next: 22,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questions: [10],
		questionIndex: 10,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 22,
		_next: 23,
		text: "The answer is ‘3 hours’",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 23,
		_next: 24,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questions: [11],
		questionIndex: 11,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 24,
		_next: 25,
		text: "The answer is ‘3 hours’",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 25,
		_next: 26,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questions: [12],
		questionIndex: 12,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 26,
		_next: 27,
		text: "The answer is ‘Quiet levels of white noise’",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 27,
		_next: 28,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questions: [13],
		questionIndex: 13,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 28,
		_next: 29,
		text: "The answer is ‘All of the above’",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 29,
		_next: 30,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "sleep-checklist-v1",
		taskId: "exercise-sleep-checklist",
		questions: [14],
		questionIndex: 14,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 30,
		_next: 31,
		text: "The answer is ‘Get up and read a boring book’",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 31,
		text:
			"Thanks for taking the test. If you’d like to know more about the ways to get a good night’s sleep then do a google search for ‘Sleep Hygiene’, which explains some of the techniques in this test",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 31,
			_next: 32,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 32,
		_end: true,
		text: "Thanks, I'll take you back to the dashboard 👋",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
