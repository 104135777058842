/* Combine all available reducers to a single root reducer.
 */
import {combineReducers} from "redux"

import userStateReducer from "./userState"
import surveyStateReducer from "./surveyState"
import skillStateReducer from "./skillState"
import taskStateReducer from "./taskState"
import coachReducer from "./coachState"
import codeReducer from "./codeState"
import suggestedReducer from "./suggestedState"
import bookingsReducer from "./bookingsState"
import hrdashboardReducer from "./hrdashboardState"

export default history =>
	combineReducers({
		userState: userStateReducer,
		skill: skillStateReducer,
		task: taskStateReducer,
		surveyState: surveyStateReducer,
		coach: coachReducer,
		code: codeReducer,
		suggested: suggestedReducer,
		bookings: bookingsReducer,
		hrdashboard: hrdashboardReducer
	})
