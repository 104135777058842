import React from "react"
import {Redirect} from "react-router-dom"
import {useSelector} from "react-redux"
import styled from "styled-components"
import {Layout, Wrap} from "components/Shared/Layout/index"
import {LeftPanel} from "components/Shared/Panels/LeftPanel"
import LeftMenu from "components/Shared/Menus/Left"
import {Header} from "components/Shared/Panels/Header"
import {Main} from "components/Shared/Panels/Main"
import {RightPanel} from "components/Shared/Panels"
import YourHealth from "components/Shared/YourHealth"
import Tabs, {Tab} from "components/Shared/Tabs"
import {Stress, Wellbeing, DataUtilisation, Risk, Feedback} from "components/Shared/HrAnalytics"

function HRAnalytics() {
	const {surveyResults, isDashboardViewEnabled} = useSelector(state => state.userState)

	if (!isDashboardViewEnabled) {
		return <Redirect to='/' />
	}

	return (
		<Layout>
			<LeftPanel LogoMobile={true}>
				<LeftMenu active={4} subMenuActive={1} />
			</LeftPanel>
			<Wrap>
				<Header
					title='Analytics and Reporting'
					textAlign='left'
					width='100%'
					handleBack={null}
					hideMobile={true}
				/>
				<Main>
					<Tabs initialActiveKey='wellbeing'>
						<Tab eventKey='wellbeing' title='Wellbeing'>
							<Wellbeing />
						</Tab>
						<Tab eventKey='stress' title='Stress'>
							<Stress />
						</Tab>
						<Tab eventKey='utilisation' title='Utilisation'>
							<DataUtilisation />
						</Tab>
						<Tab eventKey='risks' title='Risks'>
							<Risk />
						</Tab>
						{/* <Tab eventKey='roi' title='Return of Investment'>
							Return of Investment Component
						</Tab> */}
						<Tab eventKey='feedback' title='Feedback'>
							<Feedback />
						</Tab>
					</Tabs>
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-block' width='310px' widthMd='250px' widthLg='250px'>
				<YourHealth className='m-b-7' surveyResults={surveyResults} />
			</RightPanel>
		</Layout>
	)
}

export default HRAnalytics
