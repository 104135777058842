import {getCourseType} from "helpers/utils"
import {STORE_USER_STATE, LOGGED_OUT, LOGGED_IN} from "actions/types"

var signedInSent = false

const initialState = {
	courseType: getCourseType()
}

export default function(state = initialState, action) {
	switch (action.type) {
		case STORE_USER_STATE: {
			if (!signedInSent) {
				signedInSent = true
			}
			console.log("action.userState", action.userState)
			return {...state, ...action.userState}
		}
		case LOGGED_IN:
			return {...state, loggedIn: true, jwt: action.jwt}
		case LOGGED_OUT:
			signedInSent = false
			return {}

		default:
			return {
				modules: [],
				...state
			}
	}
}
