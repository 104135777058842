import React from "react"
import Styled from "styled-components"
import {Modal} from "@uprise/modal"
import {backgrounds, extended} from "@uprise/colors"
import {spacing} from "@uprise/spacing"
import {Button} from "@uprise/button"
import {P} from "@uprise/text"
import {H3, H4, H5} from "@uprise/headings"

const NumberBox = Styled.section`
	borderRadius: 10px;
	background-color: ${backgrounds.fadedPurple};
	padding: ${spacing.s5};
	text-align: left;
 `

export const EmergencyNumbers = ({isOpen, handleClose, data, ...props}) => {
	return (
		<Modal
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='600px'
			scroll={false}
			textAlign='center'
			isOpen={isOpen}
			handleClose={handleClose}>
			<H3 className='m-b-10 m-t-5'>If this is an emergency, do one of the following based on your situation:</H3>
			{/* <H4 textAlign={"left"}>If you are in Australia</H4>
			<NumberBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0' color={extended.charcoal.one}>
					Work Incident:
				</H5>
				<H4 className='m-b-4'>(03) 9205 9488</H4>
				<P>Workplace critical incident. You will need the authorised HR or WHS officer to make the report</P>
			</NumberBox>
			<NumberBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0' color={extended.charcoal.one}>
					Emergency:
				</H5>
				<H4 className='m-b-4'>000</H4>
				<P>Ambulance, fire or police services</P>
			</NumberBox>
			<NumberBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0' color={extended.charcoal.one}>
					Lifeline:
				</H5>
				<H4 className='m-b-4'>13 11 14</H4>
				<P>Lifeline for urgent counselling or suicide support</P>
			</NumberBox> */}

			{/* <H4 textAlign={"left"}>If you are not in Australia</H4> */}
			<NumberBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0'>USA</H5>
				<P>Emergency number: 911</P>
				<P>National Suicide Prevention Lifeline at 1800-273-8255</P>
			</NumberBox>

			{/* <NumberBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0'>UK</H5>
				<P>Emergency number: 999</P>
				<P>Samaritans Helpline: 116 123</P>
			</NumberBox>

			<NumberBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0'>Philippines</H5>
				<P>Suicide Hotline 1-800-273-8255</P>
				<P>Manila Lifeline Centre Hotline: (02) 896 9191</P>
				<P>Calling from mobile phone: 0917 854 9191</P>
			</NumberBox>

			<NumberBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0'>Thailand</H5>
				<P>Emergency number: 191</P>
				<P>Thai Mental Health Hotline: 1323</P>
			</NumberBox>

			<NumberBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0'>Vietnam</H5>
				<P>Police Emergency: 113</P>
				<P>Medical Emergency: 115</P>
			</NumberBox>

			<NumberBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0'>Singapore</H5>
				<P>Police Emergency: 999</P>
				<P>Medical Emergency: 995</P>
				<P>Singapore Mental Health Hotline: 1800-221 4444</P>
			</NumberBox>

			<NumberBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0'>Malaysia</H5>
				<P>Police & Medical Emergency: 999</P>
				<P>Malaysia Mental Health Helpline: 03-2780 6803</P>
			</NumberBox>

			<NumberBox className='m-b-5'>
				<H5 className='m-t-0 m-b-0'>Indonesia</H5>
				<P>Police Emergency: 110</P>
				<P>Medical Emergency: 118</P>
			</NumberBox> */}

			<Button variant='primary' size='medium' title='Close' onClick={() => handleClose()} />
		</Modal>
	)
}
