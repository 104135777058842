import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import Styled from "styled-components"
import {connect} from "react-redux"
import moment from "moment-timezone"

// Colors
import {extended, backgrounds} from "@uprise/colors"
// Actions
import {getCoachByEmail} from "actions/coachActions"
import {updateUserState} from "actions/userStateActions"
import {fetchCode} from "actions/codesActions"
// Components
import YourHealth from "components/Shared/YourHealth"
import RecommendedCoach from "components/Shared/Coach/RecommendedCoach"
import CallsCapped from "components/Shared/Coach/CallsCapped"
import {Faqs} from "components/Shared/Coach/Faqs"
// UI
import {Layout} from "components/Shared/Layout"
import {P, Medium, ExtraSmall, Note} from "@uprise/text"
import {RightPanel, Main, LeftPanel} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"
// Actions
import {fetchBookings} from "actions/bookingsActions"
// helpers
import {calculateCaps} from "helpers/utils"

const Wrap = Styled.section`	background-color: ${extended.purple.six}; 
        width: 100%;
`

const Booking = ({userState, getCoachByEmail, coachEmail, bookings, fetchCode, fetchBookings, code}) => {
	const [reachedCapLimit, setReachedCapLimit] = useState(false)
	const [codeLoading, setCodeLoading] = useState(true)
	const [totalValidBookings, setTotalBookings] = useState(true)

	const {skills, meta} = userState
	const firstLoad = true

	useEffect(() => {
		fetchBookings()

		// get employer code data
		fetchCode(userState.employerCode)
	}, [])

	useEffect(() => {
		if (Object.values(code).length) {
			setCodeLoading(false)
		}
	}, [code])

	useEffect(() => {
		if (coachEmail) {
			getCoachByEmail(coachEmail)
		}
	}, [coachEmail])

	useEffect(() => {
		const {companyCallCap, finalCaps} = calculateCaps(code)

		let currentBookings =
			bookings?.data
				?.filter(booking => {
					return booking?.id
				})
				.filter(booking => moment(booking.createdAt).diff(moment(finalCaps?.startingDate), "days") >= 0)
				.filter(booking => {
					if (["within24", "within_24", "userNoShow", "user_no_show"].includes(booking.cancelReason))
						return true
					else return !booking.cancelled
				}) || []

		// Check if calls are capps + check if users bookings exceeds codes call cap limit
		if(!code?.caps?.capCalls)
			setReachedCapLimit(false)
		else if (currentBookings?.length >= parseInt(companyCallCap)) {
			setReachedCapLimit(true)
		} else setReachedCapLimit(false)

		setTotalBookings(currentBookings)
	}, [code])

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={3} />
			</LeftPanel>
			<Wrap>
				<Header
					title='Coaching'
					textAlign='left'
					width='100%'
					back={location?.state?.from.length ? true : false}
				/>
				<Main>
					{reachedCapLimit && !codeLoading && !userState.disableCallCap ? (
						<CallsCapped
							requested={userState.increase_cap_token}
							totalValidBookings={totalValidBookings.length}
							code={code}
							userState={userState}
							className='m-b-6'
						/>
					) : !codeLoading ? (
						<RecommendedCoach className='m-b-6' />
					) : (
						<Medium>Loading..</Medium>
					)}
					<Faqs className='m-t-10' />
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-none d-xl-block' width='310px'>
				<YourHealth surveyResults={userState.surveyResults} />
			</RightPanel>
		</Layout>
	)
}

const mapStateToProps = state => {
	return {
		userState: state.userState,
		coachEmail: state?.userState?.coach,
		coach: state?.coach,
		code: state?.code,
		bookings: state?.bookings
	}
}

const mapDispatchToProps = dispatch => ({
	getCoachByEmail: email => dispatch(getCoachByEmail(email)),
	updateUserState: state => dispatch(updateUserState(state)),
	fetchCode: code => dispatch(fetchCode(code)),
	fetchBookings: () => dispatch(fetchBookings())
})

export default connect(mapStateToProps, mapDispatchToProps)(Booking)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Booking.propTypes = {}

Booking.defaultProps = {}
