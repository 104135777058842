window.location.replace("https://app.uprisehealth.com")

import React, {useEffect} from "react"
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import {connect} from "react-redux"
import "./App.css"

// HOC
import PrivateRoute from "./routes/PrivateRoute"
import CourseTypeRoute from "./routes/CourseTypeRoute"
// Containers
import Home from "./containers/Home"
import PreOnboarding from "./containers/PreOnboarding"
import Onboarding from "./containers/Onboarding"
import PreOnboardingAzure from "./containers/PreOnboardingAzure"
import PostProgram from "./containers/PostProgram"
import Dashboard from "./containers/Dashboard"
import Booking from "./containers/Booking"
import Settings from "./containers/Settings"
import Notebook from "./containers/Notebook"
import ExerciseSummary from "./containers/ExerciseSummary"
import Library from "./containers/Library"
import Skill from "./containers/Skill"
import Chatbot from "./containers/Chatbot"
import ChatbotScore from "./containers/ChatbotScore"
import Video from "./containers/Video"
import Audio from "./containers/Audio"
import Tips from "./containers/Tips"
import Docs from "./containers/Docs"
import Practice from "./containers/Practice"
import Science from "./containers/Science"
import Examples from "./containers/Examples"
import Faqs from "./containers/Faqs"
import Summary from "./containers/Summary"
import Series from "./containers/Series"
import ConfirmEmail from "./containers/ConfirmEmail"
import MakeBooking from "./containers/MakeBooking"
import SelectCoach from "./containers/SelectCoach"
import CoachDetail from "./containers/CoachDetail"
import CapApproval from "./containers/CapApproval"
import WellbeingSignIn from "./containers/WellbeingSignIn"
import WellbeingSignUp from "./containers/WellbeingSignUp"
import Ruokday from "./containers/Ruokday"
import RuokdaySurvey from "./containers/RuokdaySurvey"
import RuokdayResults from "./containers/RuokdayResults"
import HRDashboard from "./containers/HRDashboard"
import HRAnalytics from "./containers/HRAnalytics"

function App({userState}) {
	const auth = userState.loggedIn
	const hideLocalization = userState.courseType === "rtw"

	useEffect(() => {
		if (window && window.Localize && hideLocalization) {
			window.Localize.hideWidget()
		}
	}, [hideLocalization])

	React.useEffect(() => {
		if (window.Intercom) {
			let configObj = {}
			configObj[`hide_default_launcher`] = true
			window.Intercom("update", configObj)
		}
	}, [window.Intercom])

	return (
		<Router>
			<Switch>
				<Route exact path='/wellbeing-sign-in' component={WellbeingSignIn} />
				<Route exact path='/wellbeing-sign-up' component={WellbeingSignUp} />
				<Route exact path='/wellbeing-sign-up/code/:code' component={WellbeingSignUp} />
				<CourseTypeRoute exact path='/sign-in' component={Home} />
				<CourseTypeRoute exact path='/sign-up' component={Home} />
				<CourseTypeRoute exact path='/sign-up/code/:code' component={Home} />
				<CourseTypeRoute exact path='/reset' component={Home} />
				<CourseTypeRoute exact path='/user/forgot/:token/:email' component={Home} />
				{/* Ruokday */}
				<Route exact path='/ruokday' component={Ruokday} />
				<Route exact path='/ruokday/code/:companyCode' component={Ruokday} />
				<Route exact path='/ruokday/survey' component={RuokdaySurvey} />
				<Route exact path='/ruokday/results' component={RuokdayResults} />
				{/* Legacy routes, please phase these out */}
				<CourseTypeRoute exact path='/login/register/' component={Home} />
				<CourseTypeRoute exact path='/login' component={Home} />
				{/* remove these when phased out of email campaigns */}
				<PrivateRoute exact path='/' component={Dashboard} auth={auth} />
				<PrivateRoute exact path='/pre-onboarding' component={PreOnboarding} auth={auth} />
				<PrivateRoute exact path='/onboarding' component={Onboarding} auth={auth} />
				<PrivateRoute exact path='/pre-onboarding-sso' component={PreOnboardingAzure} auth={auth} />
				<PrivateRoute exact path='/dashboard/analytics' component={HRAnalytics} auth={auth} />

				<PrivateRoute exact path='/dashboard' component={HRDashboard} auth={auth} />
				<PrivateRoute exact path='/library' component={Library} auth={auth} />
				<PrivateRoute exact path='/notebook' component={Notebook} auth={auth} />
				<PrivateRoute exact path='/summary' component={Summary} auth={auth} />
				<PrivateRoute exact path='/booking' component={Booking} auth={auth} />
				<PrivateRoute exact path='/make-booking' component={MakeBooking} auth={auth} />
				<PrivateRoute exact path='/select-coach' component={SelectCoach} auth={auth} />
				<PrivateRoute exact path='/coach-details/:email' component={CoachDetail} auth={auth} />
				<PrivateRoute exact path='/settings' component={Settings} auth={auth} />
				<PrivateRoute exact path='/skill/:skillId' component={Skill} auth={auth} />
				{/* RTW */}
				<PrivateRoute exact path='/post-program' component={PostProgram} auth={auth} />
				{/* RTW post program survey */}
				<PrivateRoute exact path='/follow-up' component={PostProgram} auth={auth} />
				{/* Readings */}
				<PrivateRoute exact path='/faqs/:skillId' component={Faqs} auth={auth} />
				<PrivateRoute exact path='/summary/:skillId' component={Summary} auth={auth} />
				<PrivateRoute exact path='/exercise-summary' component={ExerciseSummary} auth={auth} />
				<PrivateRoute exact path='/practice/:skillId' component={Practice} auth={auth} />
				<PrivateRoute exact path='/science/:skillId' component={Science} auth={auth} />
				<PrivateRoute exact path='/examples/:skillId' component={Examples} auth={auth} />
				<PrivateRoute exact path='/series/:seriesId' component={Series} auth={auth} />
				{/* Tasks */}
				<PrivateRoute exact path='/skill/:skillId/video/:taskId' component={Video} auth={auth} />
				<PrivateRoute exact path='/skill/:skillId/audio/:taskId' component={Audio} auth={auth} />
				<PrivateRoute exact path='/skill/:skillId/tips/:taskId' component={Tips} auth={auth} />
				<PrivateRoute exact path='/skill/:skillId/doc/:taskId' component={Docs} auth={auth} />
				<PrivateRoute exact path='/skill/:skillId/bot/:taskId' component={Chatbot} auth={auth} />
				{/* For task without a refering skill */}
				<PrivateRoute exact path='/bot/:taskId' component={Chatbot} auth={auth} />
				<PrivateRoute exact path='/bot/:taskId/score' component={ChatbotScore} auth={auth} />
				<PrivateRoute exact path='/user/retrieve/:token/:email' component={ConfirmEmail} auth={auth} />
				<PrivateRoute exact path='/user/delete/:token/:email' component={ConfirmEmail} auth={auth} />
				{/* Call caps */}
				<Route exact path='/calls/cap/approve/:token/:email' component={CapApproval} />
				<Route exact path='/calls/cap/deny/:token/:email' component={CapApproval} />
			</Switch>
		</Router>
	)
}

const mapStateToProps = state => {
	const {wellnessCheckup, userState} = state
	return {wellnessCheckup, userState}
}

export default connect(mapStateToProps, null)(App)
