import PropTypes from "prop-types"
import React, {useState, useEffect, Fragment} from "react"
import moment from "moment-timezone"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import {connect} from "react-redux"
import * as surveys from "uprise-surveys/lib/surveys/index"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds, primary} from "@uprise/colors"
// Components
import {Card} from "@uprise/card"
import {Button} from "@uprise/button"
import {H5} from "@uprise/headings"
import {Medium} from "@uprise/text"
// Helpers
import * as api from "helpers/api"
import {getSurveyIndex, formatStringToSentenceCase} from "helpers/utils"
import {usePrevious} from "helpers/customHooks"
// Actions
import {updateUserState} from "actions/userStateActions"

import RadioChoice from "./inputTypes/RadioChoice"
import DotSlider from "./inputTypes/DotSlider"
import Slider from "./inputTypes/Slider"
import MultiChoice from "./inputTypes/MultiChoice"
import SelectBox from "./inputTypes/SelectBox"
import Text from "./inputTypes/Text"
import TextArea from "./inputTypes/TextArea"
import NumberOptional from "./inputTypes/NumberOptional"
import RadioOptionWithInputs from "./inputTypes/RadioOptionWithInputs"
import DatePicker from "./inputTypes/DatePicker"
const ButtonWrapper = Styled.div`
	display: flex;
`

const PromptLabel = Styled.label`
 	line-height: 1.75; 
	text-indent: -25px;
	padding-left: 35px;
	display: inline-block;
`

function getQuestions(surveyId, screener) {
	const questions = surveys.retrieveSurvey(surveyId).questions
	return questions.filter(question => question.batteryId === screener)
}

function setupAnswers(questions, answers = {}) {
	for (const question of questions) {
		if (typeof answers[question.batteryId] === "undefined") {
			answers[question.batteryId] = []
		}
	}

	return answers
}

function getPastAnswers(surveyResults, taskId) {
	if (!surveyResults) {
		return null
	}

	let taskIdResults = surveyResults.filter(d => {
		return (
			(d.taskId.includes(taskId) && !d.completed && !d.recurring) ||
			(!d.completed && typeof d.completed !== "undefined")
		)
	})

	if (taskIdResults.length > 0) {
		// Get latest results
		return taskIdResults.reduce((prev, curr) => (curr.timestamp > prev.timestamp ? curr : prev)).answers
	}

	return false
}

function LargeSurvey(props) {
	const [questions, setQuestions] = useState([])
	const [answers, setAnswers] = useState({})
	const [pastAnswers, setPastAnswers] = useState(getPastAnswers(props.surveyResults, props.taskId))
	const [newSurvey, setNewSurvey] = useState(!pastAnswers)
	const [allowNext, setAllowNext] = useState(false)
	const [allowPrev, setAllowPrev] = useState(false)
	const [loading, setLoading] = useState(false)

	const prevScreener = usePrevious(props.screener)
	const history = useHistory()

	let isLast = false

	useEffect(() => {
		console.count("[Effect]")
		if (props.screener && props.screener !== prevScreener) {
			const newQuestions = getQuestions(props.surveyId, props.screener)
			const newAnswers = setupAnswers(newQuestions, pastAnswers ? pastAnswers : answers)

			setAllowNext(false)
			setQuestions(newQuestions)
			setAnswers(newAnswers)
		}

		if (
			!allowNext &&
			!answers[props.screener]?.includes(undefined) &&
			answers[props.screener]?.length === questions.length
		) {
			setAllowNext(true)
		}

		if (!allowPrev && props.screeners.indexOf(props.screener) !== 0) {
			setAllowPrev(true)
		} else if (allowPrev && props.screeners.indexOf(props.screener) === 0) {
			setAllowPrev(true)
		}
	}, [questions, answers, props.screener, prevScreener, allowNext, allowPrev])

	const handleChange = (value, id, index) => {
		let newAnswers = {...answers}
		newAnswers[id][index] = value

		setAnswers(newAnswers)
	}

	const getInputType = ({id, type, index}) => {
		let inputType

		const question = questions[index]

		switch (type) {
			case "text":
				inputType = (
					<Text
						handleChange={value => handleChange(value, id, index)}
						value={answers[id][index]}
						units={question.units}
						valid={question.valid}
					/>
				)
				break
			case "textarea":
				inputType = (
					<TextArea
						handleChange={value => handleChange(value, id, index)}
						value={answers[id][index]}
						units={question.units}
						valid={question.valid}
					/>
				)
				break
			case "radio":
				inputType = (
					<RadioChoice
						handleChange={value => handleChange(value, id, index)}
						id={id}
						value={answers[id][index]}
						name={question.key}
						questionIndex={index}
						options={question.options}
						checked={answers[id][index]}
						valid={question.valid}
					/>
				)
				break

			case "radioWithInputs":
				inputType = (
					<RadioOptionWithInputs
						handleChange={value => handleChange(value, id, index)}
						id={id}
						value={answers[id][index]}
						name={question.key}
						questionIndex={index}
						options={question.options}
						checked={answers[id][index]}
						valid={question.valid}
					/>
				)
				break
			case "dropdown":
				inputType = (
					<SelectBox
						handleChange={value => handleChange(value, id, index)}
						id={id}
						value={answers[id][index]}
						name={question.key}
						questionIndex={index}
						options={question.options}
						checked={answers[id][index]}
						valid={question.valid}
					/>
				)
				break
			case "who5":
			case "multi":
				inputType = (
					<MultiChoice
						handleChange={value => handleChange(value, id, index)}
						id={id}
						value={answers[id][index]}
						name={question.key}
						questionIndex={index}
						options={question.options}
						valid={question.valid}
					/>
				)
				break
			case "dot":
				inputType = (
					<DotSlider
						handleChange={value => handleChange(value, id, index)}
						id={id}
						value={answers[id][index]}
						name={question.key}
						questionIndex={index}
						options={question.options}
						valid={question.valid}
					/>
				)
				break
			case "numberOptional":
				inputType = (
					<NumberOptional
						handleChange={value => handleChange(value, id, index)}
						value={answers[id][index]}
						valid={question.valid}
					/>
				)
				break
			case "dayslider":
			case "pointslider":
			case "slider":
				inputType = (
					<Slider
						handleChange={value => handleChange(value, id, index)}
						id={id}
						value={answers[id][index]}
						name={question.key}
						questionIndex={index}
						options={question.options}
						valid={question.valid}
					/>
				)
				break
			case "date":
				inputType = (
					<DatePicker
						date={moment(answers[id][index])}
						onChange={value => {
							handleChange(value, id, index)
						}}
						allowFutureDates={!question.isTodayMaxDate}
						allowPreviousDates={true}
					/>
				)
		}

		return inputType
	}

	const handlePrev = () => {
		const index = props.screeners.indexOf(props.screener) - 1

		if (index >= 0) {
			props.handleChange(-1)
			// history.push(`/onboarding/survey/${props.screeners[index]}`)
		}
	}

	const handleNext = () => {
		validate()
	}

	const validate = () => {
		const currentAnswers = [...answers[props.screener]]
		const newQuestions = [...questions]
		let valid = true

		newQuestions.forEach((q, i) => {
			// Fix default value for slider input
			if (q.type === "slider" && !currentAnswers[i]) {
				currentAnswers[i] = parseInt(q.options.length / 2)
			}

			// Fix default value for dropdown input
			if (q.type === "dropdown" && !currentAnswers[i]) {
				currentAnswers[i] = q.options[0]
			}
			// Fix default value for numberOptional
			if (q.type === "numberOptional" && !currentAnswers[i]) {
				currentAnswers[i] = 0
			}

			// Fix default value for numberOptional
			if (q.optional && !currentAnswers[i]) {
				currentAnswers[i] = " "
			}

			if (q.type === "date" && !currentAnswers[i]) {
				currentAnswers[i] = moment()
			}

			if (typeof currentAnswers[i] === "undefined" || currentAnswers[i] === "") {
				newQuestions[i].valid = {valid: false, message: "This question is required"}
				valid = false
				document.getElementById(`q-${i + 1}`).scrollIntoView({
					behavior: "smooth"
				})
			} else {
				newQuestions[i].valid = {valid: true, message: null}
			}
		})
		const newAnswers = {...answers, [props.screener]: currentAnswers}
		setQuestions(newQuestions)
		setAnswers(newAnswers)

		if (valid) {
			saveScore(newAnswers)
			props.setAlertType("primary")
		} else {
			props.setAlertType("error")
		}
	}

	const saveScore = answers => {
		const index = props.screeners.indexOf(props.screener)
		const nextScreener = props.screeners[index + 1]
		const completed = index + 1 === props.screeners.length ? true : false
		let surveyIndex = getSurveyIndex(props.task.taskId, props.surveyResults)
		// increment unique identifier to surveyId if a recurring survey
		let id = 0
		if (surveyIndex === null) {
			id = 0
		} else if (parseInt(surveyIndex) === 0) {
			id = 1
		} else {
			id = parseInt(surveyIndex) + 1
		}
		// Start loading
		setLoading(true)

		if (completed) {
			api.put("surveyresults/complete", {
				id: id,
				taskId: props.taskId,
				surveyId: props.task.surveyId,
				timestamp: moment().unix(),
				completed: true,
				recurring: props.task.recurring ? true : false,
				answers: answers
			})
				.then(resp => {
					props.updateUserState(resp.user_state)

					api.post("complete", {
						module: props.taskId,
						completed: true
					})
				})
				.then(() => {
					setLoading(false)
					props.handleChange(1)
					// Scroll to top
					window.scrollTo({top: 0, left: 0, behavior: "smooth"})
				})
				.catch(err => {
					console.log(err)
					setLoading(false)
				})
		} else if (newSurvey && index === 0) {
			api.post("surveyresults", {
				id: id,
				taskId: props.taskId,
				surveyId: props.task.surveyId,
				timestamp: moment().unix(),
				completed: false,
				recurring: props.task.recurring ? true : false,
				title: props.task.title,
				answers: answers
			})
				.then(resp => {
					props.updateUserState(resp.user_state)

					setLoading(false)
					props.handleChange(1)
					// Scroll to top
					window.scrollTo({top: 0, left: 0, behavior: "smooth"})
				})
				.catch(err => {
					setLoading(false)
					console.log(err)
				})
		} else {
			api.put("surveyresults/update", {
				id: id,
				taskId: props.taskId,
				surveyId: props.task.surveyId,
				timestamp: moment().unix(),
				completed: false,
				answers: answers
			})
				.then(resp => {
					props.updateUserState(resp.user_state)
					setLoading(false)
					props.handleChange(1)
					// Scroll to top
					window.scrollTo({top: 0, left: 0, behavior: "smooth"})
				})
				.catch(err => {
					setLoading(false)
					console.log(err)
				})
		}
	}

	return (
		<Card
			border={`1px solid ${extended.purple.five}`}
			shadow={false}
			backgroundColor={backgrounds.white}
			padding={spacing.s10}
			width='100%'>
			{questions
				.filter(question => question.batteryId === props.screener)
				.map((question, index) => {
					return (
						<Fragment key={`${props.screener} + ${index} `}>
							{index === 0 && questions[index].extraText && (
								<H5 className='mb-4' dangerouslySetInnerHTML={{__html: questions[index].extraText}} />
							)}

							{question.inline ? (
								<div className='form-group row mb-5'>
									<Medium className='col-sm-6' color={primary.charcoal} weight='bold'>
										<label
											className='col-form-label text-bold'
											id={`q-${index + 1}`}
											dangerouslySetInnerHTML={{
												__html: `${index + 1}. ${formatStringToSentenceCase(question.prompt)}`
											}}
										/>
									</Medium>
									<div className='col-sm-6'>
										{getInputType({id: question.batteryId, type: question.type, index})}
									</div>
								</div>
							) : (
								<div className='form-group  mb-5'>
									<div className='row mb-2'>
										<Medium color={primary.charcoal}>
											<PromptLabel
												className=''
												id={`q-${index + 1}`}
												dangerouslySetInnerHTML={{
													__html: `${index + 1}. ${formatStringToSentenceCase(
														question.prompt
													)}`
												}}
											/>
										</Medium>
									</div>
									<div className='row'>
										<div className='col-sm-12'>
											{getInputType({id: question.batteryId, type: question.type, index})}
										</div>
									</div>

									{question?.subPrompt && (
										<div className='row mb-2'>
											<Medium color={primary.charcoal} className='m-t-3'>
												<label
													className='col-sm-12 col-form-label text-bold '
													id={`sub-prompt-${index + 1}`}
													dangerouslySetInnerHTML={{
														__html: `${question.subPrompt}`
													}}
												/>
											</Medium>
										</div>
									)}

									{questions[index]?.description && (
										<div className='row'>
											<div className='col-sm-12'>
												<Medium className='mt-4' color={primary.charcoal}>
													{questions[index].description}
												</Medium>
											</div>
										</div>
									)}
								</div>
							)}
						</Fragment>
					)
				})}
			<ButtonWrapper className='justify-content-between'>
				<Button
					className='m-t-10 m-b-7'
					variant='secondary'
					title='Back'
					size='medium'
					fullWidth={false}
					width='240px'
					disabled={!allowPrev}
					onClick={() => handlePrev()}
				/>
				<Button
					className='m-t-10 m-b-7'
					variant='primary'
					title={isLast ? "Done" : "Next"}
					size='medium'
					fullWidth={false}
					width='240px'
					// disabled={!state.allowPrev}
					isLoading={loading}
					onClick={() => handleNext()}
				/>
			</ButtonWrapper>
		</Card>
	)
}

LargeSurvey.propTypes = {
	done: PropTypes.any,
	module: PropTypes.any,
	screener: PropTypes.any,
	screeners: PropTypes.any,
	surveyId: PropTypes.any,
	surveyResults: PropTypes.any,
	task: PropTypes.any,
	taskId: PropTypes.any
}

function mapStateToProps(state) {
	return {
		surveyState: state.surveyState
	}
}

export default connect(mapStateToProps, {updateUserState})(LargeSurvey)
