import moment from "moment-timezone"
// API
import {get} from "helpers/api"

/**
 * Get survey Index
 * @param {string} taskId
 * @param {array} surveyResults
 * @returns {integer}
 */
export const getSurveyIndex = (taskId, surveyResults) => {
	let taskIdResults =
		surveyResults?.filter(result => {
			// filter results that surveys are recurring and survey result must be completed to increment index
			return result.taskId.includes(taskId) && result.recurring && result.completed
		}) || []

	if (taskIdResults.length > 0) {
		// Get latest results
		return parseInt(taskIdResults.reduce((prev, curr) => (curr.timestamp > prev.timestamp ? curr : prev)).id)
	}

	return null
}

/**
 * Get personalistion results
 * @param {array} surveyResults
 * @returns {array}
 */
export const getPersonalisation = surveyResults => {
	let results = surveyResults.filter(results => {
		if (
			results.surveyId === "personalisation-v1" ||
			results.surveyId === "onboarding-v1.1" ||
			results.surveyId === "onboarding-students-v1"
		) {
			return results
		}
	})

	// get latest result
	return (
		results.map(result => {
			return result.answers["PERSONALISATION"][0]
		})[results.length - 1] || []
	)
}

export const getPrevRoute = routeHistory => {
	return routeHistory.filter(route => {
		if (routeHistory[0].location.pathname !== route.location.pathname) {
			return route
		}
	})[0]?.location.pathname
}

/**
 *
 * @param {String} fullName
 * @returns {string} firstName
 */
export const getFirstName = (fullName = "") => {
	const tokens = fullName.split(" ").map(i => i.trim())
	return tokens[0]
}
/**
 *
 * @param {String} nextTaskType
 * @returns {String} Route name
 */
export const getTaskPathFromTaskType = nextTaskType => {
	switch (nextTaskType) {
		case "survey":
			return "bot"
		case "audio-file":
			return "audio"
		case "video":
			return "video"
		case "overview-video":
			return "video"
		default:
			return nextTaskType
	}
}

/**
 *
 * @param {Object} userState includes lessons, homework, activity, tasks
 * @param {String} nextSkillId Id of next skill to complete
 *
 * @returns {Object} nextTask
 */
export const getNextTask = (userState, nextSkillId) => {
	let nextLesson
	let nextHomework
	let nextTask
	let nextPath

	const {lessons, homework, activity, tasks} = userState

	if (lessons[nextSkillId]) {
		for (let index = 0; index < lessons[nextSkillId].length; index++) {
			const currentLesson = lessons[nextSkillId][index]

			if (!activity[currentLesson] || !activity[currentLesson].completed) {
				nextLesson = currentLesson
				break
			}
		}
	}

	if (homework[nextSkillId]) {
		for (let index = 0; index < homework[nextSkillId].length; index++) {
			const currentHomework = homework[nextSkillId][index]

			if (!activity[currentHomework] || !activity[currentHomework].completed) {
				nextHomework = currentHomework
				break
			}
		}
	}

	if (nextLesson) {
		nextTask = tasks[nextLesson]
		nextPath = nextLesson
	}

	if (!nextTask && nextHomework) {
		nextTask = tasks[nextHomework]
		nextPath = nextHomework
	}

	return [nextTask, nextPath]
}

export const getSkill = (userState, skillId) => {
	// Retrive skill
	const meta = userState.meta[skillId]
	// Retrieve skills
	const skills = userState.skills[skillId]
	// Retrieve lessons
	const lessons = userState.lessons[skillId].map(taskId => {
		return {...userState.tasks[taskId], ...{taskId}}
	})
	// Retrieve bonus tasks
	const bonus = userState.bonus[skillId].map(taskId => {
		return {...userState.tasks[taskId], ...{taskId}}
	})
	// Retrieve summary
	const summary = userState.summaries[skillId]
	// Retrieve summary
	const practice = userState.practice[skillId]
	// Retrieve homework
	const homework = userState.homework[skillId].map(taskId => {
		return {...userState.tasks[taskId], ...{taskId}}
	})
	// Retrieve science
	const science = userState.science[skillId]
	// Retrieve examples
	const examples = userState.examples[skillId]
	// Retrieve faqs
	const faqs = userState.faqs[skillId]

	return {
		skillId,
		meta,
		skills,
		lessons,
		bonus,
		summary,
		practice,
		homework,
		science,
		examples,
		faqs
	}
}

export const getTaskId = (result, skills) => {
	for (const skill of Object.entries(skills)) {
		if (skill[1].includes(result.taskId)) {
			const taskId = skill[0]
			return taskId
		}
	}
}

// To check for backwards compatibility with old course versions activity
export const checkActivityComplete = (id, activity) => {
	switch (id) {
		case "onboarding-corporate":
			return (
				activity["survey-onboarding"]?.completed ||
				(activity["survey-wellnesscheck-onboarding"]?.completed && activity["survey-preprogram"]?.completed)
			)
		case "onboarding-rtw":
			return activity["survey-preprogram"]?.completed && activity["onboarding-page"]?.completed
		case "onboarding-students":
			return activity["onboarding-students"]?.completed
		case "wellbeingCheck":
			break
		case "stressCheck":
			break
		case "retraining-thinking-v1":
			break
		case "retraining-thinking-revision-v1":
			break
		case "retraining-thinking-stress-v1":
			break
		case "retraining-thinking-metacognition-v1":
			break
		case "retraining-thinking-beliefs-v1":
			break
		case "values-v1":
			break
		case "progress-v1":
			break
		case "perspective-taking-v1":
			break
		case "guilt-v1":
			break
		default:
			return
	}
}

export const taskIdWhitelist = [
	"retraining-thinking-v1",
	"retraining-thinking-revision-v1",
	"retraining-thinking-stress-v1",
	"retraining-thinking-metacognition-v1",
	"retraining-thinking-beliefs-v1",
	"retraining-thinking-sleep-thoughts-v1",
	"retraining-thinking-sleep-predictions-v1",
	"values-v1",
	"progress-v1",
	"perspective-taking-v1",
	"guilt-v1",
	"survey-corona-anxiety-odds-v1",
	"survey-corona-anxiety-positives-v1",
	"survey-corona-beliefs-about-health-anxiety-checking-v1",
	"survey-corona-tolerating-uncertainty-v1",
	"retraining-thinking-focus-v1",
	"survey-focus-question-v1",
	// alcohol
	"motivational-interviewing-addictions-v1",
	"self-test-gambling-v1",
	"self-test-drug-use-v1",
	"self-test-hazardous-drinking-v1",
	"self-cant-change-person-can-v1",
	"willingness-trust-letting-go-addicted-self-v1",
	"personal-formula-happiness-v1",
	// RTW
	"survey-problem-definition-v1",
	"survey-problem-solution-v1",
	"survey-before-experiment-v1",
	"survey-after-experiment-v1",
	// Manager training
	"manager-training-v1",
	"building-empathy-v1",
	// Stress
	"stress-triggers-v1",
	// Worry time
	"introduction-worry-time-v1",
	"worry-time-exercise-v1"
]

export const stringTemplateParser = (expression, valueObj) => {
	const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g

	let text = expression.toString().replace(templateMatcher, (substring, value, index) => {
		value = valueObj[value]
		return value
	})
	return text
}

/**
 * Convert old skillId to new
 * @param {string} skillId
 * @returns {string}
 */
export const getThumbnailId = skillId => {
	switch (skillId) {
		case "retraining1":
			return "mindset"
		case "pleasant":
			return "personal-values"
		case "letgo":
			return "mindfulness"
		case "breathing":
			return "stress-management"
		case "awareness":
			return "helping-others"
		case "reflectiveListening":
			return "reflective-listening"
		case "crisis":
			return "crisis-situations"
		case "earlyWarningSigns":
			return "early-warning-signs"
		case "perspectivetaking":
			return "perspective-taking"
		case "revision":
			return "revision"
		case "stress":
			return "stress"
		case "guilt":
			return "guilt"
		case "metacognition":
			return "metacognition"
		case "beliefs":
			return "beliefs"
		case "sleepBasics":
			return "sleep-basics"
		case "sleepAndRelaxation":
			return "sleep-and-relaxation"
		case "mindfulnessForSleep":
			return "mindfulness-for-sleep"
		case "control":
			return "control-vs-acceptance"
		case "observer":
			return "observer-perspective"
		case "autopilot":
			return "autopilot-mode"
		case "threats":
			return "mental-threats"
		case "healthAndDiseaseAnxiety":
			return "health-and-disease-anxiety"
		case "worry":
			return "worry"
		case "problemSolving":
			return "problemSolving"
		case "problemDefinition":
			return "problemDefinition"
		case "solutionSelection":
			return "solutionSelection"
		case "experimentation":
			return "experimentation"
		case "relapseTrac":
			return "relapseTrac"
		case "smartGoals":
			return "smartGoals"
		case "painFatigueIntro":
			return "painFatigueIntro"
		case "pacing":
			return "pacing"
		case "controlledBreathing":
			return "controlledBreathing"
		case "stepLadders":
			return "stepLadders"
		case "focusProductivityProcrastination":
			return "focus-productivity-procrastination"
		case "alcoholAndAddictions":
			return "alcohol-and-addictions"
		case "alcoholAndAddictionsTestYourself":
			return "alcohol-addictions-test-yourself"
		case "alcoholAndAddictionsGetStarted":
			return "alcohol-addictions-get-started"
		case "notFeelingGoodEnough":
			return "not-feeling-good-enough"
		case "alcoholAndAddictionsGettingPastAddictedSelf":
			return "alcohol-addictions-getting-past-the-addicted-self"
		case "alcoholAndAddictionsMindfulnessSkills":
			return "alcohol-addictions-mindfulness-skills"
		case "alcoholAndAddictionsMotivationGettingHelp":
			return "alcohol-addictions-motivation-getting-help"
		case "alcoholAndAddictionsStayingOnTrack":
			return "alcohol-addictions-staying-on-track"
		case "leadingWellbeing":
			return "leading-wellbeing"
		case "leadingMentalHealth":
			return "leading-mental-health"
		case "buildingEmpathy":
			return "building-empathy"
		case "financialSkills":
			return "financial-skills"
		case "financialWorries":
			return "financial-worries"
		case "financialStressAndThinking":
			return "financial-stress-and-thinking"
		case "mindfulnessForFinancialStress":
			return "mindfulness-for-financial-stress"
		default:
			break
	}
}
/**
 *
 * @param {Number} duration duration of call either 30 or 60
 * @param {Object} options
 */
export const getBookingMessage = (duration = 60, options) => {
	if (duration === 30) {
		return `A coaching call is 30 mins long. ${options?.coach?.name?.split(" ")[0]} will help you apply Uprise
		skills to your life`
	}
	if (duration === 60) {
		return `This is not a coaching call. It is a 60 min therapy session.`
	}
}

/**
 *
 * @param {Number} duration duration of call either 30 or 60
 * @param {Object} options
 */
export const getBookingMessageForRTW = (duration = 60, options) => {
	if (duration === 30) {
		return `A coaching call is 30 mins long.`
	}
	if (duration === 60) {
		return `A coaching call is 60 mins long.`
	}
}

export const getCourseType = () => {
	let courseType = "corporate"
	switch (window.location.hostname) {
		case "students.uprise.co":
			courseType = "students"
			break
		case "staging-students.uprise.co":
			courseType = "students"
			break
		case "testing-students.uprise.co":
			courseType = "students"
			break
		case "rtw.uprise.co":
			courseType = "rtw"
			break
		case "testing-rtw.uprise.co":
			courseType = "rtw"
			break
		case "workable-us.uprise.co":
		case "testing-workable-us.uprise.co":
		case "staging-workable-us.uprise.co":
			courseType = "rtw"
			break
	}
	return courseType
}

export const formatStringToSentenceCase = string => {
	const [firstWord, ...rest] = string.split(" ")
	const firstLetterCapital = `${firstWord[0].toUpperCase()}${firstWord.slice(1)}`
	return [firstLetterCapital, ...rest].join(" ")
}

/**
 *
 * @param {Object} config containing `onSuccess` and `onError` callback
 */
export const tryLogin = ({history, onSuccess, onError}) => {
	return get("state")
		.then(resp => {
			if (resp.error) {
				onError(resp.error)
				return null
			}
			if (resp.user_state?.activity["onboarding-page"]?.completed) {
				history.push("/bot/wellbeingCheck")
			} else {
				history.push("/onboarding")
			}
			onSuccess(resp)
		})
		.catch(err => {
			onError(err)
			console.error(err)
		})
}

export const calculateCaps = code => {
	const callCapPeriod = code?.caps?.callCapPeriod

	//find the current active caps

	const finalCaps = callCapPeriod
		?.slice()
		.reverse()
		?.find(caps => {
			return moment().isAfter(moment(caps.startingDate))
		})

	if (!finalCaps) return {companyCallCap: 0, finalCaps: {}}
	else {
		const companyCallCap =
			code?.caps && code?.caps?.capCalls
				? finalCaps.combinedBookingCaps + finalCaps.therapyBookingCaps + finalCaps.coachingBookingCaps
				: 0

		return {companyCallCap, finalCaps}
	}
}

export const isCoachingEnabled = features => {
	if (Array.isArray(features)) return !!features?.find(feature => feature?.id === "coaching-sessions")?.enabled
	else return false
}

export const isTherapyEnabled = features => {
	if (Array.isArray(features)) return !!features?.find(feature => feature?.id === "therapy-sessions")?.enabled
	else return false
}
