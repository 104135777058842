import {primary} from "@uprise/colors"
import {Medium} from "@uprise/text"
import Styled from "styled-components"

const MediumStyled = Styled(Medium)`
	a {
		color: ${primary.purple}
		text-decoration: underline;
	}
`

const ImageWrap = Styled.section`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 30px;
`

const PrivacyImage = Styled.img`
	width: 140px;
  height: 140px;

	@media (max-width: 1024px) {
		height: 200px;
		width: 200px;
	}

	@media (max-width: 475px) {
		width: 140px;
		height: 140px;
	}
`

export {MediumStyled, ImageWrap, PrivacyImage}
