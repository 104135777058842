import React, {Fragment, useState, useEffect} from "react"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import moment from "moment-timezone"
import {Modal} from "@uprise/modal"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Components
import {Button} from "@uprise/button"
import {Medium, Bold, Note, P, Small, Tiny} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
// Icons
import Icons from "constants/Icons"

const SuccessImage = Styled.img`
      height: 90px;
      width: 90px;
`

const DateBar = Styled.div`
	padding: 9px 0;
	width: 420px;
	height: 40px;
	border-radius: 5px;
	background-color: ${extended.lightOrange.five};
`

export const RequestConfirmModal = ({isOpen, hrEmail, handleClose, data, ...props}) => {
	let history = useHistory()

	return (
		<Modal
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='500px'
			textAlign='center'
			isOpen={isOpen}
			handleClose={handleClose}>
			<H3 className='m-b-10'>Request is sent</H3>
			<SuccessImage className='m-b-7' src={Icons.success} />

			<Medium className='m-b-6' textAlign='left'>
				Your anonymously additional coaching calls request has been sent to your HR manager{" "}
				<Bold>({hrEmail})</Bold>. When it’s approved, we’ll send you an email alert.
			</Medium>

			<Button variant='primary' size='medium' title='Ok' onClick={() => handleClose()} />
		</Modal>
	)
}
