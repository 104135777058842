const constraints = {
	accessCode: {
		presence: {
			message: "^Please enter an access code",
			allowEmpty: false
		},
		length: {
			minimum: 2,
			message: "^access code must be at least 1 characters"
		}
	},
	phone: {
		presence: {
			message: "^Please enter a phone number",
			allowEmpty: false
		},
		format: {
			pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/,
			message: "Must be a valid phone number"
		},
		length: {
			minimum: 8,
            maximum: 15,
			tooShort: "Must be at least 8 digits in length",
			tooLong: "Must be 15 digits or less in length"
		}
	},
	name: {
		presence: {
			message: "^Please enter a name",
			allowEmpty: false
		},
		length: {
			minimum: 2,
			message: "^Your name must be at least 1 characters"
		}
	}
}

export default constraints
