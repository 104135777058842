import React, {Fragment, useState, useEffect} from "react"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import moment from "moment-timezone"
import {Modal} from "@uprise/modal"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Components
import {Button} from "@uprise/button"
import {Medium, Note, P, Small, Tiny} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
// Icons
import Icons from "constants/Icons"

const Date = Styled.span`

`

const DateBar = Styled.div`
	padding: 9px 0;
	width: 420px;
	height: 40px;
	border-radius: 5px;
	background-color: ${extended.lightOrange.five};
`

export const MoreInfoModal = ({isOpen, handleClose, data, ...props}) => {
	let history = useHistory()

	return (
		<Modal
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='500px'
			height= 'calc(100vh - 100px)'
            overflow = 'auto'
			textAlign='center'
			isOpen={isOpen}
			handleClose={handleClose}>
			<H4 className='m-b-5'>What happens on chat/email with Mental Health Specialist?</H4>
			<Medium className='m-b-5'>
				Your chat with Mental Health Specialist is a licensed healthcare professional. They might be either an
				LCSW, a psychologist, a counselor or a nurse depending on your program.
			</Medium>

			<H4 className='m-b-5'>How does it work?</H4>
			<Medium className='m-b-5'>
				The courses in Uprise are like chapters in a self-help book. The more chapters that you read, the better
				you will get. It’s hard to stay motivated so your chat with Mental Health Specialist will help you. Your
				Mental Health Specialist can show you how to do the exercises in the courses and recommend courses and
				exercises based on your needs.
			</Medium>

			<H4 className='m-b-5'>Is it confidential?</H4>
			<Medium className='m-b-5'>
				Uprise does not share any information that could identify you to your employer or insurer unless it’s an
				emergency situation. Uprise does send reports but those are for overall results from big groups of
				participants and never have names or anything that might identify you personally.
			</Medium>

			<H4 className='m-b-5'>Do I have to message my Mental Health Specialist?</H4>
			<Medium className='m-b-10'>
				No, you don’t have to. We just recommend you do as you’re likely to improve the more courses you do and
				your Mental Health Specialist helps make that happen.
			</Medium>

			<Button variant='primary' size='medium' title='Close' onClick={() => handleClose()} />
		</Modal>
	)
}
