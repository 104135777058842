export const testForGambling = [
	{
		_id: 0,
		text:
			"This is a test for whether or not your gambling might require professional support in order to recover. It’s a measure that’s recommended by most peak bodies called the GBIRT.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text: "There are 10 questions so it should only a minute or so",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",
			surveyId: "self-test-gambling-v1",
			taskId: "exercise-self-test-gambling",
			questions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
			recurring: true,
			values: [
				{
					label: "That’s ok",
					value: "start",
					replyType: "dialogue",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		createdAt: new Date(),
		replyType: "calcScore",
		questionRange: [2, 3, 4, 5, 6, 7, 8, 9],
		nextRange: [5, 6, 11, 14],
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	// range 0
	{
		_id: 5,
		_next: 19,
		text: "You scored in the ‘Healthy’ range. It’s not likely that you have a problem with gambling.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	// range 1
	{
		_id: 6,
		_next: 7,
		text: "You scored in the ‘Risky’ range.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 7,
			_next: 8,
			optionType: "radio",
			values: [
				{
					label: "What would you recommend I do?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		_next: 9,
		text:
			"I recommend educating yourself about gambling addiction, starting to set some goals around reducing your use of gambling and doing an exercise called ‘Motivational Interviewing’ about your use.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 9,
			_next: 10,
			optionType: "radio",
			values: [
				{
					label: "Anything else?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		_next: 19,
		text:
			"I also recommend calling the Gambling Hotline in your country to discuss support options that could work for you or seeing your doctor.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	// range 2
	{
		_id: 11,
		text: "You scored in the ‘Harmful’ range. This is the second highest possible level for problematic gambling.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 12,
			_next: 13,
			optionType: "radio",
			values: [
				{
					label: "What would you recommend I do?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 13,
		_next: 19,
		text:
			"We’d recommend seeking professional help rather than trying to recover on your own. It’s possible that you might meet criteria for a diagnosis of ‘Gambling Disorder’. Start by calling the Gambling Hotline in your country to discuss support options that could work for you and seeing your doctor.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	// Range 3
	{
		_id: 14,
		text:
			" You scored in the ‘Severe’ range. This is the highest possible level for gambling and you would almost definitely meet criteria for a diagnosis of ‘Gambling Disorder’’.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 15,
			_next: 16,
			optionType: "radio",
			values: [
				{
					label: "What would you recommend I do?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 16,
		text:
			"We’d recommend that you seek professional help as soon as possible to give yourself the best chance of recovering.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 17,
			_next: 18,
			optionType: "radio",
			values: [
				{
					label: "Anything else?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 18,
		_next: 19,
		text:
			"Call the Gambling Hotline in your country to discuss support options that could work for you and see your doctor.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	//
	{
		_id: 19,
		text:
			"Thanks for doing the test with me. There are millions of people who struggle with gambling and there are groups both online and in-person that support a stigma and judgment free space to get support.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 20,
			_next: 21,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 21,
		_end: true,
		text: "Great, I'll take you back to the dashboard 👋",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
