export const motivationalInterviewingAddictions = [
	{
		_id: 0,
		text:
			"Acceptance is one of the first steps we take in recovering from alcohol and addictions. Understanding that you might be powerless over the chemical, biological, physical and genetic influences that are driving your behaviour is key",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text:
			"I’d recommend doing this checklist and sharing it with someone who is able to hear it without judging you or offering advice.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",
			values: [
				{
					label: "That sounds hard!",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		text:
			"If you have a genetic risk for addiction then keeping these to yourself probably won’t help you to recover unfortunately.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 5,
			_next: 6,
			optionType: "radio",
			surveyId: "motivational-interviewing-addictions-v1",
			taskId: "exercise-motivational-interviewing-addictions",
			questions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
			values: [
				{
					label: "Ok, let’s take a look then",
					value: "start",
					questionIndex: 0,
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		_next: 7,
		text: "Thanks for doing the checklist.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 7,
			_next: 8,
			optionType: "radio",
			values: [
				{
					label: "That’s ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		_next: 9,
		text:
			"Rather than giving a score, this is more a personal reflection for you to check whether you feel ready to accept that there is a problem around your addiction.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 9,
			_next: 10,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		_next: 11,
		text: "Change takes time. That exercise is called Motivational Interviewing.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 11,
			_next: 12,
			optionType: "radio",
			values: [
				{
					label: "What’s that?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 12,
		_next: 13,
		text:
			"It’s an evidence-based exercise to help get started with recovery. It’s worth doing several times in different formats and sharing the information with people who can be trusted to support you in your recovery.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 14,
			_next: 15,
			optionType: "radio",
			values: [
				{
					label: "Ok. Thanks.",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 15,
		_end: true,
		text: "Great, I'll take you back to the dashboard 👋",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
