import React, {Fragment, useState, useEffect} from "react"
import {bool, object, string} from "prop-types"
import {useHistory, useLocation} from "react-router-dom"
import Styled from "styled-components"
import {connect} from "react-redux"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// Utils
import {taskIdWhitelist} from "helpers/utils"
// UI
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {Medium, Note, Small} from "@uprise/text"
import {H5} from "@uprise/headings"
import {ProgressiveImage} from "@uprise/image"
// Components
import {CoachModal} from "components/Shared/Coach/CoachModal"
import {MoreInfoModal} from "components/Shared/Coach/MoreInfoModal"
// spacing
import {spacing} from "@uprise/spacing"
// Icons
import Icons from "constants/Icons"
// API
import {post} from "helpers/api"
// Actions
import {getCoachByEmail} from "actions/coachActions"
import {updateUserState} from "actions/userStateActions"
// Typography
import {text} from "@uprise/typography"

const CoachStyles = Styled.section`
`

const Content = Styled.div`
	padding: ${spacing.s7}
      display: flex;
      flex-direction: row;
	width: 100%;
	
	@media (max-width: 1024px) {
		flex-direction: column;
	}

	@media (max-width: 475px) {
		flex-direction: column;
	}
`

const CoachInfoWrap = Styled.div`
      display: flex;
      flex-direction: column;
	width: 100%;
`

const CoachHeader = Styled.div`
      display: flex;
	flex-direction: column;
 	
	@media (max-width: 1024px) {
		margin-top: -105px;
		margin-left: 120px;
	}

	@media (max-width: 475px) {
		margin-left: 120px;
		margin-top: -100px;
	}

`

const CoachBody = Styled.div`
      display: flex;
      flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
 
	@media (max-width: 1024px) {
		margin-top: 80px;
		flex-direction: column;
 	}

	@media (max-width: 475px) {
		margin-top: 100px;
		flex-direction: column;
 	}
`

const CoachSpecs = Styled.div`
	width: 100%;

	@media (max-width: 1024px) {
		margin-bottom: 30px;
	}

	@media (max-width: 475px) {
		margin-bottom: 30px;
 	}
`

const CoachTimes = Styled.div`

`
const CoachBio = Styled.div`
	display: flex;
	flex-direction: column;
`

const CoachBioContent = Styled.div`
	p {
		color: ${extended.charcoal.one}
	}
`

const ButtonsWrap = Styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	float: right;
	width: 100%;

	@media (max-width: 1024px) {
		flex-direction: column;
	}
`

const CoachPhoto = Styled.div`
      height: 142px;
	max-width: 142px;
	width: 142px;
	border-radius: 10px;
	margin-right: 25px;
	
	@media (max-width: 1024px) {
		height: 100px;
		min-width: 100px;
		max-width: 100px;
		margin-right: 0;
	}

	@media (max-width: 475px) {
		height: 100px;
		min-width: 100px;
		max-width: 100px;
		margin-right: 0;
	}
`

const ChangeCoach = Styled.div`
	margin-top: 20px;

	@media (max-width: 1024px) {
	}
`

const SpecIcon = Styled.img`
	vertical-align: top;
	margin-left: ${props => props.marginLeft};
	margin-top: ${props => props.marginTop};
      height: ${props => props.height};
	width: ${props => props.width};
      min-width: 11px;
`

const Ul = Styled.ul`

	width: auto;
	margin-top: -1px;
      margin-bottom: ${spacing.s3};
      list-style-type: none;
	padding-left: 0;	 
`

const Li = Styled.li`
	display: inline-block;
	vertical-align: top;
	margin-bottom: ${spacing.s5};
 `

const Skills = Styled.ul`
	max-width: 200px;
      margin-bottom: ${spacing.s32};
      list-style-type: none;
      padding-left: ${spacing.s4};
`

const Qualifications = Styled.ul`
	margin-bottom: ${spacing.s32};
	list-style-type: none;
	padding-left: ${spacing.s4};
`

const Qualification = Styled.li`
      display: flex;
	flex-direction: row;
	margin-bottom: 0;
	color: ${extended.charcoal.one};
	font-size: ${text.t6};
`

const Skill = Styled.li`
      display: flex;
	flex-direction: row;
	margin-bottom: 0;
	color: ${extended.charcoal.one};
	font-size: ${text.t6};
`

const OffDay = Styled.div`
	padding: 5px;
	max-height: 24px;
	border-radius: 5px;
 	margin-right: 4px;
  	background-color: ${backgrounds.fadedPurple};
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const Day = Styled.div`
	padding: 5px;
	max-height: 24px;
	line-height: 1;
	border-radius: 5px;
	margin-right: 4px;
  	background-color: ${extended.purple.five};
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (max-width: 475px) {
		margin-top: 10px;
	}
`

const Availability = Styled.div`
	display: flex;
	flex-direction: 'row';
	width: 400px;

	@media (max-width: 475px) {
		width: 100%;
		flex-wrap: wrap;
		margin-top: -10px;
	}
`

const LeftWrap = Styled.div`
`

const CoachCard = ({className, coach, pick, change, recommended, getCoachByEmail, updateUserState}) => {
	let history = useHistory()

	const [langs, setLangs] = useState()
	const [availability, setAvailability] = useState([])
	const [showCoachModal, setShowCoachModal] = useState(false)
	const [showMoreInfo, setShowMoreInfo] = useState(false)
	const [loadingSelect, setLoadingSelect] = useState(false)

	useEffect(() => {
		if (coach?.hours) {
			setAvailability(
				Object.values(coach.hours).map((day, index) => {
					switch (index) {
						case 0:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<Small color={primary.purple}>Sun</Small>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<Small color={extended.charcoal.three}>Sun</Small>
									</OffDay>
								)
							}
						case 1:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<Small color={primary.purple}>Mon</Small>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<Small color={extended.charcoal.three}>Mon</Small>
									</OffDay>
								)
							}
						case 2:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<Small color={primary.purple}>Tues</Small>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<Small color={extended.charcoal.three}>Tues</Small>
									</OffDay>
								)
							}
						case 3:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<Small color={primary.purple}>Wed</Small>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<Small color={extended.charcoal.three}>Wed</Small>
									</OffDay>
								)
							}
						case 4:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<Small color={primary.purple}>Thurs</Small>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<Small color={extended.charcoal.three}>Thurs</Small>
									</OffDay>
								)
							}
						case 5:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<Small color={primary.purple}>Fri</Small>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<Small color={extended.charcoal.three}>Fri</Small>
									</OffDay>
								)
							}
						case 6:
							if (day.length > 0) {
								return (
									<Day key={index}>
										<Small color={primary.purple}>Sat</Small>
									</Day>
								)
							} else {
								return (
									<OffDay key={index}>
										<Small color={extended.charcoal.three}>Sat</Small>
									</OffDay>
								)
							}
					}
				})
			)
		}

		// Get keys of languages and split into string
		if (coach.languages && coach.languages.length > 0) {
			setLangs(coach.languages.join(", "))
		} else if (coach.intro) {
			setLangs(
				Object.keys(coach.intro)
					.map(lang => {
						return lang.charAt(0).toUpperCase() + lang.slice(1)
					})
					.join(", ")
			)
		}
	}, [coach])

	const _handlePick = coach => {
		setLoadingSelect(true)
		post("setCoach", {
			coachEmail: coach.email
		}).then(resp => {
			getCoachByEmail(coach.email)

			post("set_intercom_coach", {
				intercomCoach: coach.email,
				coachName: coach.name
			}).then(resp => {
				updateUserState(resp.user_state)
				setShowCoachModal(true)
				setLoadingSelect(false)
			})
		})
	}

	if (coach?.name) {
		return (
			<CoachStyles className={className}>
				<Card backgroundColor={backgrounds.white} shadow={true}>
					<Content>
						<LeftWrap>
							<CoachPhoto>
								<ProgressiveImage
									className='m-r-6 m-b-4'
									width='100%'
									height='100%'
									borderRadius='10px'
									src={
										coach?.photos?.avatarURL.length > 0
											? coach?.photos?.imageURL
											: require("assets/images/coach/default-coach.png")
									}
									overlaySrc={
										coach?.photos?.avatarURI.length > 0
											? coach?.photos?.avatarURI
											: require("assets/images/coach/default-coach.png")
									}
								/>
							</CoachPhoto>
							{change && (
								<ChangeCoach>
									<Button
										className='m-t-0'
										variant='secondary'
										title='Change Coach'
										size='small'
										width='full'
										onClick={() => history.push("select-coach", {from: location.pathname})}
									/>
								</ChangeCoach>
							)}
						</LeftWrap>

						<CoachInfoWrap>
							<CoachHeader>
								<H5 className='m-t-0 m-b-1'>
									{coach.name}
									{coach?.activeOn?.debug ? "- (this account is not visible regular users)" : null}
								</H5>
								<Note className='m-b-4' color={extended.charcoal.one}>
									{coach.shortIntro}
								</Note>
							</CoachHeader>

							<CoachBody>
								<CoachBio className='m-b-6'>
									<Note className='m-t-0' color={extended.charcoal.one}>
										Bio
									</Note>
									<CoachBioContent dangerouslySetInnerHTML={{__html: coach?.intro?.english}} />
								</CoachBio>
								<CoachSpecs className=' '>
									<Ul>
										<Li className='m-r-10'>
											<Ul>
												<Li className='m-r-10'>
													<SpecIcon
														className='d-inline-block'
														src={Icons.location}
														width='13px'
														height='16px'
														marginTop='4px'
														marginLeft='0px'
													/>
													<Medium className='d-inline-block align-top m-l-3'>
														{coach.timezone}
													</Medium>
												</Li>
												<Li>
													<SpecIcon
														className='d-inline-block'
														src={Icons.language}
														width='14px'
														height='14px'
														marginTop='6px'
														marginLeft='0px'
													/>
													<Medium className='d-inline-block align-top m-l-3'>{langs}</Medium>
												</Li>
											</Ul>
										</Li>

										{coach?.qualifications?.length > 0 && (
											<Li className='m-r-10'>
												<SpecIcon
													className='d-inline-block'
													src={Icons.education}
													width='16px'
													height='15px'
													marginTop='6px'
													marginLeft='-2px'
												/>

												<Qualifications className='d-inline-block align-top'>
													{coach?.qualifications?.map((qualification, index) => {
														return (
															<Qualification key={index}>{qualification}</Qualification>
														)
													})}
												</Qualifications>
											</Li>
										)}
										{coach?.skills?.length > 0 && (
											<Li className='m-r-0'>
												<SpecIcon
													className='d-inline-block'
													src={Icons.notebookActive}
													width='12px'
													height='16px'
													marginTop='6px'
													marginLeft='0px'
												/>
												<Skills className='d-inline-block align-top'>
													{coach?.skills?.map((skill, index) => {
														return <Skill key={index}>{skill}</Skill>
													})}
												</Skills>
											</Li>
										)}
									</Ul>
								</CoachSpecs>
								<CoachTimes>
									<Note className='m-t-0 m-b-2' color={extended.charcoal.one}>
										Availability
									</Note>
									<Availability className='m-t-0 m-b-3'>{availability}</Availability>
								</CoachTimes>
								<ButtonsWrap className='m-t-6'>
									{recommended && (
										<Fragment>
											<Button
												className='m-r-4 m-r-sm-0 m-b-sm-3'
												variant='secondary'
												title='View other Uprise coaches'
												padding='5px 15px'
												size='medium'
												width='auto'
												onClick={() => history.push("select-coach", {from: location.pathname})}
											/>
											<Button
												className=''
												variant='primary'
												padding='5px 15px'
												onClick={() => history.push("make-booking")}
												title={`Continue with ${coach?.name?.split(" ")[0]}`}
												size='medium'
												width='auto'
											/>
										</Fragment>
									)}

									{pick && coach && (
										<Fragment>
											<Button
												className='m-b-sm-3'
												variant='secondary'
												size='medium'
												title='Learn more about Coaching'
												full={false}
												padding='5px 15px'
												onClick={() => setShowMoreInfo(true)}
											/>
											<MoreInfoModal
												isOpen={showMoreInfo}
												handleClose={() => setShowMoreInfo(false)}
											/>
											<Button
												className='m-l-4 m-l-sm-0'
												variant='primary'
												title='Pick as Coach'
												isLoading={loadingSelect}
												size='medium'
												full={false}
												padding='5px 15px'
												onClick={() => _handlePick(coach)}
											/>
											<CoachModal
												data={coach}
												isOpen={showCoachModal}
												handleClose={() => setShowCoachModal(false)}
											/>
										</Fragment>
									)}
								</ButtonsWrap>
							</CoachBody>
						</CoachInfoWrap>
					</Content>
				</Card>
			</CoachStyles>
		)
	} else {
		return <Medium>Loading</Medium>
	}
}

const mapDispatchToProps = dispatch => ({
	getCoachByEmail: email => dispatch(getCoachByEmail(email)),
	updateUserState: state => dispatch(updateUserState(state))
})

export default connect(null, mapDispatchToProps)(CoachCard)

CoachCard.propTypes = {
	coach: object.isRequired,
	pick: bool,
	change: bool,
	recommended: bool
}

CoachCard.defaultProps = {
	pick: false,
	change: false,
	recommended: false
}
