// Get skills completion count
export const getSkills = meta => {
	let skills = 0
	Object.values(meta).forEach(skill => {
		skill.completed ? skills++ : skills
	})

	if (isNaN(skills)) {
		return 0
	}
	return skills
}

// Get exercise completion count
export const getExercises = meta => {
	let exercises = 0

	Object.values(meta).forEach(task => {
		task.completedTasks ? exercises++ : exercises
	})

	if (isNaN(exercises)) {
		return 0
	}
	return exercises
}
