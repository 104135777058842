import React, {useEffect, useState} from "react"
import {object, array} from "prop-types"
import Styled from "styled-components"
import {useHistory} from "react-router-dom"
import {connect} from "react-redux"

// Colors
import {extended, backgrounds} from "@uprise/colors"
// Components
import {Layout} from "components/Shared/Layout"
import {ContainerFluid, Row} from "@uprise/grid"
import {LeftPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import {VerticalProgress} from "components/Shared/Onboarding/VerticalProgress"
// Coporate
import {getCorporateItemsList} from "components/Corporate/Onboarding/utils"
// Students
import {getStudentItemsList} from "components/Students/Onboarding/utils"
// Corporate, Students shared
import OnboardingSteps from "components/Shared/Onboarding"
// RTW
import OnboardingRtwSteps from "components/Rtw/Onboarding"
import {getRTWItemsList} from "components/Rtw/Onboarding/utils"
// API
import {post} from "helpers/api"
// Actions
import {updateUserState} from "actions/userStateActions"

const Wrap2 = Styled.section`
	background-color: ${extended.purple.six};
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;

	@media (max-width: 1200px) {
		flex-direction: column;
	}

	@media (max-width: 992px) {
		flex-direction: column;
	}
`

const Wrap = Styled.section`
	background-color: ${extended.purple.six};
	width: 100%;
	height: 100%;
`

const Onboarding = ({userState, surveyState, updateUserState}) => {
	let history = useHistory()
	const [activeIndex, setActiveIndex] = useState(0)
	const courseType = userState.courseType
	const [progressCheckpoints, setProgressCheckpoints] = useState(getCorporateItemsList())

	/**
	 *  Left Menu Items
	 */
	useEffect(() => {
		let newItems

		if (courseType === "rtw") {
			// If `pre-onboarding` in not completed for RTW user
			newItems = getRTWItemsList(userState)
		} else {
			newItems = getCorporateItemsList()
		}

		setProgressCheckpoints(newItems)
	}, [])

	useEffect(() => {
		if (courseType === "rtw" && userState?.activity["survey-preprogram"]?.completed) {
			if (progressCheckpoints[9]) {
				_handleNext(9)
			}
		}
	}, [userState])

	const _handleNext = id => {
		let newItems = [...progressCheckpoints]

		if (id === newItems.length - 1 && courseType === "rtw") {
			_handleCompleteRtw()
		} else {
			newItems.map((item, index) => {
				item.active = false
				if (index === id) {
					item.active = true
				}
			})

			setActiveIndex(id)
			setProgressCheckpoints(newItems)
		}
	}

	const _handleCompleteRtw = () => {
		// Mark it as completed...
		// Order is important
		post("complete", {
			module: "onboarding-page",
			completed: true
		})
			.then(() =>
				post("complete", {
					module: "onboarding-rtw",
					completed: true
				})
					.then(res => {
						updateUserState(res.user_state)
						history.push("/dashboard")
					})
					.catch(error => console.error(error))
			)
			.catch(error => console.error(error))
	}

	const _handleCompleteCorporate = () => {
		// Mark it as completed...
		post("complete", {
			module: "onboarding-page",
			completed: true
		})

		post("complete", {
			module: "onboarding-corporate",
			completed: true
		}).then(res => {
			updateUserState(res.user_state)
			history.push("/dashboard")
		})
	}

	const _handleCompleteStudents = () => {
		// Mark it as completed...
		post("complete", {
			module: "onboarding-page",
			completed: true
		})

		post("complete", {
			module: "onboarding-students",
			completed: true
		}).then(res => {
			updateUserState(res.user_state)
			history.push("/dashboard")
		})
	}

	useEffect(() => {
		if (courseType === "corporate" && Object.values(surveyState).length > 0) {
			let itemsList = progressCheckpoints

			itemsList.map((item, index) => {
				item.active = false
				if (index === 1 && surveyState.activeScreener === "WHO5") {
					item.active = true
					setActiveIndex(1)
				}
				if (index === 2 && surveyState.activeScreener === "STRESS") {
					item.active = true
					setActiveIndex(2)
				}

				if (index === 3 && surveyState.activeScreener === "PERSONALISATION") {
					item.active = true
					setActiveIndex(3)
				}
			})

			setProgressCheckpoints(itemsList)
		} else if (courseType === "students" && Object.values(surveyState).length > 0) {
			let itemsList = progressCheckpoints
			itemsList.map((item, index) => {
				item.active = false
				if (index === 1 && surveyState.activeScreener === "WHO5") {
					item.active = true
					setActiveIndex(1)
				}
				if (index === 2 && surveyState.activeScreener === "STRESS_STUDENTS") {
					item.active = true
					setActiveIndex(2)
				}

				if (index === 3 && surveyState.activeScreener === "PERSONALISATION") {
					item.active = true
					setActiveIndex(3)
				}
			})

			setProgressCheckpoints(itemsList)
		}
	}, [surveyState])

	const containerHeight = courseType === "rtw" ? "auto" : null

	// Check if onboarding already completed
	userState.surveyResults.forEach(survey => {})

	// Check if onboarding already completed
	if (
		userState.activity["onboarding-page"]?.completed &&
		(userState.activity["onboarding-corporate"]?.completed ||
			userState.activity["onboarding-rtw"]?.completed ||
			userState.activity["onboarding-students"]?.completed)
	) {
		history.push("/dashboard")
	}

	return (
		<ContainerFluid height='100vh' backgroundColor={backgrounds.fadedPurple}>
			<Row className='flex-nowrap h-100'>
				<LeftPanel width='250px' className='d-none d-sm-none d-md-none d-lg-none d-xl-flex'>
					<VerticalProgress items={progressCheckpoints} />
				</LeftPanel>
				<Wrap2>
					<Wrap className='h-100'>
						<Header
							title={`${progressCheckpoints[activeIndex]?.header}`}
							textAlign='center'
							width='100%'
							back={location?.state?.from.length ? true : false}
						/>

						<Main className='10px 25px 10px 25px h-100' containerHeight={containerHeight}>
							{courseType === "corporate" && (
								<OnboardingSteps
									progressCheckpoints={progressCheckpoints}
									handleNext={_handleNext}
									handleComplete={_handleCompleteCorporate}
									userState={userState}
									dialogueId='onboarding-corporate'
								/>
							)}
							{courseType === "students" && (
								<OnboardingSteps
									progressCheckpoints={progressCheckpoints}
									handleNext={_handleNext}
									handleComplete={_handleCompleteStudents}
									userState={userState}
									dialogueId='onboarding-students'
								/>
							)}
							{courseType === "rtw" && (
								<OnboardingRtwSteps
									progressCheckpoints={progressCheckpoints}
									handleNext={_handleNext}
									handleComplete={_handleCompleteRtw}
									activeIndex={activeIndex}
									userState={userState}
								/>
							)}
						</Main>
					</Wrap>
				</Wrap2>
			</Row>
		</ContainerFluid>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		surveyState: state.surveyState
	}
}

export default connect(mapStateToProps, {updateUserState})(Onboarding)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Onboarding.propTypes = {
	userState: object.isRequired,
	activity: array
}

Onboarding.defaultProps = {}
