import moment from "moment-timezone"
import {post, get, setJwt} from "helpers/api"
import {storeuserState} from "actions/loginActions"

import {LOGGED_IN} from "./types"

const setTimezone = timezone => {
	moment.tz.setDefault(timezone)

	post("setUserTimezone", {timezone})
		.then(resp => {
			// console.log(resp)
		})
		.catch(err => {
			console.log(err)
		})
}

const handleTrial = (dispatch, response) => {
	const now = moment(new Date())
	const createdAt = moment(response.createdAt)
	const diff = now.diff(createdAt)
	const timeLeft = moment.duration(response.user_state.features.trialLength * (86400 * 1000) - diff)
	dispatch(
		storeuserState({
			...response.user_state,
			...{timeLeft: timeLeft}
		})
	)
}

export function onLoggedIn(response, nextPathname = "/", history) {
	return dispatch => {
		setJwt(response.jwt)
		dispatch(loggedIn(response.jwt))

		if (response?.user_state?.features?.trialLength) {
			handleTrial(dispatch, response)
		} else {
			dispatch(
				storeuserState({
					...response.user_state,
					...{createdAt: response.createdAt}
				})
			)
		}

		if (moment.tz.guess()) {
			setTimezone(moment.tz.guess())
		}

		history.push(nextPathname)
	}
}

export function updateUserState(userState) {
	return dispatch => {
		dispatch(storeuserState(userState))
	}
}

export function loggedIn(jwt) {
	return {
		type: LOGGED_IN,
		jwt
	}
}
