import PropTypes from "prop-types"
import React, {useState, useEffect} from "react"
import Styled from "styled-components"
import {connect} from "react-redux"

// Utils
import {filterCoachesByEmployerCode, filterCoachesByCourseType} from "./utils"
import {getCourseTypeByHostName} from "../../../utils/getCourseType"
// API
import {get} from "helpers/api"
// Components
import CoachCard from "components/Shared/Coach/CoachCard"

const CoachStyles = Styled.section`
`

const CoachList = ({className, userState}) => {
	const [coaches, setCoaches] = useState()
	const [coachList, setCoachList] = useState()

	useEffect(() => {
		get("coaches").then(coaches => {
			if (userState.employerCode !== "behappy") {
				coaches = coaches.filter(coach => {
					return typeof coach?.activeOn?.debug === "undefined" || coach?.activeOn?.debug === false
				})
			}

			setCoaches(coaches)
		})
	}, [])

	useEffect(() => {
		if (coaches) {
			let filteredCoaches = filterCoachesByEmployerCode(coaches, userState.employerCode)

			let courseType = getCourseTypeByHostName()
			filteredCoaches = filterCoachesByCourseType(filteredCoaches, courseType)

			filteredCoaches = filteredCoaches.map((coach, index) => {
				let hasHours = false
				// check if hours are present
				if (!coach.hours) hasHours = false
				else
					Object.entries(coach.hours)?.find(hour => {
						if (hour?.[1]?.length) {
							hasHours = true
							return true
						}
					})

				if (hasHours) return <CoachCard key={index} className='m-b-10' coach={coach} pick={true} />
				else return null
			})

			setCoachList(filteredCoaches)
		}
	}, [coaches])

	return <CoachStyles className={className}>{coachList}</CoachStyles>
}

CoachList.propTypes = {
	className: PropTypes.string,
	userState: PropTypes.object.isRequired
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		coachEmail: state?.userState?.coach,
		coach: state?.coach
	}
}

export default connect(mapStateToProps, null)(CoachList)
