import React, {useState, useEffect, Component, Fragment} from "react"
import {connect} from "react-redux"
import Styled, {css} from "styled-components"
import {Line, Bar} from "react-chartjs-2"
import moment from "moment-timezone"

// UI
import {Container, Row, Col} from "@uprise/grid"
import {ProgressBar} from "@uprise/charts"
import {Card} from "@uprise/card"
import {H2, H3, H4, H5} from "@uprise/headings"
import {Medium, ExtraSmall} from "@uprise/text"
// Components
import {Switch} from "@uprise/switch"
import {YourStress} from "components/Shared/YouHealth/YourStress"
import {YourWellbeing} from "components/Shared/YouHealth/YourWellbeing"

// utils
import {retrieveResults} from "uprise-surveys/lib/surveys/getScore"
// Colors
import {backgrounds, primary, extended} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"

const YouHealthStyle = Styled.section`
`

const SwitchWrap = Styled.div`
 	width: 155px;
`

const NoData = Styled.div`
      height: 149px;
`

const BarStyle = Styled.section`
      height: 250px;
`

const HeaderStyle = Styled.section`
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
	flex-direction: row;
      justify-content: space-between;
`

const Content = Styled.section`
      display: flex;
	flex-direction: row;
`

const YouHealth = ({className, surveyResults}) => {
	const chartOptions = {
		legend: {
			display: false
		},
		scales: {
			xAxes: [
				{
					gridLines: {
						display: true,
						color: "#fff",
						zeroLineColor: "rgba(219, 221, 227, 0.5)"
					}
				}
			],
			yAxes: [
				{
					ticks: {
						min: 0,
						stepSize: 50,
						max: 100,
						callback: function(label) {
							return label + "%"
						}
					},
					gridLines: {
						display: false,
						color: "#fff",
						zeroLineColor: "rgba(219, 221, 227, 0.5)"
					}
				}
			]
		}
	}

	let who5 = []
	let stress = []

	surveyResults?.forEach(result => {
		if (result.completed) {
			switch (result.taskId) {
				case "survey-onboarding":
				case "survey-stress-wellbeing-weekly":
				case "survey-stress-wellbeing-monthly":
					who5.push(retrieveResults(result).who5)
					stress.push(retrieveResults(result).stress)
					break
				case "survey-progress":
					who5.push(retrieveResults(result).who5)
					stress.push(retrieveResults(result).stress)
					break
				case "survey-stress-check":
					stress.push(retrieveResults(result).stress)
					break
				case "survey-wellbeing-check":
					who5.push(retrieveResults(result).who5)
					break
				case "survey-onboarding-rtw-us":
				case "survey-postprogram-rtw":
					who5.push(retrieveResults(result).who5)
					break
			}
		}
	})

	who5.sort(function(a, b) {
		return b.timestamp - a.timestamp
	}).reverse()

	stress
		.sort(function(a, b) {
			return b.timestamp - a.timestamp
		})
		.reverse()

	return (
		<YouHealthStyle className={className}>
			<H3 className='m-b-4'>Your Health</H3>
			<Row>
				<Col className='col-md-6 m-b-sm-10'>
					<YourWellbeing who5={who5} chartOptions={chartOptions} />
				</Col>
				<Col className='col-md-6'>
					<YourStress stress={stress} chartOptions={chartOptions} />
				</Col>
			</Row>
		</YouHealthStyle>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(YouHealth)
